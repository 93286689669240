import React, { Component, useCallback } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalFooter, MDBInput } from 'mdbreact';
import CompanyAndDetailsView from './CompanyModal';
//import Representative from '../DealCreation/views/Representative';
import RepresentativeType from '../DealCreation/views/RepresentativeType';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
class RepresentativeModal extends Component {

    constructor(props) {
        super(props);
        this.state = { repFormData: this.props.RepresentativeForm }
        console.log('modalprops', JSON.stringify(this.state));
        this.RepresentativInput = React.createRef();
    }
    onCompanyBlur = (event, value) => {
        event.preventDefault();
        let companyExists = false;
        if (event && event.target.value) {
            let companyText = event.target.value;
            if (companyText != '') {
                this.props.CompaniesData.map(function (obj1) {
                    if (obj1.CompanyName == companyText) {
                        companyExists = true
                    }
                });
                if (!companyExists) {
                    event.preventDefault();
                    let newCompany = {
                        "CompanyExtensionId": null,
                        "CompanyId": 0,
                        "CompanyName": companyText,
                        "Address": null,
                        "Address2": null,
                        "City": null,
                        "State": null,
                        "Country": null,
                        "zip": null,
                        "IsLastUsed": true,
                        "IsExtensionChanged": true,
                        "RepresentativeCompanyId": 0
                    }
                    this.props.onCompanyChange(event, newCompany);
                }
            }
        }
        
        let r = 1;
    }

    onCompanyChange = (event, value) => {
        
        if (typeof value === 'string') {
            event.preventDefault();
            let newCompany = {
                "CompanyExtensionId": null,
                "CompanyId": 0,
                "CompanyName": value,
                "Address": null,
                "Address2": null,
                "City": null,
                "State": null,
                "Country": null,
                "zip": null,
                "IsLastUsed": true,
                "IsExtensionChanged": true,
                "RepresentativeCompanyId": 0
            }
            this.props.onCompanyChange(event, newCompany);
        }
        else if (typeof value === 'object') {
            // input is an object
            this.props.onCompanyChange(event, value);
        }

    }
    submitHandler = (event) => {
        let value = this.props.talentRepresentation;
        this.props.submitHandler(event, value);
    }

    onRepresentativeChange = (event, value) => {
        if (typeof value === 'string') {
            event.preventDefault();
            let newRepresentative = {
                "RepresentativeExtensionId": 0,
                "RepresentativeId": 0,
                "RepresentativeName": value,
                "Phone1": null,
                "Phone2": null,
                "Email": null,
                "RepresentativeTypeId": 0,
                "RepresentativeTypeName": "",
                "Fax": null,
                "UseForPaymentAddress": false,
                "CC": false,
                "IsLastUsed": true,
                "CompanyExtensionId": null,
                "IsExtensionChanged": true,
                "IsNewlyAdd": true
            }
            this.props.onRepresentativeChange(event, newRepresentative,
                this.props.RepresentativeForm.isRepSelected ? 'E' : 'A', null)
        }
        else if (typeof value === 'object') {
            // input is an object
            this.props.onRepresentativeChange(event, value, this.props.RepresentativeForm.isRepSelected ? 'E' : 'A', null)
        }
    }
    onRepresentativeTypeChange = (event, value) => {
        this.props.onRepresentativeTypeChange(event, value);
    }
    updateStatus = () => {
        this.setState({ repFormData: this.props.RepresentativeForm })
        console.log("OnRepChange: " + JSON.stringify(this.state));
    }
    toggleRepresentative = (event, value) => {

        this.props.toggleRepresentative(event, value);
        console.log("OnToggleRep: " + JSON.stringify(this.props));
    }
    chkRepChange = (event) => {
        this.props.chkRepChange(event.target.id, event.target.checked)
    }


    render() {
        return (
            <div id="repCreationModal">
                <MDBModal isOpen={this.props.isOpen} size='sm' id="repModal" >
                    {/* <form
                        className="needs-validation"
                        onSubmit={this.submitHandler}
                        noValidate> */}
                    <MDBModalBody>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol size="12">
                                    <label className="labelHeaders">Search for Representative</label>
                                    <Autocomplete autoHighlight size="small" name="representative"
                                        id="representative"
                                        options={this.props.RepresentativeData}
                                        freeSolo disabled={this.props.isCreate}
                                        getOptionLabel={(option) => option.RepresentativeName}
                                        value={this.props.RepresentativeForm.representative ?
                                            this.props.RepresentativeForm.representative : {}}
                                        onChange={this.onRepresentativeChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Representative*"
                                                placeholder="Representative*" autocomplete="new-password"
                                                inputRef={this.RepresentativInput}
                                            />)}
                                    />
                                </MDBCol>
                                <RepresentativeType
                                    isRepSelected={this.props.RepresentativeForm.isRepSelected}
                                    options={this.props.RepresentativeTypesData}
                                    onRepresentativeTypeChange={this.onRepresentativeTypeChange}
                                    value={this.props.RepresentativeForm.representativeType ?
                                        this.props.RepresentativeForm.representativeType : {}}>
                                </RepresentativeType>
                            </MDBRow>
                        </MDBContainer>

                        <MDBContainer id="repDetails">
                            {
                                this.props.RepresentativeForm.isRepSelected ? <MDBRow>
                                    <MDBCol size="12">
                                        <Autocomplete autoHighlight size="small"
                                            id="company" selectOnFocus
                                            options={this.props.CompaniesData} freeSolo
                                            getOptionLabel={(option) => option.CompanyName}
                                            value={this.props.RepresentativeForm.company ? this.props.RepresentativeForm.company : []}
                                            onChange={this.onCompanyChange}
                                            onBlur={this.onCompanyBlur}
                                            onFocus={event => {
                                                if (event.target.autocomplete) {
                                                    event.target.autocomplete = "whatever";
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Company Name*"
                                                    placeholder="Company Name*" autocomplete="new-password"
                                                />)}
                                        />
                                    </MDBCol>
                                    <MDBCol size="12">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="chkCC"
                                                checked={this.props.RepresentativeForm.isCC}
                                                onChange={this.chkRepChange} />
                                            <label class="custom-control-label" for="chkCC">CC</label>
                                        </div>
                                        {/* checked={this.props.RepresentativeForm.isUSeForPaymentAddress} */}
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" class="custom-control-input"
                                                id="ChkPaymentAddress"
                                                onChange={this.chkRepChange}
                                                checked={this.props.RepresentativeForm.isUSeForPaymentAddress} />
                                            <label class="custom-control-label" for="ChkPaymentAddress">Use for Payment Address</label>
                                        </div>
                                        <br />
                                    </MDBCol>
                                    <MDBCol size="12">
                                        <MDBInput label="Email"
                                            value={this.props.RepresentativeForm.email}
                                            id="email" name="email"
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            color="primary" autocomplete="new-password"
                                        />

                                    </MDBCol>
                                    <MDBCol size="12">
                                        <MDBInput name="phoneNumber"
                                            value={this.props.RepresentativeForm.phoneNumber}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            label="Phone Number" color="primary"
                                            autocomplete="new-password"
                                        />

                                    </MDBCol>
                                    <MDBCol size="12">
                                        <MDBInput name="Address1"
                                            value={this.props.RepresentativeForm.Address1}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            autocomplete="new-password"
                                            label="Office Address" color="primary"
                                        />

                                    </MDBCol>
                                    <MDBCol size="12">
                                        <MDBInput name="addres2"
                                            value={this.props.RepresentativeForm.addres2}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            autocomplete="new-password"
                                            label="Office Address2" color="primary"
                                        />

                                    </MDBCol>
                                    <MDBCol size="12">
                                        <MDBInput
                                            value={this.props.RepresentativeForm.city}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            autocomplete="new-password"
                                            label="City"
                                            name="city" color="primary"
                                        />

                                    </MDBCol>
                                    <MDBCol size="6">
                                        <MDBInput
                                            value={this.props.RepresentativeForm.State}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            autocomplete="new-password"
                                            label="State"
                                            name="State" color="primary"
                                        />

                                    </MDBCol>
                                    <MDBCol size="6">
                                        <MDBInput
                                            value={this.props.RepresentativeForm.Zip}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            autocomplete="new-password"
                                            label="Zip" name="Zip" color="primary"

                                        />

                                    </MDBCol>
                                    <MDBCol size="6">
                                        <MDBInput
                                            value={this.props.RepresentativeForm.phoneNumber2}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            autocomplete="new-password"
                                            label="2nd Phone#"
                                            name="phoneNumber2" color="primary"
                                        />
                                    </MDBCol>
                                    <MDBCol size="6">
                                        <MDBInput
                                            value={this.props.RepresentativeForm.fax}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            autocomplete="new-password"
                                            label="Fax" color="primary"
                                            name="fax"
                                        />
                                    </MDBCol>
                                    <MDBCol size="12">
                                        <MDBInput
                                            value={this.props.RepresentativeForm.Country}
                                            onChange={(event, value) => {
                                                this.props.onFormDataChange(event, value)
                                            }}
                                            type="text"
                                            autocomplete="new-password"
                                            label="Country"
                                            name="Country" color="primary"
                                        />
                                    </MDBCol>
                                </MDBRow>
                                    : ""
                            }</MDBContainer>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {
                            this.props.RepresentativeForm.isRepSelected ?
                                <MDBBtn size="sm" type="button" disabled={this.props.isDisabled}
                                    onClick={(event, value) => this.submitHandler(event, value)}>Save</MDBBtn>
                                : ""
                        }
                        <MDBBtn size="sm" onClick={() => this.toggleRepresentative('C', null)}>Close</MDBBtn>
                    </MDBModalFooter>
                    {/* </form> */}
                </MDBModal>
            </div >
        )
    }

}

export default RepresentativeModal;
