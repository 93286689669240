import React, { useState, useEffect, Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class TalentExtensionChanged extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return <>
            <MDBModal isOpen={this.props.isOpen} size='md' >
                <MDBModalHeader>
                    Representation Extension Changes</MDBModalHeader>
                <MDBModalBody>
                    <MDBInput
                        label="Do you want to use this changes for all the future Deals" type="radio" name="All"
                        id="1" onChange={this.props.onChange}
                        checked={this.props.selection} />
                    <MDBInput
                        label="Do you want to use these changes for only this deal " name="One"
                        type="radio" onChange={this.props.onChange}
                        checked={!this.props.selection}
                        id="2" />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" onClick={this.props.confirmation}>Confirm</MDBBtn>
                    {/* <MDBBtn size="sm" onClick={this.props.confirmation}>Cancel</MDBBtn> */}
                </MDBModalFooter>
            </MDBModal>

        </>
    }
}
export default TalentExtensionChanged;