import axios from 'axios';
import DealerService from '../service';
import * as Constants from '../../constants/constants';

// async function dealService(divisionId){


export const dealService = {
  fetchEntityDetails: async function (entity, divisionId = -1) {
    if (divisionId != -1) {
      return (
        DealerService.getData(Constants.DealerSharedServiceBaseUrl + entity + '?divisionId=' + divisionId)
      );
    }
    else {
      return (

        DealerService.getData(Constants.DealerSharedServiceBaseUrl + entity)
      )
    }

  },
  fetchLookupEntities: async function (lookupEntity) {
    return (
      DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'LookupEntities?entity=' + lookupEntity)
    );
  },
  fetchDealDetailsFormData: async function (divisionId, selectedPosition) {
    
    return (
      await Promise.all(
        [
          DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Representative'),
          DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Company'),
          DealerService.getData(Constants.DealerServiceBaseUrl +
            'FinalDistribution?divisionId=' + divisionId + '&positionName=' + selectedPosition),
          DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'User?divisionId=' + divisionId),
          DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Recipients?divisionId=' + divisionId),

        ]))
  },
  fetchDealDetailsTalentFormData: async function (divisionId, selectedPosition) {
    
    return (
      await Promise.all(
        [
          DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Representative'),
          DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Company'),
        ]))
  }


}
export default dealService
