import React, { useState, useEffect, Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class DisassociateTalent extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return <>
            <MDBModal isOpen={this.props.isOpen} size='lg' >
                <MDBModalHeader>
                    Delete Talent Representation</MDBModalHeader>
                <MDBModalBody>
                    <MDBInput
                        label="Do you want to PERMANENTLY disassociate the representative from this Talent?" type="radio"
                        id="1" onChange={this.props.onDelChange}
                        checked={this.props.checkedProp == "1" ? true : false} />
                    <MDBInput
                        label="Do you want to disassociate the representative from this Talent for this Deal ONLY? "
                        type="radio" onChange={this.props.onDelChange}
                        checked={this.props.checkedProp == "2" ? true : false}
                        id="2" />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" onClick={this.props.confirmTalentRepDeletion}>Confirm</MDBBtn>
                    <MDBBtn size="sm" onClick={this.props.toggleDeleteTalentRepresentationModal}>Cancel</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

        </>
    }
}
export default DisassociateTalent;