import React, { Component,useState } from "react";

const SelectBox = function (props) {

    const [optionSelected, setValue] = useState('');
    
    
    console.log("Props in Input :", props);
    const handleChange = event => {
      //  console.log(event.target.name,"....",event.target.value);
        setValue(event.target.value);
        props.onchange(optionSelected);
    }

    return (
         <select 
            class="custom-select custom-select-sm" 
            id="dealmemoDivision"
            name="division" 
            value={optionSelected}
            onChange={handleChange}
            >
             {props.optionList.map((x,y) => <option key={y} value={x}>{x}</option>)}
         </select>
        
    )

}
export default SelectBox;