import React from 'react'; //, { useState }
import '../../styles/SideNav.css';
import SideNav from './SideNav'
import { MDBCol, MDBRow } from 'mdbreact';
//import DealMemoLandingPage from '../../pages/DealMemoLandingPage';
import {
    BrowserRouter as Router
} from "react-router-dom";
import Routes from '../../Routes';
import ErrorBoundary from "../../pages/ErrorBoundary";

class MainContent extends React.Component {
    // const [isexpanded, setWidth] = useState(false);
    _isMounted = false;

    constructor(props) {
        super(props);
        //console.log("Main Content -->"+ this.props.authState.isAuthenticated);
        //this.inputRef = React.createRef();

        this.state = {
            isExpanded: false,
            isAdmin: false,
            isCreate: false,
            isFinalApprover: false
        }
    }
    toggleSideNav = () => {

        this.setState({
            isExpanded: !this.state.isExpanded
        });

    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentWillReceiveProps(nextProps) {

        if (this._isMounted) {
            this.setState({
                isAdmin: nextProps.isAdmin,
                isCreate: nextProps.isCreate,
                isFinalApprover: nextProps.isFinalApprover
            });
        }
    }
    render() {
        return (

            <Router >
                <MDBRow className="containerBorder">
                    <MDBCol id="sideNav" size={this.state.isExpanded ? "2" : "1"} >
                        <SideNav isAdmin={this.state.isAdmin} isExpanded={this.state.isExpanded}
                            isCreate={this.state.isCreate} isFinalApprover={this.state.isFinalApprover} toggleSideNav={this.toggleSideNav} />
                    </MDBCol >
                    <MDBCol size={this.state.isExpanded ? "10" : "11"} className="colP50" id="main-content">
                        <ErrorBoundary>
                            <Routes authState={this.props.authState} />
                        </ErrorBoundary>
                    </MDBCol>
                </MDBRow>
            </Router>
        );
    }
}

export default MainContent