import React, { useRef, useState, useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useHistory, withRouter, Link } from "react-router-dom";
import {
    MDBContainer, MDBDataTableV5, MDBCol, MDBRow, MDBLink,
    MDBBtn, MDBSpinner, MDBInput, MDBModal, MDBSelect, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBInputGroup
} from 'mdbreact';
import Role from './DealCreation/views/Role';
import Fee from './DealCreation/views/Fee';
import AddEditRecipient from '../pages/Modal/AddEditRecipient';
import DisassociateCompany from '../pages/Modal/DisAssociateCopmany';
import DisassociateTalent from '../pages/Modal/DisAssociateTalent';
import CompanyExtensionChanged from '../pages/Modal/CompanyExtensionChanged';
import TalentExtensionChanged from '../pages/Modal/TalentExtensionChanged';
import CompanyAndDetailsView from '../pages/Modal/CompanyModal';
import TalentViews from './DealCreation/views/TalentViews';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { blue } from '@material-ui/core/colors';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateIcon from '@material-ui/icons/Create';
import AddCircle from '@material-ui/icons/AddCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import '../styles/Autocomplete.css';
import '../styles/Form.css';
import DealCreation from './DealCreation';
import dealService from '../services/dealCreationService/dealService';
import DealerService from '../services/service';
import * as Constants from '../constants/constants';
import RepresentativeModal from '../pages/Modal/RepresentativeModal';
import isEqual from 'lodash/isEqual';
import PageSpinner from './PageSpinner';
import { DealPDFDocGen } from './Modal/DealPDFDocViewer';
import AddOtherCommentsModal from '../pages/Modal/AddOtherCommentsModal';
function ManageTalents(props) {
    let history = useHistory();
    const [dealDetailsForm, setDealDetailsForm] = useState({
        dealId: '',
        projectId: null,
        projectTitle: '',
        projectTitleData: [],
        positionId: null,
        positionName: null,
        divisionId: null,
        divisionName: null,
        talentsData: [],
        creativeExecData: [],
        recipientsData: [],
        baExecutiveData: [],
        existingProducerData: [],
        brandData: [],
        genreData: [],
        specialCategoryData: [],
        representativesData: [],
        distributors: [],
        creativeExecutivesSelected: [],
        selectedRepresentatives: [],
        selectedBAExecsData: [],
        selectedCreativeExecData: [],
        selectedExistingProducerData: [],
        selectedProjectData: [],
        selectedSpecialCategory: '',
        selectedBrand: '',
        selectedWritingSteps: [],
        writingStepsData: [],
        lastStepWrittenData: [],
        fictionData: [],
        selectedLastSteps: [],
        selectedFiction: '',
        companiesData: [],
        repsData: [],
        representativeTypesData: [],
        materialData: [],
        selectedMaterial: '',
        comments: '',
        role: '',
        emailNotes: '',
        loading: false,
        talentLoader: false,
        repCompanyLoader: false,
        addEditReps: [],
        projectSelected: false,
        oneSterpGuarentee: [],
        selectedGenre: [],
        optionalStepsSelected: [],
        fictionSelected: [],
        Fee: '',
        isUrgent: false,
        IsCompetitive: false,
        talentInfo: [],
        ExistingProdData: [],
        optionalSteps: [],
        createdDate: '',
        formPosition: '',
        isEdit: false,
        submitterEmail: '',
        submitterAssistantEmail: '',
        status: ''
    });
    const [dataCreationModals, setModal] = useState({
        projectConfirmationModal: false,
        TalentCreationModal: false,
        CreativeExecConfirmationModal: false,
        createTalentFormModal: false,
        createProjectFormModal: false,
        createExecFormModal: false,
        addRepresentativeModal: false,
        TalentNameSelected: '',
        ProjectNameSelected: '',
        CreativeExecNameSelected: '',
        controlName: '',
        deleteTalentRepresentationModal: false,
        deleteTalentRepresentationObject: [],
        deleteTalentRepresentationSelection: "1",
        deleteRepresentationCompanySelection: "1",
        deleteCompanyModle: false,
        representationExtensions: false,
        repExtensions: [],
        companyExtensions: false,

    });
    const [save, setSave] = useState(false);

    const [loading, setLoading] = useState({
        pageSpinner: false,
        message: ''
    });

    const [mode, setMode] = useState({
        editMode: true,
        newMode: false,
        viewMode: false
    })
    const [data, setRepdata] = useState({
        columns: [
            {
                label: 'Name',
                field: 'RepresentativeName',
                width: 200
            },
            {
                label: 'Type',
                field: 'RepresentativeTypeName',
                width: 100
            },
            {
                label: 'Email',
                field: 'Email',
                width: 100
            },
            {
                label: 'Phone1',
                field: 'Phone1',
                width: 100
            },

            {
                label: 'Fax',
                field: 'Fax',
                width: 100,
            },
            {
                label: 'UseForPaymentAddress',
                field: 'UseForPaymentAddress',
                width: 50
            },
            {
                label: 'CC',
                field: 'CC',
                width: 50
            },
            {
                label: 'IsLastUsed',
                field: 'IsLastUsed',
                width: 50
            }
        ],
        rows: []
    });
    const [compData, setCompanyData] = useState({
        columns: [
            {
                label: 'Name',
                field: 'CompanyName',
                width: 200
            },
            {
                label: 'Address',
                field: 'Address',
                width: 100
            },
            {
                label: 'City',
                field: 'City',
                width: 100
            },
            {
                label: 'Country',
                field: 'Country',
                width: 100
            },

            {
                label: 'State',
                field: 'State',
                width: 100,
            },
            {
                label: 'zip',
                field: 'zip',
                width: 100
            },
            {
                label: 'IsLastUsed',
                field: 'IsLastUsed',
                width: 100
            },
        ],
        rows: []
    });

    const [representativeCreation, setRepCreation] = useState({
        addRepresentativeModal: false,
        isCreate: false,
    });
    const [RepresentativeForm, setRepForm] = useState({
        representative: [],
        representativeType: [],
        company: [],
        email: '',
        phoneNumber: null,
        Address1: null,
        addres2: null,
        city: null,
        State: null,
        Country: null,
        Zip: null,
        phoneNumber2: null,
        fax: null,
        isCC: false,
        isUSeForPaymentAddress: false,
        isAdd: false,
        isEdit: false,
        isRepSelected: false,
        Mode: 'A'
    });


    //const [formValidate, setFormValidate] = useState(false);
    const [formValidate, setFormValidate] = React.useState(false);
    const [tempValidate, settempValidate] = React.useState(false);
    const [submissionType, setSubmissionType] = React.useState(1);
    const [companyChange, setCompanyChange] = React.useState([]);
    const [talentRepresentation, settalentRepresentation] = React.useState([]);
    const [IsRepresentativeCompanyLinkAsCurrent, setIsRepresentativeCompanyLinkAsCurrent] = React.useState(false);

    const [companyExtChange, setcompanyExtChange] = React.useState(false);

    const [repCompanyDomChange, setrepCompanyDomChange] = React.useState([]);
    const [addItem, setnewItem] = React.useState([]);
    const [existingITem, setvalue] = React.useState([]);

    const [companyModal, setcompanyModal] = React.useState(false);
    const [companyExtensionSelection, setcompanyExtensionSelection] = React.useState(true);
    const [talentModal, settalentModal] = React.useState(false);
    const [talentExtensionSelection, settalentExtensionSelection] = React.useState(true);
    const [isCompanyChange, setisCompanyChange] = React.useState(false);
    const [isTalentChange, setisTalentChange] = React.useState(false);

    const projectAliasInput = useRef();
    const RepresentativInput = useRef();
    const RepresentativeTypeInput = useRef();
    const projectNewInput = useRef();
    let ProjectTitlesData1 = [];

    const [selectedRepresentative, setSelectedRepresentative] = React.useState(null);
    const [dealId, setDealId] = React.useState(null);
    const queryString = require('query-string');
    const params = queryString.parse(window.location.search);

    useEffect(() => {
        let dealId = null;
        let preview = false;
        if (params.dealId) {
            setDealId(params.dealId);
            dealId = params.dealId;
        }

        if (params.preview === "true")
            preview = true;

        const fetchExistingDeal = (dealId, preview) => {

        }
        const fetchData = (newDeal) => {
            debugger;
            dealService.fetchEntityDetails('Talent')
                .then((response) => {
                    setDealDetailsForm(dealDetailsForm => {
                        debugger;
                        return {
                            ...dealDetailsForm,
                            talentsData: response.data,
                            existingProducerData: response.data,
                            talentInfo: response.data.map(talents => ({
                                'talent': talents.TalentName,
                                'value': talents.TalentId,
                                'IsActive': talents.IsActive
                            })),
                            ExistingProdData: response.data.map(existingProducer => ({
                                'ExistingProducerName': existingProducer.TalentName,
                                'ExistingProducerId': existingProducer.TalentId
                            }))
                        }
                    });
                });


            dealService.fetchLookupEntities('Representativetype')
                .then((response) => {
                    setDealDetailsForm(dealDetailsForm => {
                        return {
                            ...dealDetailsForm,
                            representativeTypesData: response.data
                        }
                    });
                });


            dealDetailsForm.formPosition = localStorage.getItem('position')
            debugger;
            dealService.fetchDealDetailsTalentFormData(divisionId, dealDetailsForm.formPosition)
                .then((response) => {
                    debugger;
                    let responseData = response.map((resp) => (resp.data));
                    const [
                        RepresentativeDataResponse,
                        CompaniesDataResponse,
                        RecipientsResponse] = responseData;
                    setDealDetailsForm(dealDetailsForm => {
                        return {
                            ...dealDetailsForm,
                            representativesData: (newDeal ? [] : dealDetailsForm.representativesData),
                            selectedRepresentatives: (newDeal ? [] : dealDetailsForm.selectedRepresentatives),
                            companiesData: CompaniesDataResponse,
                            repsData: RepresentativeDataResponse,
                            formPosition: localStorage.getItem('position')
                        }
                    });
                });
        }
        fetchData(true);
    }, []);


    const toggleLoading = (msg) => {
        setLoading(loading => {
            return {
                ...loading,
                pageSpinner: !loading.pageSpinner,
                message: msg
            }
        }
        );

    }
    let RepresentativeData = dealDetailsForm.repsData;
    let CompaniesData = dealDetailsForm.companiesData;


    var today = moment().format('MM/DD/YYYY');

    let representativesData = dealDetailsForm.representativesData;
    let selectedPosition = localStorage.getItem('position');
    let divisionId = localStorage.getItem('divisionId');
    let positionId = localStorage.getItem('positionId');
    let WritingStepsOptions = [];



    let chkChange = (event) => {
        let prop = event.target.checked;
        if (dealDetailsForm.selectedRepresentatives.length > 0) {
            toggleLoading('Saving...')
            DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'Talent ', {
                "TalentName": dealDetailsForm.selectedRepresentatives[0].talent,
                "IsActive": event.target.checked
            })
                .then(response => response)
                .then(data => {
                    let talent = dealDetailsForm.selectedRepresentatives[0];
                    let r = {
                        'talent': talent.talent,
                        'value': talent.value,
                        'IsActive': prop
                    }
                    var newtalentData = dealDetailsForm.talentInfo;
                    for (var i in newtalentData) {
                        if (newtalentData[i].talent == talent.talent) {
                            newtalentData[i].IsActive = prop;
                            break; //Stop this loop, we found it!
                        }
                    }
                    setDealDetailsForm(dealDetailsForm => {
                        return {
                            ...dealDetailsForm,
                            talentInfo: newtalentData,
                            selectedRepresentatives: [r]
                        }
                    });
                    toggleLoading()
                    alert(talent.talent.toString() + ' is marked as ' + (prop ? 'Active' : 'Inactive'));
                });
        }
    }
    let chkRepChange = (id, value) => {
        if (id == 'chkCC') {
            setRepForm({
                ...RepresentativeForm,
                isCC: value
            });
        } else {
            setRepForm({
                ...RepresentativeForm,
                isUSeForPaymentAddress: value
            });
        }

    }



    const SpinnerPage = () => {
        if (dealDetailsForm.loading) {
            return (
                <>
                    <MDBSpinner small />
                </>
            );
        } else {
            return ('')
        }
    }
    const TalentSpinner = () => {
        if (dealDetailsForm.talentLoader) {
            return (
                <>
                    <MDBSpinner small />
                </>
            );
        } else {
            return ('')
        }
    }






    function RepresentativeAdd(index, t) {

        if (index && index.index == 0) {
            return (<AddBoxIcon style={{ color: blue[900], fontSize: 25 }}
                onClick={() => toggleRepresentative('A', t, index.represntative)} />)
        } else
            return ('')
    }

    function RepresentativeEditDelete(index, represntative) {

        if ((index.represntative && index.represntative[0] && index.represntative[0].TalentRepresentativeId) ||
            (index.represntative && index.represntative[0] && index.represntative[0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId != null)) {
            return (
                <>
                    <CreateIcon href='/' style={{ color: blue[900], fontSize: 25 }}
                        onClick={() => toggleRepresentative('E', index.t, index.represntative)} />
                    <CancelIcon style={{ color: blue[900], fontSize: 25 }}
                        onClick={() => toggleDeleteTalentRepresentationModal(represntative, index)}
                    ></CancelIcon></>)
        } else { return ('') }
    }

    const toggleDeleteTalentRepresentationModal = (t, index) => {
        debugger;
        if (index) {
            let array = getAnEmptyTalentRep();
            let emptyRow = array[0];
            if (index.represntative[index.index].RepresentativeCompanyModels[0].RepresentativeExtension.IsNewlyAdd
                != true) {
                let obj = []
                if (t && t != '') {
                    obj = index.represntative[index.index];
                    confirmTalentRepDeletion(obj);
                }
                // setModal({
                //     ...dataCreationModals,
                //    // deleteTalentRepresentationModal: !dataCreationModals.deleteTalentRepresentationModal,
                //     deleteTalentRepresentationObject: obj && t != '' ? obj : []
                // },[confirmTalentRepDeletion()]);

            }
        } else {
            // setModal({
            //     ...dataCreationModals,
            //     deleteTalentRepresentationModal: !dataCreationModals.deleteTalentRepresentationModal,
            // });
        }
    }
    const confirmTalentRepDeletion = (val) => {
        if (dataCreationModals.deleteTalentRepresentationSelection == "1") {
            let obj = [];
            let r = []
            if (dataCreationModals.deleteTalentRepresentationObject) {
                // r = dataCreationModals.deleteTalentRepresentationObject;
                r = val;
                obj = {
                    "TalentId": r.TalentModel.TalentId,
                    "RepresentativeId": r.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId,
                    "TalentRepresentativeId": r.TalentRepresentativeId
                }

                DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'DeActiveTalentRepresentativeLink', obj)
                    .then(response => response)
                    .then(data => {
                        //removing array from RepresentativesSection
                        var array = dealDetailsForm.representativesData;
                        let index = -1;
                        debugger
                        for (const [i, obj] of array.entries()) {
                            let len = obj.length;
                            for (const [j, obj1] of obj.entries()) {
                                if (isEqual(obj1, r)) {
                                    let talentModel = array[i][j].TalentModel;
                                    array[i].splice(j, 1)
                                    if (array[i] && array[i].length == 0) {

                                        let empt = getAnEmptyTalentRep();
                                        let emptyRow = empt[0];
                                        emptyRow.TalentModel = talentModel
                                        array[i].push(emptyRow)
                                    }
                                    break;
                                }
                            }
                        }
                        setDealDetailsForm({
                            ...dealDetailsForm,
                            representativesData: array,
                        });
                        // toggleDeleteTalentRepresentationModal()
                        // if (index !== -1) {
                        //   array[0].splice(index, 1)
                        //   setDealDetailsForm({
                        //     ...dealDetailsForm,
                        //     representativesData: array,
                        //   });
                        //   toggleDeleteTalentRepresentationModal()
                        // }
                    });
            }
        } else {
            //toggleDeleteTalentRepresentationModal()
            let r = [];
            if (dataCreationModals.deleteTalentRepresentationObject) {
                r = dataCreationModals.deleteTalentRepresentationObject;
                var array = dealDetailsForm.representativesData;
                for (const [i, obj] of array.entries()) {
                    let len = obj.length;
                    for (const [j, obj1] of obj.entries()) {
                        if (isEqual(obj1, r)) {
                            let talentModel = array[i][j].TalentModel;
                            array[i].splice(j, 1)
                            if (array[i] && array[i].length == 0) {

                                let empt = getAnEmptyTalentRep();
                                let emptyRow = empt[0];
                                emptyRow.TalentModel = talentModel
                                array[i].push(emptyRow)
                            }
                            break;
                        }
                    }
                }
                setDealDetailsForm({
                    ...dealDetailsForm,
                    representativesData: array,
                });
                toggleDeleteTalentRepresentationModal()

            } else {
                toggleDeleteTalentRepresentationModal()
            }
        }

    }
    const confirmRepCompanyDeletion = () => {
        //Permanently Disassociate
        setcompanyExtChange(true);
        let deAssociateCompanyRep = {
            "RepresentativeId": RepresentativeForm.representative.RepresentativeId,
            "CompanyId": RepresentativeForm.company.CompanyId,
            "RepresentativeCompanyId": RepresentativeForm.company.RepresentativeCompanyId
        }
        if (dataCreationModals.deleteRepresentationCompanySelection == "1") {
            setIsRepresentativeCompanyLinkAsCurrent(true);
            DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'DeActiveRepresentativeCompanyLink', deAssociateCompanyRep)
                .then(response => response)
                .then(data => {
                    //setting up the changed company details to form
                    setCompanyChangeToForm();
                });
        } else {
            setIsRepresentativeCompanyLinkAsCurrent(false);
            setcompanyExtensionSelection(false);
            setCompanyChangeToForm();
        }
    }
    let setCompanyChangeToForm = () => {
        if (companyChange) {
            let company = companyChange;
            let rep = RepresentativeForm.representative;
            setRepForm({
                ...RepresentativeForm,
                representative: rep,
                company: company,
                Address1: company && company.Address ? company.Address : '',
                addres2: company && company.Address2 ? company.Address2 : '',
                city: company && company.City ? company.City : '',
                State: company && company.State ? company.State : '',
                Country: company && company.Country ? company.Country : '',
                Zip: company && company.zip ? company.zip : '',
            });
        }
    }

    const toggleDeleteCompany = () => {
        confirmRepCompanyDeletion();
    }
    const onDelChange = (event, value) => {
        setModal({
            ...dataCreationModals,
            deleteTalentRepresentationSelection: event.target.id,
        });
    }
    const onCompanyDelete = (event, value) => {
        setModal({
            ...dataCreationModals,
            deleteRepresentationCompanySelection: event.target.id,
        });
    }

    //Company Extension Changes
    const toggleCompanyExtension = () => {
        if (companyModal) {
            setcompanyModal(!companyModal);
            RepresentationDomChange();
        } else {
            setcompanyModal(!companyModal);
        }

    }
    const onCompanyExtPopValChange = (event) => {
        if (event && event.target.name == "All") {
            setcompanyExtensionSelection(true);
        } else if (event && event.target.name == "One") {
            setcompanyExtensionSelection(false);
        }
    }
    //Company Extension Changes
    //Talent Extension Changes
    const toggleTalentExtension = () => {
        if (talentModal) {
            settalentModal(!talentModal);
            if (isCompanyChange) {
                toggleCompanyExtension();
            } else {
                RepresentationDomChange();
            }
        } else {
            settalentModal(!talentModal);
        }
    }
    const onTalentExtPopValChange = (event) => {
        if (event && event.target.name == "All") {
            settalentExtensionSelection(true);
        } else if (event && event.target.name == "One") {
            settalentExtensionSelection(false);
        }
    }

    //Talent Extension Changes

    function Representatives(props) {
        return dealDetailsForm.representativesData.map((represntative, index) =>
            <div>
                {
                    represntative.map((r, index2) =>
                        <div id="rep">
                            <label className="labelHeaders">{index2 == 0 ? r.TalentModel.TalentName : ''}</label>
                            {r.RepresentativeCompanyModels.map(t =>
                                <MDBRow id="talent" >
                                    <MDBCol col="3" >
                                        <label id="repLabel">Representative Name</label>
                                        <MDBLink id="repName" onClick={() => { toggleRepExtensions(t) }}>{t.RepresentativeExtension.RepresentativeName}</MDBLink>
                                    </MDBCol>
                                    <MDBCol col="2" data-attr='colrep2'>
                                        <MDBInput disabled label="Email" value={t.RepresentativeExtension.Email}></MDBInput>
                                    </MDBCol>
                                    <MDBCol col="2" data-attr='colrep2'>
                                        <MDBInput disabled label="Phone" value={t.RepresentativeExtension.Phone1}></MDBInput>
                                    </MDBCol>
                                    <MDBCol col="2" data-attr='colrep2'>
                                        <MDBInput disabled label="Type"
                                            value={t.RepresentativeExtension.RepresentativeTypeName}></MDBInput>
                                    </MDBCol>
                                    <MDBCol col="2" >
                                        <label id="repLabel"> &nbsp;&nbsp;&nbsp;&nbsp;    Company Name</label>
                                        <MDBLink id="repName"
                                            onClick={() => { toggleCompany(t) }}>{t.CompanyExtension.CompanyName}</MDBLink>
                                    </MDBCol>
                                    <MDBCol col="1" disabled={props.disabled}>
                                        <RepresentativeEditDelete index={index2} t={t} represntative={represntative}></RepresentativeEditDelete>
                                        <RepresentativeAdd index={index2} t={t}
                                            represntative={represntative}></RepresentativeAdd>
                                    </MDBCol>
                                </MDBRow>
                            )}
                        </div>
                    )
                }
            </div>
        )
    }
    const titleKeyPress = (value) => {
        // setSave(false)
        // toggleTalentModal(value, 'Talent');
    }

    const [talentSelected, setTalentSelected] = useState(false);
    const talentChange = (a) => {
        debugger;
        var t = a ? [a] : [];
        if (t && t.length > 0) {
            setDealDetailsForm({
                ...dealDetailsForm,
                selectedRepresentatives: t,
                talentLoader: true
            });
            onTalentSelection(t);
            setTalentSelected(true);
        } else {
            setDealDetailsForm({
                ...dealDetailsForm,
                representativesData: [],
                selectedRepresentatives: [],
            });
        }
    }
    const onTalentSelection = (t) => {
        if (dealDetailsForm.selectedRepresentatives
            && dealDetailsForm.selectedRepresentatives.length <= t.length) {
            if (t && t.length > 0) {
                // var selectedTalents = dealDetailsForm.representativesData;
                var selectedTalents = []
                for (const [index, value] of t.entries()) {

                    if (value) {
                        var objExists = dealDetailsForm.representativesData.filter(obj => {
                            return obj[0].TalentModel.TalentId === value.value
                        })
                        if (objExists.length == 0) {
                            toggleLoading('Fetching Representations')
                            DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'TalentRepresentativeCompany?talentId=' + value.value)
                                .then(response =>
                                    response.json())
                                .then(data => {
                                    if (data && data.length > 0) {
                                        selectedTalents.push(data);
                                        setDealDetailsForm({
                                            ...dealDetailsForm,
                                            representativesData: selectedTalents,
                                            selectedRepresentatives: t,
                                            talentLoader: false
                                        });
                                    }
                                    toggleLoading('')
                                })
                        } else {
                            setDealDetailsForm({
                                ...dealDetailsForm,
                                selectedRepresentatives: t,
                                talentLoader: false
                            });
                        }
                    } else {
                        setDealDetailsForm({
                            ...dealDetailsForm,
                            talentLoader: false,
                        });
                    }
                }
            }
        } else {
            // The onew which is remove from Talents DropDown
            if (t && t.length > 0) {
                let r = t;
                let removedItem = dealDetailsForm.selectedRepresentatives.filter((el) => {
                    return r.indexOf(el) < 0;
                });
                if (removedItem.length > 0) {
                    let talentreps = dealDetailsForm.representativesData;
                    let pos = 0;
                    for (const [index, value] of talentreps.entries()) {
                        if (value[0].TalentModel.TalentId == removedItem[0].value) {
                            pos = index;
                            break;
                        }
                    }
                    talentreps.splice(pos, 1);
                    let pos1 = 0;
                    let selectedReps = dealDetailsForm.selectedRepresentatives;
                    for (const [i, v] of selectedReps.entries()) {
                        if (v.value == removedItem[0].value) {
                            pos1 = i;
                            break;
                        }
                    }
                    selectedReps.splice(pos1, 1);
                    setDealDetailsForm({
                        ...dealDetailsForm,
                        representativesData: talentreps,
                        selectedRepresentatives: selectedReps,
                        talentLoader: false
                    });

                } else {
                    setDealDetailsForm({
                        ...dealDetailsForm,
                        selectedRepresentatives: t,
                        talentLoader: false
                    });
                }
            } else {
                setDealDetailsForm({
                    ...dealDetailsForm,
                    representativesData: [],
                    selectedRepresentatives: [],
                    talentLoader: false
                });
            }
        }
    }
    //Talent Region
    const toggleTalentModal = (selectedTtile, controlName) => {

        setModal({
            ...dataCreationModals,
            TalentCreationModal: !dataCreationModals.TalentCreationModal,
            TalentNameSelected: selectedTtile,
            controlName: controlName ? controlName : ''
        });
    }
    const toggleRepExtensions = (repExtensions) => {
        debugger;
        if (repExtensions) {
            const url = Constants.DealerSharedServiceBaseUrl + 'RepresentativeExtensions?representativeId='
                + repExtensions.RepresentativeExtension.RepresentativeId;
            DealerService.fetchData(url)
                .then(response =>
                    response.json())
                .then(data => {
                    setRepdata({
                        columns: [
                            {
                                label: 'Name',
                                field: 'RepresentativeName',
                                width: 200
                            },
                            {
                                label: 'Type',
                                field: 'RepresentativeTypeName',
                                width: 100
                            },
                            {
                                label: 'Email',
                                field: 'Email',
                                width: 100
                            },
                            {
                                label: 'Phone1',
                                field: 'Phone1',
                                width: 100
                            },

                            {
                                label: 'Fax',
                                field: 'Fax',
                                width: 100,
                            },
                            {
                                label: 'UseForPaymentAddress',
                                field: 'UseForPaymentAddress',
                                width: 50
                            },
                            {
                                label: 'CC',
                                field: 'CC',
                                width: 50
                            },
                            {
                                label: 'IsLastUsed',
                                field: 'IsLastUsed',
                                width: 50
                            }
                        ],
                        rows: data.map((repData, order) => {
                            return {
                                RepresentativeName: repData.RepresentativeName,
                                RepresentativeTypeName: repData.RepresentativeTypeName,
                                Email: repData.Email,
                                Phone1: repData.Phone1,
                                Fax: repData.Fax,
                                Phone2: repData.Phone2,
                                UseForPaymentAddress: repData.UseForPaymentAddress ? 'Yes' : 'No',
                                CC: repData.CC ? 'Yes' : 'No',
                                IsLastUsed: repData.IsLastUsed ? 'Yes' : 'No',
                            }
                        })
                    });
                    setModal({
                        ...dataCreationModals,
                        representationExtensions: !dataCreationModals.representationExtensions,
                        repExtensions: repExtensions
                    });
                })
        }
    }
    const toggleCompany = (cmpExtensions) => {
        debugger;
        if (cmpExtensions) {
            const url = Constants.DealerSharedServiceBaseUrl + 'CompanyExtensions?companyId='
                + cmpExtensions.CompanyExtension.CompanyId;
            DealerService.fetchData(url)
                .then(response =>
                    response.json())
                .then(data => {
                    setCompanyData({
                        columns: [
                            {
                                label: 'Name',
                                field: 'CompanyName',
                                width: 200
                            },
                            {
                                label: 'Address',
                                field: 'Address',
                                width: 100
                            },
                            {
                                label: 'City',
                                field: 'City',
                                width: 100
                            },
                            {
                                label: 'Country',
                                field: 'Country',
                                width: 100
                            },

                            {
                                label: 'State',
                                field: 'State',
                                width: 100,
                            },
                            {
                                label: 'zip',
                                field: 'zip',
                                width: 100
                            },
                            {
                                label: 'IsLastUsed',
                                field: 'IsLastUsed',
                                width: 100
                            },
                        ],
                        rows: data.map((compData, order) => {
                            return {
                                CompanyName: compData.CompanyName,
                                Address: compData.Address ? compData.Address : '' + (compData.Address2 ? ',' + compData.Address2 : ''),
                                City: compData.City,
                                Country: compData.Country,
                                State: compData.State,
                                zip: compData.zip,
                                IsLastUsed: compData.IsLastUsed ? 'Yes' : 'No',
                            }
                        })
                    });
                    setModal({
                        ...dataCreationModals,
                        companyExtensions: !dataCreationModals.companyExtensions,
                    });
                })
        }
    }
    function CreateTalentModalConfirmation() {
        return (
            <MDBModal isOpen={dataCreationModals.TalentCreationModal} size='sm' >
                <MDBModalHeader toggle={() => setModal(!dataCreationModals.TalentCreationModal)}>Create {dataCreationModals.controlName}</MDBModalHeader>
                <MDBModalBody>
                    Do you want to Create the New {dataCreationModals.controlName} - <span className="font-weight-bold">{dataCreationModals.TalentNameSelected}?</span>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" disabled={save} className="btnCreate" id="btnCreate"
                        autoHighlight onClick={() => { createNewTalent(dataCreationModals.controlName) }}>Confirm</MDBBtn>
                    <MDBBtn size="sm" onClick={toggleTalentModal}>Cancel</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )
    }
    function RepresentationExtensions() {
        return (
            <MDBModal isOpen={dataCreationModals.representationExtensions} size='lg' >
                <MDBModalHeader toggle={() => setModal(!dataCreationModals.representationExtensions)}>
                    Representative Extensions
                    </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer>

                        <MDBRow>
                            <MDBCol size="12">
                                <MDBDataTableV5
                                    hover
                                    responsive id="dashboard"
                                    data={data}
                                    searchTop={false}
                                    searchBottom={false}
                                    noRecordsFoundLabel=" "
                                >
                                </MDBDataTableV5>
                            </MDBCol>

                        </MDBRow>

                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" onClick={() => setModal(!dataCreationModals.representationExtensions)}>Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )
    }
    function CompanyExtensions() {
        return (
            <MDBModal isOpen={dataCreationModals.companyExtensions} size='lg' >
                <MDBModalHeader toggle={() => setModal(!dataCreationModals.companyExtensions)}>
                    Company Extensions
                    </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer>

                        <MDBRow>
                            <MDBCol size="12">
                                <MDBDataTableV5
                                    hover
                                    responsive id="dashboard"
                                    data={compData}
                                    searchTop={false}
                                    searchBottom={false}
                                    noRecordsFoundLabel=" "
                                >
                                </MDBDataTableV5>
                            </MDBCol>

                        </MDBRow>

                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" onClick={() => setModal(!dataCreationModals.companyExtensions)}>Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )
    }
    const createNewTalent = (controlName) => {
        setSave(true);
        toggleLoading('Saving...')
        DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'Talent ', {
            "TalentName": dataCreationModals.TalentNameSelected,
            "IsActive": true
        })
            .then(response => response)
            .then(data => {
                DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'Talent')
                    .then(response => response.json())
                    .then(data => {
                        let newData = data.map(talents => ({
                            'talent': talents.TalentName,
                            'value': talents.TalentId,
                            'IsActive': talents.IsActive
                        }));
                        let newExp = data.map(existingProducer => ({
                            'ExistingProducerName': existingProducer.TalentName,
                            'ExistingProducerId': existingProducer.TalentId
                        }))
                        dealDetailsForm.talentInfo = newData;
                        dealDetailsForm.ExistingProdData = newExp;
                        var maxid = 0;
                        var maxobj;
                        data.sort(function (a, b) {
                            return a.TalentId - b.TalentId
                        });
                        data.map(function (obj) {
                            if (obj.TalentId > maxid) maxobj = obj;
                        });
                        if (controlName != 'Existing Producers') {
                            var r = []// dealDetailsForm.selectedRepresentatives;
                            if (maxobj) {
                                let newTalent = {
                                    'talent': maxobj.TalentName,
                                    'value': maxobj.TalentId,
                                    'IsActive': true
                                }
                                r.push(newTalent);
                                onTalentSelection(r);
                            }
                            toggleTalentModal();
                        } else {
                            if (maxobj) {
                                let ep = dealDetailsForm.selectedExistingProducerData;
                                let newTalent = {
                                    'ExistingProducerName': maxobj.TalentName,
                                    'ExistingProducerId': maxobj.TalentId
                                }
                                ep.push(newTalent);
                                setDealDetailsForm({
                                    ...dealDetailsForm,
                                    selectedExistingProducerData: ep
                                });
                            }
                            toggleTalentModal();
                        }
                        setSave(false);
                        toggleLoading('')

                    })
            });
    }
    const toggleTalentCreationModal = () => {
        setModal({
            ...dataCreationModals,
            createTalentFormModal: !dataCreationModals.createTalentFormModal,
        });
    }

    //End Talent Region

    //Project Region

    const toggleRepresentative = (formType, selectedRep, talentRep) => {

        if (formType == 'C') {
            setRepCreation({
                ...representativeCreation,
                addRepresentativeModal: !representativeCreation.addRepresentativeModal,
            });
        } else {
            settalentRepresentation(talentRep)
            if (formType == 'A') {
                setRepForm({
                    ...RepresentativeForm,
                    Mode: 'A'
                });
                onRepresentativeChange(null, null, 'A', null);
            } else if (formType == 'E' && selectedRep) {
                // let r = dealDetailsForm.repsData.find((element) => {
                //   return element.RepresentativeId === selectedRep.RepresentativeExtension.RepresentativeId;
                //   //  return element.RepresentativeId === selectedRep.CompanyExtension.CompanyId
                // })
                if (selectedRep.RepresentativeExtension) {
                    onRepresentativeChange(null, selectedRep.RepresentativeExtension, 'E', selectedRep.CompanyExtension);
                } else {
                    setRepForm({
                        ...RepresentativeForm,
                        Mode: 'E'
                    });
                }
            }
            setRepCreation({
                ...representativeCreation,
                addRepresentativeModal: !representativeCreation.addRepresentativeModal,
                isCreate: formType == 'A' ? true : false
            });
        }

    }

    const onCancel = () => {
        history.push('/dashboard');
    }


    const notifications = (status, message, timeOut, submissionType = 0, dealId = 0) => {
        switch (status) {
            case 'info':
                NotificationManager.info(message, 'Deal', timeOut);
                if (submissionType == 2) {
                    setTimeout(() => {
                        history.push('/dashboard')
                    }, timeOut);
                } else if (submissionType == 1 && dealId != 0) {
                    setTimeout(() => {
                        history.push('/dealrequestform?dealId=' + dealId);
                        window.location.reload(false);
                    }, 100);
                } else {
                    NotificationManager.info(message, 'Deal', timeOut);
                }
                break;
            case 'success':
                NotificationManager.success(message, 'Deal', timeOut);
                settempValidate(false)
                break;
            case 'warning':
                NotificationManager.warning(message, 'Deal', timeOut);
                break;
            case 'error':
                NotificationManager.error(message, 'Deal', timeOut, () => {
                });
                settempValidate(false)
                break;
        }
    }
    const onSave = () => {

    }
    const checkRepAndCompanyChanges = (repChanged, companyChanged) => {
        if (repChanged) {
            toggleTalentExtension()
        } else if (companyChanged) {
            toggleCompanyExtension();
        }
    }

    const RepresentationDomChange = () => {
        let newItem = addItem;
        let value = existingITem;
        let a = dealDetailsForm.representativesData;
        newItem[0].RepresentativeCompanyModels[0].CompanyExtension.IsLastUsed = companyExtensionSelection;
        newItem[0].RepresentativeCompanyModels[0].RepresentativeExtension.IsLastUsed = talentExtensionSelection;
        for (const [index, v] of a.entries()) {
            if (v[0].TalentModel.TalentId === value[0].TalentModel.TalentId) {
                if (RepresentativeForm.Mode == 'A') {
                    if (a[index][0].TalentRepresentativeId == null &&
                        a[index][0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId == null) {
                        a[index][0] = newItem[0];
                    }
                    else {
                        a[index].push(newItem[0]);

                    }
                } else if (RepresentativeForm.Mode == 'E') {
                    for (const [j, va] of v.entries()) {
                        if (va.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId
                            == newItem[0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId) {
                            a[index][j] = newItem[0];
                        }
                    }
                }
                saveRepCompany(newItem[0], a);
                break;
            }
        }
    }
    const saveRepCompany = (newItem, a) => {
        if (newItem) {
            toggleLoading('Saving...')
            DealerService.postData(Constants.DealerSharedServiceBaseUrl +
                'RepresentativeCompany', newItem)
                .then(res1 => {
                    // do good things
                    if (res1.data) {
                        //check if new rep of company
                        // if (newItem.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId == 0 ||
                        //  newItem.RepresentativeCompanyModels[0].CompanyExtension.CompanyId) {
                        //GET REP AND Company Details
                        //res1.data[0]
                        if (newItem.TalentRepresentativeId == null) {
                            newItem.TalentRepresentativeId = res1.data[0].TalentRepresentativeId
                        }
                        let RepsData = [];
                        let CompaniesData = [];
                        let newRep = [];
                        let newCompany = [];
                        DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'Representative')
                            .then(response => response.json())
                            .then(data => {
                                RepsData = data
                                dealDetailsForm.repsData = RepsData;
                                setDealDetailsForm(dealDetailsForm => {
                                    return {
                                        ...dealDetailsForm,
                                        repsData: RepsData
                                    }
                                });

                                // setDealDetailsForm({
                                //   ...dealDetailsForm,
                                //   repsData: RepsData
                                // })
                                RepsData.map(function (obj) {
                                    if (obj.RepresentativeName == newItem.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeName) {
                                        if (talentExtensionSelection) {
                                            newItem.RepresentativeCompanyModels[0].RepresentativeExtension = obj;
                                        }
                                        if (RepresentativeForm.Mode == 'A') {
                                            newItem.RepresentativeCompanyModels[0].RepresentativeExtension.IsNewlyAdd = true;
                                        }
                                    }
                                });
                                // if (!talentExtensionSelection) {
                                //append representation coming from post data
                                if (res1.data && res1.data.length > 0) {
                                    newItem.RepresentativeCompanyModels[0].RepresentativeExtension =
                                        res1.data[0].RepresentativeCompanyModels[0].RepresentativeExtension;
                                }
                                //}
                                DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'Company')
                                    .then(response => response.json())
                                    .then(res => {
                                        CompaniesData = res;
                                        // setDealDetailsForm({
                                        //   ...dealDetailsForm,
                                        //   companiesData: CompaniesData,
                                        //   repsData: RepsData
                                        // })
                                        dealDetailsForm.companiesData = CompaniesData
                                        setDealDetailsForm(dealDetailsForm => {
                                            return {
                                                ...dealDetailsForm,
                                                companiesData: CompaniesData,
                                                repsData: RepsData
                                            }
                                        });
                                        CompaniesData.map(function (obj1) {
                                            if (obj1.CompanyName == newItem.RepresentativeCompanyModels[0]
                                                .CompanyExtension.CompanyName) {
                                                newItem.RepresentativeCompanyModels[0].CompanyExtension = obj1
                                            }
                                        });
                                        // if (!companyExtensionSelection) {
                                        //append representation coming from post data
                                        if (res1.data && res1.data.length > 0) {
                                            newItem.RepresentativeCompanyModels[0].CompanyExtension =
                                                res1.data[0].RepresentativeCompanyModels[0].CompanyExtension;
                                        }
                                        // newItem.RepresentativeCompanyModels[0].CompanyExtension = obj;
                                        // }
                                        for (const [index, v] of a.entries()) {
                                            if (v[0].TalentModel.TalentId === newItem.TalentModel.TalentId) {
                                                for (const [j, va] of v.entries()) {
                                                    // if (va.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId
                                                    //  == 0 || va.RepresentativeCompanyModels[0].CompanyExtension.CompanyId == 0) {
                                                    va = newItem;
                                                    // }
                                                }
                                            }
                                        }
                                        settalentExtensionSelection(true);
                                        setcompanyExtensionSelection(true);
                                        setDealDetailsForm({
                                            ...dealDetailsForm,
                                            representativesData: a,
                                            repCompanyLoader
                                                : false
                                        }, [toggleRepresentative('C', null)])
                                    });
                            });
                        // }
                        // else {
                        //   setDealDetailsForm({
                        //     ...dealDetailsForm,
                        //     representativesData: a
                        //   }, [toggleRepresentative('C', null)])
                        // }

                    }
                    else {
                        setDealDetailsForm({
                            ...dealDetailsForm,
                            repCompanyLoader: true
                        })
                        toggleRepresentative('C', null)
                    }
                    toggleLoading('');
                })
                .catch(err => {
                    notifications('error', 'Something went wrong', 3000)
                    setDealDetailsForm({
                        ...dealDetailsForm,
                        repCompanyLoader: false
                    })
                    toggleRepresentative('C', null)
                    toggleLoading('');
                });

        }
    }


    const changeHandler = event => {

    };
    const onRepresentativeChange = (event, value, formType, companyExt) => {

        setcompanyExtChange(false);
        var selectedType = RepresentativeForm.representativeType = [];
        var selectedRep = RepresentativeForm.representative = [];
        var selectedCompnay = RepresentativeForm.company = [];
        if (value) {
            let r = []
            if (value.RepresentativeTypeId && value.RepresentativeTypeId > 0) {
                r = dealDetailsForm.representativeTypesData.find((element) => {
                    return element.Id === value.RepresentativeTypeId;
                })
            } else {

            }
            let company = []
            if (companyExt) {
                company = companyExt;
            } else {
                if (value && value.CompanyId) {
                    company = dealDetailsForm.companiesData.find((element) => {
                        return element.CompanyId === value.CompanyId;
                    })
                }
            }
            selectedCompnay = company ? company : []
            selectedRep = value;
            selectedType = r;
            if (value) {
                setRepForm({
                    ...RepresentativeForm,
                    isRepSelected: true,
                    representativeType: selectedType,
                    representative: selectedRep,
                    company: selectedCompnay,
                    email: value.Email && value.Email != "null" ? value.Email : '',
                    phoneNumber: value.Phone1 && value.Phone1 != "null" ? value.Phone1 : '',
                    phoneNumber2: value.Phone2 && value.Phone2 != "null" ? value.Phone2 : '',
                    fax: value.Fax && value.Fax != "null" ? value.Fax : '',
                    Address1: company && company.Address ? company.Address : '',
                    addres2: company && company.Address2 ? company.Address2 : '',
                    city: company && company.City ? company.City : '',
                    State: company && company.State ? company.State : '',
                    Country: company && company.Country ? company.Country : '',
                    Zip: company && company.zip ? company.zip : '',
                    isUSeForPaymentAddress: value.UseForPaymentAddress ? value.UseForPaymentAddress : false,
                    isCC: value.CC ? value.CC : false,
                    Mode: formType
                }, [setSelection()]);
            }
        } else {
            setRepForm({
                ...RepresentativeForm,
                isRepSelected: false,
                Mode: 'A'
            });
        }
    }

    const setSelection = () => {

    }
    const onRepresentativeTypeChange = (event, value) => {
        setRepForm({
            ...RepresentativeForm,
            representativeType: value
        });
    }

    const onFormDataChange = (event, value) => {
        if (event.target.name == 'phoneNumber' || event.target.name == 'phoneNumber2') {
            let isChar = isNumber(event.target.value.charCodeAt(0));
            if (isChar) {
                setRepForm({
                    ...RepresentativeForm,
                    [event.target.name]: event.target.value
                });
            }
        } else {
            setRepForm({
                ...RepresentativeForm,
                [event.target.name]: event.target.value
            });
        }
    }
    const isNumber = (charCode) => {
        //evt = (evt) ? evt : window.event;
        // var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    let onPDFGen = () => {
        if (dealDetailsForm.dealId) {
            DealerService.getData(Constants.DealerServiceBaseUrl + 'Deal' + '?dealId=' + dealDetailsForm.dealId)
                .then(response => {
                    let responseData = response.data[0];
                    DealPDFDocGen(responseData);
                });
        }
        else {
            alert("please save the deal before previewing");
        }
    }
    const onCompanyChange = (event, value) => {

        setCompanyChange(value);
        if (RepresentativeForm.Mode == 'A') {
            //No Change in
            if (value) {
                if (isEqual(RepresentativeForm.company, value) || value.CompanyId == 0) {
                    if (value) {
                        var company = value;
                        setRepForm({
                            ...RepresentativeForm,
                            company: company,
                            Address1: company && company.Address ? company.Address : '',
                            addres2: company && company.Address2 ? company.Address2 : '',
                            city: company && company.City ? company.City : '',
                            State: company && company.State ? company.State : '',
                            Country: company && company.Country ? company.Country : '',
                            Zip: company && company.zip ? company.zip : '',
                        });
                    }
                } //Change in Company
                else {
                    debugger;
                    if (RepresentativeForm.company.CompanyId &&
                        RepresentativeForm.company.CompanyId > 0) {

                        if (RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId > 0) {
                            toggleDeleteCompany();
                        }
                    }
                    if (value) {
                        var company = value;
                        setRepForm({
                            ...RepresentativeForm,
                            company: company,
                            Address1: company ? company.Address : '',
                            addres2: company ? company.Address2 : '',
                            city: company ? company.City : '',
                            State: company ? company.State : '',
                            Country: company ? company.Country : '',
                            Zip: company ? company.zip : '',
                        });
                    }
                }
            } else {
                debugger;
                if (RepresentativeForm.representative &&
                    RepresentativeForm.representative.RepresentativeId > 0) {
                    toggleDeleteCompany();
                }
                setRepForm({
                    ...RepresentativeForm,
                    company: [],
                    Address1: '',
                    addres2: '',
                    city: '',
                    State: '',
                    Country: '',
                    Zip: '',
                });
            }
        } else if (RepresentativeForm.Mode == 'E') {
            if (!value) {
                setRepForm({
                    ...RepresentativeForm,
                    company: [],
                    Address1: '',
                    addres2: '',
                    city: '',
                    State: '',
                    Country: '',
                    Zip: '',
                });
            }
        }
        if (!representativeCreation.isCreate) {
            if (!companyExtChange) {
                debugger;
                toggleDeleteCompany();
            }
            else {
                if (value) {
                    let company = value;
                    setRepForm({
                        ...RepresentativeForm,
                        company: company,
                        Address1: company && company.Address ? company.Address : '',
                        addres2: company && company.Address2 ? company.Address2 : '',
                        city: company && company.City ? company.City : '',
                        State: company && company.State ? company.State : '',
                        Country: company && company.Country ? company.Country : '',
                        Zip: company && company.zip ? company.zip : '',
                    });
                }
            }
        }
    }

    const spinner = () => {
        if (dealDetailsForm.loading) {
            return (
                <MDBSpinner small />
            )
        }
        else
            return ('')
    }
    const getAnEmptyTalentRep = () => {
        return [
            {
                "TalentRepresentativeId": null,
                "IsTalentRepresntativeLinkActive": false,
                "TalentModel": {
                    "TalentId": 0,
                    "TalentName": ""
                },
                "RepresentativeCompanyModels": [
                    {
                        "CompanyExtension": {
                            "CompanyExtensionId": null,
                            "CompanyId": null,
                            "CompanyName": null,
                            "Address": null,
                            "Address2": null,
                            "City": null,
                            "State": null,
                            "Country": null,
                            "zip": null,
                            "IsLastUsed": true,
                            "IsExtensionChanged": false,
                            "RepresentativeCompanyId": null
                        },
                        "RepresentativeExtension": {
                            "RepresentativeExtensionId": null,
                            "RepresentativeId": null,
                            "RepresentativeName": null,
                            "Phone1": null,
                            "Phone2": null,
                            "Email": null,
                            "RepresentativeTypeId": null,
                            "RepresentativeTypeName": null,
                            "Fax": null,
                            "UseForPaymentAddress": false,
                            "CC": false,
                            "IsLastUsed": true,
                            "CompanyExtensionId": null,
                            "IsExtensionChanged": false
                        },
                        "RepresentativeCompanyId": null,
                        "IsRepresentativeCompanyLinkAsCurrent": true
                    }
                ]
            }
        ]
    }
    const submitHandler = (event, value) => {
        if (!(RepresentativeForm.representative
            && RepresentativeForm.representative.RepresentativeName)) {
            event.preventDefault();
            alert('Please select Representative  before submission');
            return false;
        } else if (!(RepresentativeForm.company
            && RepresentativeForm.company.CompanyName)) {
            event.preventDefault();
            alert('Please select Company  before submission');
            return false;
        }
        else if (!(RepresentativeForm.representativeType
            && RepresentativeForm.representativeType.Id > 0)) {
            event.preventDefault();
            alert('Please select Representative Type  before submission');
        }
        // else if (RepresentativeForm.email == '') {
        //   event.preventDefault();
        //   alert('Please enter email  before submission');
        //   return false;
        // }
        // else if (RepresentativeForm.phoneNumber == '') {
        //   event.preventDefault();
        //   alert('Please enter Phone1 details  before submission');
        //   return false;
        // }
        else {
            event.preventDefault();
            let newItem = [];
            let repChanged = true;
            let companyChanged = true;
            setisCompanyChange(true);
            setisTalentChange(true);
            if (RepresentativeForm.Mode == 'A' || RepresentativeForm.Mode == 'E') {
                let selectedRep = dealDetailsForm.repsData.find((element) => {
                    return element.RepresentativeId === RepresentativeForm.representative.RepresentativeId
                })
                if (selectedRep &&
                    (RepresentativeForm.email == "" || selectedRep.Email == RepresentativeForm.email)
                    && (RepresentativeForm.phoneNumber == "" || selectedRep.Phone1 == RepresentativeForm.phoneNumber)
                    && (RepresentativeForm.fax == "" || selectedRep.Fax == RepresentativeForm.fax)
                    && (RepresentativeForm.phoneNumber2 == "" || selectedRep.Phone2 == RepresentativeForm.phoneNumber2)
                    && selectedRep.RepresentativeTypeId == RepresentativeForm.representativeType.Id
                    && selectedRep.CC == RepresentativeForm.isCC
                    && selectedRep.UseForPaymentAddress == RepresentativeForm.isUSeForPaymentAddress) {
                    repChanged = false;
                } else {
                    repChanged = true;
                    RepresentativeForm.representative.Email = RepresentativeForm.email;
                    RepresentativeForm.representative.Fax = RepresentativeForm.fax;
                    RepresentativeForm.representative.Phone1 =
                        RepresentativeForm.phoneNumber ? RepresentativeForm.phoneNumber : null;
                    RepresentativeForm.representative.Phone2 = RepresentativeForm.phoneNumber2
                        ? RepresentativeForm.phoneNumber2 : null;
                    RepresentativeForm.representative.RepresentativeTypeId = RepresentativeForm.representativeType.Id
                    RepresentativeForm.representative.RepresentativeTypeName = RepresentativeForm.representativeType.Name
                    RepresentativeForm.representative.CC = RepresentativeForm.isCC;
                    RepresentativeForm.representative.UseForPaymentAddress = RepresentativeForm.isUSeForPaymentAddress;
                }

                let selectedcompany = dealDetailsForm.companiesData.find((element) => {
                    return element.CompanyId === RepresentativeForm.company.CompanyId;
                })
                if (selectedcompany
                    && (RepresentativeForm.Address1 == "" || selectedcompany.Address == RepresentativeForm.Address1)
                    && (RepresentativeForm.addres2 == "" || selectedcompany.Address2 == RepresentativeForm.addres2)
                    && (RepresentativeForm.city == "" || selectedcompany.City == RepresentativeForm.city)
                    && (RepresentativeForm.State == "" || selectedcompany.State == RepresentativeForm.State)
                    && (RepresentativeForm.Country == "" || selectedcompany.Country == RepresentativeForm.Country)
                    && (RepresentativeForm.Zip == "" || selectedcompany.zip == RepresentativeForm.Zip)) {
                    companyChanged = false;
                } else {
                    companyChanged = true;
                    RepresentativeForm.company.Address = RepresentativeForm.Address1;
                    RepresentativeForm.company.Address2 = RepresentativeForm.addres2;
                    RepresentativeForm.company.City = RepresentativeForm.city;
                    RepresentativeForm.company.State = RepresentativeForm.State;
                    RepresentativeForm.company.Country = RepresentativeForm.Country;
                    RepresentativeForm.company.zip = RepresentativeForm.Zip;
                }
                if (RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId == 0) {
                    repChanged = false;
                }
                if (RepresentativeForm.company && RepresentativeForm.company.CompanyId == 0) {
                    companyChanged = false;
                }

                let selectedRepType = [];
                if (RepresentativeForm.representativeType && RepresentativeForm.representativeType.Id != 0) {
                    selectedRepType = dealDetailsForm.representativeTypesData.find((element) => {
                        return element.Id === RepresentativeForm.representativeType.Id
                    })
                } else {
                    selectedRepType = dealDetailsForm.representativeTypesData[0];
                }
                RepresentativeForm.representative.RepresentativeTypeId = selectedRepType.Id;
                RepresentativeForm.representative.RepresentativeTypeName = selectedRepType.Name;
                RepresentativeForm.company.IsExtensionChanged =
                    RepresentativeForm.company && RepresentativeForm.company.CompanyId == 0 ?
                        true : companyChanged;
                if (RepresentativeForm.company && RepresentativeForm.company.CompanyExtensionId == null) {
                    companyChanged = false;
                    RepresentativeForm.company.IsExtensionChanged = true;
                }
                RepresentativeForm.representative.IsExtensionChanged =
                    RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId == 0 ?
                        true : repChanged;
                //RepresentativeForm.represntative.CompanyExtensionId = RepresentativeForm.company.CompanyExtensionId
                if (RepresentativeForm.Mode == 'A') {
                    RepresentativeForm.representative.IsNewlyAdd = true;
                }
            }
            if (value && value != "") {
                let obj = {
                    "TalentRepresentativeId": value[0].TalentRepresentativeId,
                    "IsTalentRepresntativeLinkActive": value[0].IsTalentRepresntativeLinkActive,
                    "TalentModel": {
                        "TalentId": value[0].TalentModel.TalentId,
                        "TalentName": value[0].TalentModel.TalentName
                    },
                    "RepresentativeCompanyModels": [{
                        "RepresentativeCompanyId": RepresentativeForm.company.RepresentativeCompanyId ? RepresentativeForm.company.RepresentativeCompanyId : 0,
                        //"RepresentativeCompanyId": null,
                        "IsRepresentativeCompanyLinkAsCurrent": true,
                        "CompanyExtension":
                            RepresentativeForm.company,
                        "RepresentativeExtension": RepresentativeForm.representative,
                    }]
                }

                if (IsRepresentativeCompanyLinkAsCurrent && IsRepresentativeCompanyLinkAsCurrent == true) {
                    obj.RepresentativeCompanyModels[0].IsRepresentativeCompanyLinkAsCurrent = true;
                } else {
                    if (RepresentativeForm.representative.RepresentativeExtensionId == 0) {
                        obj.RepresentativeCompanyModels[0].IsRepresentativeCompanyLinkAsCurrent = true;
                    } else {
                        obj.RepresentativeCompanyModels[0].IsRepresentativeCompanyLinkAsCurrent = IsRepresentativeCompanyLinkAsCurrent;
                    }

                }

                newItem.push(obj);

            } else {
                //When Company Changes check
            }
            //set whole item in state
            repChanged = false;
            companyChanged = false;
            //Submission Logic
            if (repChanged || companyChanged) {
                setnewItem(newItem, [setvalue(value)]);
                setisTalentChange(repChanged);
                setisCompanyChange(companyChanged);
                setrepCompanyDomChange(newItem, [checkRepAndCompanyChanges(repChanged, companyChanged)]);
            }//If nothing is changes
            else {
                //Disable Save Button 
                setDealDetailsForm({
                    ...dealDetailsForm,
                    repCompanyLoader: true
                })
                let a = dealDetailsForm.representativesData;
                newItem[0].RepresentativeCompanyModels[0].CompanyExtension.IsLastUsed
                    = RepresentativeForm.company && RepresentativeForm.company.CompanyId == 0
                        ? true : companyExtensionSelection;
                newItem[0].RepresentativeCompanyModels[0].RepresentativeExtension.IsLastUsed
                    = RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId == 0 ?
                        true : talentExtensionSelection;
                for (const [index, v] of a.entries()) {
                    if (v[0].TalentModel.TalentId === value[0].TalentModel.TalentId) {
                        if (RepresentativeForm.Mode == 'A') {
                            if (a[index][0].TalentRepresentativeId == null &&
                                a[index][0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId == null) {
                                a[index][0] = newItem[0];
                            }
                            else {
                                a[index].push(newItem[0]);
                            }
                        }
                        else if (RepresentativeForm.Mode == 'E') {
                            for (const [j, va] of v.entries()) {
                                if (va.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId
                                    == newItem[0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId) {
                                    a[index][j] = newItem[0];
                                }
                            }
                        }
                        saveRepCompany(newItem[0], a);
                        break;
                    }
                }
            }
            // Append This new addition to existing Section
        }
    }


    return (
        <MDBContainer fluid>
            <MDBRow>
                {/* <DealCreationForm /> */}
                <MDBContainer className="sectionContainer">
                    <MDBRow className="sectionBgColor" >
                        <MDBCol >
                            <label>Manage Talents</label>
                        </MDBCol>
                        <MDBCol>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md="4">
                            <TalentViews options={dealDetailsForm.talentInfo && dealDetailsForm.talentInfo.length > 0 ?
                                dealDetailsForm.talentInfo : []}
                                value={dealDetailsForm.selectedRepresentatives.length > 0
                                    ? dealDetailsForm.selectedRepresentatives : []}
                                change={talentChange} ManageTalent={true}
                                keyPress={titleKeyPress} isformValidated={formValidate}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md="4">
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" className="custom-control-input" id="chkActive"
                                    checked={dealDetailsForm.selectedRepresentatives.length > 0 ?
                                        dealDetailsForm.selectedRepresentatives[0].IsActive : false}
                                    onChange={chkChange} />
                                <label className="custom-control-label" for="chkActive">Active</label>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer className="sectionContainer">
                    <MDBRow>
                        <MDBCol className="sectionBgColor">
                            <label >REPRESENTATION</label>
                            <br></br>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol >
                            <TalentSpinner></TalentSpinner>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow >
                        <MDBCol>
                            {dealDetailsForm.selectedRepresentatives && dealDetailsForm.selectedRepresentatives.length == 0 ?
                                <span className="custom-control-label-override">Please select the talent(s) before accessing this section</span>
                                : ''}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow >
                        <MDBCol>
                            <Representatives disabled={false}></Representatives>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer id="btn" >
                    <MDBRow>
                        <MDBCol col="3">
                            <MDBBtn id="btnCancel" onClick={() => onCancel()} >Cancel</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBRow >
            <MDBRow>
                <CreateTalentModalConfirmation></CreateTalentModalConfirmation>
            </MDBRow>
            <MDBRow>
                <RepresentativeModal isOpen={representativeCreation.addRepresentativeModal}
                    representativeCreation={representativeCreation}
                    RepresentativeData={dealDetailsForm.repsData && dealDetailsForm.repsData.length > 0 ?
                        dealDetailsForm.repsData : []}
                    isCreate={!representativeCreation.isCreate}
                    onRepresentativeChange={onRepresentativeChange}
                    onFormDataChange={onFormDataChange}
                    RepresentativeForm={RepresentativeForm} isDisabled={dealDetailsForm.repCompanyLoader}
                    RepresentativeTypesData={dealDetailsForm.representativeTypesData}
                    onRepresentativeTypeChange={onRepresentativeTypeChange} chkRepChange={chkRepChange}
                    onCompanyChange={onCompanyChange}
                    submitHandler={submitHandler}
                    CompaniesData={dealDetailsForm.companiesData && dealDetailsForm.companiesData.length > 0 ?
                        dealDetailsForm.companiesData : []}
                    toggleRepresentative={toggleRepresentative}
                    selectedRepresentative={selectedRepresentative} talentRepresentation={talentRepresentation}
                ></RepresentativeModal>
                <DisassociateCompany isOpen={dataCreationModals.deleteCompanyModle}
                    toggleDeleteCompany={toggleDeleteCompany}
                    onCompanyDelete={onCompanyDelete} confirmRepCompanyDeletion={confirmRepCompanyDeletion}
                    checkedProp={dataCreationModals.deleteRepresentationCompanySelection}></DisassociateCompany>
                <DisassociateTalent
                    isOpen={dataCreationModals.deleteTalentRepresentationModal}
                    onDelChange={onDelChange}
                    checkedProp={dataCreationModals.deleteTalentRepresentationSelection}
                    confirmTalentRepDeletion={confirmTalentRepDeletion}
                    toggleDeleteTalentRepresentationModal={toggleDeleteTalentRepresentationModal}></DisassociateTalent>
                <NotificationContainer />
                <CompanyExtensionChanged isOpen={companyModal}
                    onChange={onCompanyExtPopValChange} confirmation={toggleCompanyExtension}
                    selection={companyExtensionSelection}></CompanyExtensionChanged>
                <TalentExtensionChanged isOpen={talentModal}
                    onChange={onTalentExtPopValChange} confirmation={toggleTalentExtension}
                    selection={talentExtensionSelection}></TalentExtensionChanged>
                {/* <AddOtherCommentsModal isOpen={addComments.isOpen} toggleCommentsModal={toggleCommentsModal} saveOtherComments={saveOtherComments}></AddOtherCommentsModal> */}
            </MDBRow>
            <MDBRow>
            </MDBRow>
            <PageSpinner pageSpinner={loading.pageSpinner} message={loading.message}></PageSpinner>
            <RepresentationExtensions></RepresentationExtensions>
            <CompanyExtensions></CompanyExtensions>
        </MDBContainer >
    );
}
export default ManageTalents;
