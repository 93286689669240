import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon
} from "mdbreact";
import { BrowserRouter as Router } from 'react-router-dom';
import logo from '../../assets/logo.png';
import FaceIcon from '@material-ui/icons/Face';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { withOktaAuth } from '@okta/okta-react';
export default withOktaAuth(class HeaderContent extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isLoggedIn : props.isLoggedIn
        };
        this.onClick = this.onClick.bind(this);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }
    
    async logout () { 
    // Redirect to '/' after logout
    this.props.authService.logout('/');
    }


    async login () { 
        // Redirect to '/' after logout
        this.props.authService.login('/');
    }

    render() {
        const bgColorBlue = { backgroundColor: '#0062AF' }

        return (
            <div >
                <Router fixed="top">
                    <header>
                        <MDBNavbar style={bgColorBlue} dark expand="md" scrolling fixed="top"   >
                            <MDBNavbarBrand>
                                <img
                                    src={logo}
                                    alt='Logo' className="imageWidth" />
                            </MDBNavbarBrand>
                            <div className="headerSpace"></div>
                            <h1 className="headerFont" id='dealer'>
                                DEALER</h1>
                            <MDBNavbarNav right>
                                {/* <MDBNavItem>
                                    <MDBNavLink  to="/">
                                    <MDBIcon icon="cog" />
                                    </MDBNavLink>
                                </MDBNavItem> */}
                                <MDBNavItem >
                                    <MDBDropdown >
                                        <MDBDropdownToggle nav caret>
                                                <FaceIcon style={{ fontSize: 45 }} color="inherit" />
                                            {/* <MDBIcon far icon="user-circle" size="3x"/> */}
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu  className="dropdown-default">
                                            <MDBDropdownItem className = {!this.state.isLoggedIn ? "d-none":"d-block"} disabled={!this.state.isLoggedIn} onClick={this.logout}>Logout</MDBDropdownItem>
                                            <MDBDropdownItem className = {this.state.isLoggedIn ? "d-none":"d-block"} disabled={this.state.isLoggedIn} onClick={this.login}>Login</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                            </MDBNavbarNav>
                        </MDBNavbar>
                    </header>
                </Router>
            </div>
        );
    }
}
);
//export default HeaderContent;