import React, { useState, useEffect, Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';


class AddOtherCommentsModal extends Component {

    constructor(props) {
        super(props);
    }
   
    saveComments = () => {
       console.log('Saving Comments...');
    }
    toggle = () => {
        this.props.toggleCommentsModal();
    }
    render() {
        return <>
            <MDBModal isOpen={this.props.isOpen} toggle={this.props.toggleCommentsModal} size='sm' >
                <MDBModalHeader>
                    Add Submitter Comments
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol col="4" >
                            <label className="labelHeaders">Submitter Comments</label>
                                <textarea
                                    className="form-control" 
                                    id="comments"
                                    rows="2"
 
                                />
                            </MDBCol>
                        </MDBRow>
                        
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" onClick={this.saveComments}>Save</MDBBtn>
                    <MDBBtn size="sm" onClick={this.toggle}>Cancel</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

        </>
    }
}
export default AddOtherCommentsModal;