import React,{useState} from 'react';
import { useHistory,withRouter } from "react-router-dom";
import Autocomplete from '../components/FormElements/Autocomplete';
import SelectBox from '../components/FormElements/SelectBox';
import '../styles/Autocomplete.css';


import {MDBContainer,MDBCol,MDBRow} from 'mdbreact';



function DealRequestForm() {
  const [form, setState] = useState({
    position: '',
    division: ''
  });

  let PositionList = [
    'Writer',
    'Author',
    'Producer'
  ];
  const iTemList = [
    'First',
    'Second',
    'Third'
];

  let history = useHistory();


  const updateField = e => {
    setState({
      ...form,
      [e.target.name]: e.target.value
    });
   
  };
  const onselect = (data) => {
 
    console.log("AutoComplete>",  data );
    let pTitle = data;
    setState({
      ...form,
      position:data
    })
    console.log('ProjectTitle in p..', form.position);
    localStorage.setItem("form.position",data);
  }

  const onchange = (data) => {
    console.log('data...',data);
 
    setState({
      ...form,
      division :data
    })
    console.log('form.division....', form.division);
}

  const nextForm= () => {
    console.log(form);
    
    localStorage.setItem("form.division",form.division);
    history.push('/dealrequestform');
  }

  const prevForm= () => {
    
    history.push('/');
  }
    return (
          <MDBContainer fluid>
            <MDBRow>
              <MDBCol md='12' className='mt-4'>
                <p className='text-left mb-6'>Forms and Function</p>
                <h3 className='h6 text-left mb-6'>Deal Request Memo</h3>
  
              <form>
              
                <MDBRow>
                <MDBCol md='6'>
                  <MDBRow>
                    <MDBCol md="12" className='py-3'>
                  <label
                    htmlFor='dealMemoPosition'
                    className='grey-text'
                  >
                    Position
                  </label>
                  {/* <select class="custom-select custom-select-sm" 
                    id='dealMemoPosition' onChange={updateField} 
                    name='position'
                    value={form.position}
                  >
                     <option value="">Open this select menu</option>
                    <option value="One">One</option>
                    <option value="Two" >Two</option>
                    <option value="Three">Three</option>
                  </select> */}
                    <Autocomplete  
                        suggestions={PositionList}
                        id='dealMemoPosition' 
                        onselect={(e) => { onselect(e) }}  
                        value={form.position}
                      />
                      </MDBCol>
                      <MDBCol md='12' className='py-3'>
                    <label
                      htmlFor='dealmemoDivision'
                      className='grey-text'
                    >
                     Division
                    </label>
                    <SelectBox id="dealmemoDivision" optionList={iTemList} name="division"  value={form.division}  onchange={(e) => { onchange(e) }}/>
     
               </MDBCol>
                </MDBRow>
                      <MDBRow>
                      <MDBCol md="6">
                      <div className='text-center mt-4'>
                      <button className='btn btn-indigo'type='button' onClick={nextForm} >
                      Submit
                    </button>
                    </div>
                      </MDBCol>
                      <MDBCol md='6'>
                        <div className='text-center mt-4'>
                        <button className='btn btn-indigo'type='button' onClick={prevForm} >
                          Cancel
                        </button>
                        </div>
                      </MDBCol>
                   </MDBRow>
              </MDBCol>
                  
               
              </MDBRow>
            </form>
        </MDBCol> 
      </MDBRow>
  </MDBContainer>
        
     
    );
  
}

export  default withRouter(DealRequestForm);


