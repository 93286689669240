import React, { useRef, useState, useEffect } from 'react';
import TextBox from '../components/FormElements/TextBox';
import SelectBox from '../components/FormElements/SelectBox';
import { MDBDatePicker } from 'mdbreact';
import authService from "../app.config"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useHistory, withRouter } from "react-router-dom";
import {
  MDBContainer, MDBDataTableV5, MDBCol, MDBRow,
  MDBBtn, MDBSpinner, MDBInput, MDBModal, MDBSelect, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBInputGroup
} from 'mdbreact';
import RoutingDataGrid from './DealCreation/views/RoutingDataGrid';
import Representative from './DealCreation/views/Representative';
import RepresentativeType from './DealCreation/views/RepresentativeType';
import DealCreationComments from './DealCreation/views/DealCreationComments';
import DealCreationOtherComments from './DealCreation/views/DealCreationOtherComments';
import DealCreationEmail from './DealCreation/views/DealCreationEmailNotes';
import Role from './DealCreation/views/Role';
import Fee from './DealCreation/views/Fee';
import DealCreationProjectTitle from './DealCreation/views/DealCreationProjectTitle';
import AddEditRecipient from '../pages/Modal/AddEditRecipient';
import DisassociateCompany from '../pages/Modal/DisAssociateCopmany';
import DisassociateTalent from '../pages/Modal/DisAssociateTalent';
import CompanyExtensionChanged from '../pages/Modal/CompanyExtensionChanged';
import TalentExtensionChanged from '../pages/Modal/TalentExtensionChanged';
import CompanyAndDetailsView from '../pages/Modal/CompanyModal';
import TalentViews from './DealCreation/views/TalentViews';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { blue } from '@material-ui/core/colors';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateIcon from '@material-ui/icons/Create';
import AddCircle from '@material-ui/icons/AddCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import '../styles/Autocomplete.css';
import '../styles/Form.css';
import DealCreation from './DealCreation';
import dealService from '../services/dealCreationService/dealService';
import DealerService from '../services/service';
import * as Constants from '../constants/constants';
import RepresentativeModal from '../pages/Modal/RepresentativeModal';
import isEqual from 'lodash/isEqual';
import PageSpinner from './PageSpinner';
import { DealPDFDocGen } from './Modal/DealPDFDocViewer';
import AddOtherCommentsModal from '../pages/Modal/AddOtherCommentsModal';
function MoreDealInfo(props) {
  let history = useHistory();

  const [dealDetailsForm, setDealDetailsForm] = useState({
    dealId: '',
    projectId: null,
    projectTitle: '',
    projectTitleData: [],
    positionId: null,
    positionName: null,
    divisionId: null,
    divisionName: null,
    talentsData: [],
    creativeExecData: [],
    recipientsData: [],
    baExecutiveData: [],
    existingProducerData: [],
    brandData: [],
    releasetypeData: [],
    genreData: [],
    specialCategoryData: [],
    representativesData: [],
    distributors: [],
    creativeExecutivesSelected: [],
    selectedRepresentatives: [],
    selectedBAExecsData: [],
    selectedCreativeExecData: [],
    selectedExistingProducerData: [],
    selectedProjectData: [],
    selectedSpecialCategory: '',
    selectedBrand: '',
    selectedReleaseType: '',
    selectedWritingSteps: [],
    writingStepsData: [],
    lastStepWrittenData: [],
    fictionData: [],
    selectedLastSteps: [],
    selectedFiction: '',
    companiesData: [],
    repsData: [],
    representativeTypesData: [],
    materialData: [],
    selectedMaterial: '',
    comments: '',
    role: '',
    emailNotes: '',
    loading: false,
    talentLoader: false,
    repCompanyLoader: false,
    addEditReps: [],
    projectSelected: false,
    oneSterpGuarentee: [],
    selectedGenre: [],
    optionalStepsSelected: [],
    fictionSelected: [],
    Fee: '',
    isUrgent: false,
    IsCompetitive: false,
    talentInfo: [],
    ExistingProdData: [],
    optionalSteps: [],
    createdDate: '',
    formPosition: '',
    isEdit: false,
    submitterEmail: '',
    submitterAssistantEmail: '',
    SubmitterMappedActiveEmail: '',
    SubmitterMapAssActiveEmail: '',
    status: ''
  });
  const [dataCreationModals, setModal] = useState({
    projectConfirmationModal: false,
    TalentCreationModal: false,
    CreativeExecConfirmationModal: false,
    createTalentFormModal: false,
    createProjectFormModal: false,
    createExecFormModal: false,
    addRepresentativeModal: false,
    TalentNameSelected: '',
    ProjectNameSelected: '',
    CreativeExecNameSelected: '',
    controlName: '',
    deleteTalentRepresentationModal: false,
    deleteTalentRepresentationObject: [],
    deleteTalentRepresentationSelection: "1",
    deleteRepresentationCompanySelection: "1",
    deleteCompanyModle: false

  });
  const [save, setSave] = useState(false);

  const [loading, setLoading] = useState({
    pageSpinner: false,
    message: ''
  });

  const [mode, setMode] = useState({
    editMode: true,
    newMode: false,
    viewMode: false
  })

  const [representativeCreation, setRepCreation] = useState({
    addRepresentativeModal: false,
    isCreate: false,
  });
  const [RepresentativeForm, setRepForm] = useState({
    representative: [],
    representativeType: [],
    company: [],
    email: '',
    phoneNumber: null,
    Address1: null,
    addres2: null,
    city: null,
    State: null,
    Country: null,
    Zip: null,
    phoneNumber2: null,
    fax: null,
    isCC: false,
    isUSeForPaymentAddress: false,
    isAdd: false,
    isEdit: false,
    isRepSelected: false,
    Mode: 'A'
  });
  const [distributionGrid, setdistributionGrid] = useState({
    distributionDefaultApprovers: [],
    ApproversFromCreativeExec: [],
    ApproversNewlyAdded: [],
    finalDistributor: []
  });
  const [distributionAddModal, setdistributionAddModal] = useState({
    isOpen: false,
    mode: 'A',
    sequenceOptions: []
  });
  const [distributionReciepent, setdistributionReciepent] = useState({
    reciepient: [],
  });
  const [addEditRecipient, setaddEditRecipient] = useState({
    slectedRecipient: [],
    selectedAction: [],
    selectedSequence: []
  });
  const [addComments, setAddComments] = useState({
    isOpen: false,
    comments: []
  });

  //const [formValidate, setFormValidate] = useState(false);
  const [formValidate, setFormValidate] = React.useState(false);
  const [tempValidate, settempValidate] = React.useState(false);
  const [submissionType, setSubmissionType] = React.useState(1);
  const [companyChange, setCompanyChange] = React.useState([]);
  const [talentRepresentation, settalentRepresentation] = React.useState([]);
  const [IsRepresentativeCompanyLinkAsCurrent, setIsRepresentativeCompanyLinkAsCurrent] = React.useState(false);

  const [companyExtChange, setcompanyExtChange] = React.useState(false);

  const [repCompanyDomChange, setrepCompanyDomChange] = React.useState([]);
  const [addItem, setnewItem] = React.useState([]);
  const [existingITem, setvalue] = React.useState([]);

  const [companyModal, setcompanyModal] = React.useState(false);
  const [companyExtensionSelection, setcompanyExtensionSelection] = React.useState(true);
  const [talentModal, settalentModal] = React.useState(false);
  const [talentExtensionSelection, settalentExtensionSelection] = React.useState(true);
  const [isCompanyChange, setisCompanyChange] = React.useState(false);
  const [isTalentChange, setisTalentChange] = React.useState(false);

  const projectAliasInput = useRef();
  const RepresentativInput = useRef();
  const RepresentativeTypeInput = useRef();
  const projectNewInput = useRef();
  let ProjectTitlesData1 = [];

  const [selectedRepresentative, setSelectedRepresentative] = React.useState(null);
  const [dealId, setDealId] = React.useState(null);
  const queryString = require('query-string');
  const params = queryString.parse(window.location.search);

  useEffect(() => {
    let dealId = null;
    let preview = false;
    if (params.dealId) {
      setDealId(params.dealId);
      dealId = params.dealId;
    }

    if (params.preview === "true")
      preview = true;

    const fetchExistingDeal = (dealId, preview) => {
      if (dealId) {
        toggleLoading('Loading deal...');
        DealerService.getData(Constants.DealerServiceBaseUrl + 'Deal' + '?dealId=' + dealId)
          .then(response => {
            if (response && response.data.length == 0) {
              alert('You are not authorized to view this Deal');
              history.push('/dashboard');
              return;
            }
            let responseData = response.data[0];
            // if (responseData && !responseData.IsView) {
            //   alert('You are not authorized to view this Deal');
            //   history.push('/dashboard');
            // }
            if (preview) {
              DealPDFDocGen(responseData);
            }
            divisionId = responseData.DivisionId;
            positionId = responseData.PositionId;

            setMode(mode => {
              return {
                ...mode,
                viewMode: (responseData.Status != "Draft" ? true : false),
                editMode: (responseData.Status == "Draft" ? true : false),
              }
            });

            localStorage.setItem('position', responseData.PositionName);
            localStorage.setItem('positionId', responseData.PositionId);
            localStorage.setItem('division', responseData.DivisionName);
            localStorage.setItem('divisionId', responseData.DivisionId);
            fetchData(false);
            let repData = [];
            let repArray = []

            for (const [index, val] of responseData.TalentRepresentativeModels.entries()) {
              let innerArray = [];
              for (const [j, innerVal] of val.RepresentativeCompanyModels.entries()) {
                let arr = {
                  "TalentRepresentativeId": val.TalentRepresentativeId,
                  "IsTalentRepresntativeLinkActive": val.IsTalentRepresntativeLinkActive,
                  "TalentModel": val.TalentModel,
                  "RepresentativeCompanyModels": [innerVal]
                }
                innerArray.push(arr);
              }
              repArray.push(innerArray)
            }
            repData = repArray;
            // repData.push(Array.from(responseData.TalentRepresentativeModels));
            let talentsData = [];
            debugger;
            responseData.TalentRepresentativeModels.map(representative => {
              talentsData.push({
                "talent": representative.TalentModel.TalentName,
                "value": representative.TalentModel.TalentId,
                "IsActive": representative.TalentModel.IsActive,
              });
            });

            let selectedCreativeExecData = responseData.CreativeExecutives.map(creativeExec => (
              {
                'RecipientName': creativeExec.CreativeExecutiveName,
                'RecipientId': creativeExec.CreativeExecutiveId,
                'Division': (creativeExec.DivsionGroupRoles != null && creativeExec.DivsionGroupRoles.length > 0) ? creativeExec.DivsionGroupRoles[0].DivisionName : '',
                'Group': (creativeExec.DivsionGroupRoles != null && creativeExec.DivsionGroupRoles.length > 0) ? creativeExec.DivsionGroupRoles[0].Groups[0].GroupName : '',
                'Role': (creativeExec.DivsionGroupRoles != null && creativeExec.DivsionGroupRoles.length > 0) ? creativeExec.DivsionGroupRoles[0].Groups[0].Roles[0].RoleName : '',
                "Action": creativeExec.IsDefaultApprover ? 'Approver' : 'Copied',
                'Sequence': 0,
                "ActionId": creativeExec.IsDefaultApprover ? 1 : 2,
                "IsDefaultApprover": creativeExec.IsDefaultApprover,
                "IsActive": creativeExec.IsActive
              }));

            let writingSteps = [];
            if (responseData.WritingSteps.length > 0 && responseData.WritingSteps[0].WritingStepsId) {
              responseData.WritingSteps.map(writingStep => {
                writingSteps.push({
                  "value": writingStep.WritingStepsId,
                  "text": writingStep.WritingStepsName
                })
              });
            }
            let oneStepGuarenteeOnlySelected = [];
            oneStepGuarenteeOnlySelected = responseData.OneStepGuarenteeOnly ? OneStepGuaranteeOptions[0] : OneStepGuaranteeOptions[1];

            let materials = [];
            if (responseData.Materials.length > 0 && responseData.Materials[0].MaterialId) {
              responseData.Materials.map(material => {
                materials.push({
                  "value": material.MaterialId,
                  "text": material.MaterialName
                })
              });
            }
            let optionalSteps = [];
            if (responseData.OptionalSteps.length > 0 && responseData.OptionalSteps[0].OptionalStepId) {
              responseData.OptionalSteps.map(option => {
                optionalSteps.push({
                  "value": option.OptionalStepId,
                  "text": option.OptionalStepName
                })
              });
            }

            let selectedGenres = [];
            responseData.Genres.map(genre => {
              selectedGenres.push({
                "value": genre.GenreId,
                "name": genre.GenreName
              })
            });

            let lastStepWritten = []
            if (responseData.LastStepWrittenId) {
              lastStepWritten = {
                "text": responseData.LastStepWrittenName,
                "value": responseData.LastStepWrittenId
              };
            }
            let fictionData = []
            if (responseData.FictionId) {
              fictionData = {
                "text": responseData.FictionName,
                "value": responseData.FictionId
              };
            }
            let createdDate = responseData.CreatedDate ?
              moment(responseData.CreatedDate).format('MM/DD/YYYY') : moment.format('MM/DD/YYYY');

            setDealDetailsForm(dealDetailsForm => {
              debugger
              return {
                ...dealDetailsForm,
                dealId: responseData.DealId,
                positionId: responseData.PositionId,
                positionName: responseData.PositionName,
                divisionId: responseData.DivisionId,
                divisionName: responseData.DivisionName,
                projectId: responseData.ProjectId,
                formPosition: responseData.PositionName,
                //projectTitleData: responseData.ProjectName,
                representativesData: repData,
                selectedRepresentatives: talentsData,
                selectedCreativeExecData: selectedCreativeExecData,
                selectedBAExecsData: (responseData.BAExecutiveId != null ?
                  [{
                    value: responseData.BAExecutiveId,
                    name: responseData.BAExecutiveName,
                    IsActive: responseData.BAIsActive
                  }]
                  : []),
                selectedExistingProducerData: responseData.ExistingProducers,
                selectedProjectData: {
                  "ProjectId": responseData.ProjectId,
                  "Name": responseData.ProjectName
                },
                selectedBrand: (responseData.BrandId != null ?
                  {
                    'value': responseData.BrandId,
                    'text': responseData.BrandName
                  }
                  : ''),
                selectedReleaseType: (responseData.ReleaseTypeId != null ?
                  {
                    'value': responseData.ReleaseTypeId,
                    'text': responseData.ReleaseTypeName
                  }
                  : ''),
                selectedSpecialCategory: (responseData.SpecialCategoryId != null ?
                  {
                    'value': responseData.SpecialCategoryId,
                    'text': responseData.SpecialCategoryName
                  }
                  : ''),
                selectedWritingSteps: writingSteps,
                createdDate: createdDate,
                role: responseData.Role,

                selectedGenre: selectedGenres,
                oneStepGuarenteeOnly: responseData.OneStepGuarenteeOnly,
                IsCompetitive: responseData.IsCompetitive,
                IsUrgent: responseData.IsUrgent,
                // S
                optionalSteps: responseData.OptionalSteps,
                // createdDate: responseData.CreatedDate,
                createdBy: responseData.CreatedBy,
                statusId: responseData.StatusId,
                status: responseData.Status,
                comments: responseData.Comments,
                emailNotes: responseData.EmailNote,
                fee: responseData.Fee ? Number(responseData.Fee) : 0,
                distributionModels: responseData.DistributionModels,
                approveRejcetQuerystring: responseData.ApproveRejcetQuerystring,
                optionalStepsSelected: optionalSteps,
                selectedLastSteps: lastStepWritten,
                Fee: responseData.Fee,
                fictionSelected: fictionData,
                selectedMaterial: materials,
                oneSterpGuarentee: oneStepGuarenteeOnlySelected,
                projectSelected: true,
                isEdit: responseData.IsEdit,
                submitterEmail: responseData.SubmitterEmail,
                SubmitterMapAssActiveEmail: responseData.SubmitterMapAssActiveEmail,
                SubmitterMappedActiveEmail: responseData.SubmitterMappedActiveEmail,
                submitterAssistantEmail: responseData.SubmitterAssistantEmail ? responseData.SubmitterAssistantEmail : ''
              }
            });

            setdistributionGrid(distributionGrid => {
              return {
                ...distributionGrid,
                // distributionDefaultApprovers: DefaultDistributorsResponse,
                finalDistributor: Array.from(responseData.DistributionModels)
              }
            });
            toggleLoading('');
          })
          .catch(err => {

          });
        //write your code here..
      }
    }


    const fetchData = (newDeal) => {


      dealService.fetchEntityDetails('Project')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {

            return {
              ...dealDetailsForm,
              projectTitleData: response.data
            }
          });
        });
      dealService.fetchEntityDetails('Talent')
        .then((response) => {
          debugger;
          let activeTalents = response.data.filter(function (el) {
            return el.IsActive
          });
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              talentsData: response.data,
              existingProducerData: response.data,
              talentInfo: activeTalents.map(talents => ({
                'talent': talents.TalentName,
                'value': talents.TalentId,
                'IsActive': talents.IsActive
              })),
              ExistingProdData: activeTalents.map(existingProducer => ({
                'ExistingProducerName': existingProducer.TalentName,
                'ExistingProducerId': existingProducer.TalentId,
                'IsActive': existingProducer.IsActive
              }))
            }
          });
        });

      dealService.fetchEntityDetails('CreativeExecutive', divisionId)
        .then((response) => {
          debugger;
          setDealDetailsForm(dealDetailsForm => {

            return {
              ...dealDetailsForm,
              creativeExecData: response.data,

            }
          });

        });
      dealService.fetchEntityDetails('User', divisionId)
        .then((response) => {

          setDealDetailsForm(dealDetailsForm => {

            return {
              ...dealDetailsForm,
              baExecutiveData: response.data,

            }
          });

        });


      dealService.fetchLookupEntities('Brand')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              brandData: response.data
            }
          });
        });

      dealService.fetchLookupEntities('ReleaseType')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              releasetypeData: response.data
            }
          });
        });

      dealService.fetchLookupEntities('Genre')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              genreData: response.data
            }
          });
        });
      dealService.fetchLookupEntities('SpecialCategory')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              specialCategoryData: response.data
            }
          });
        });

      dealService.fetchLookupEntities('Representativetype')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              representativeTypesData: response.data
            }
          });
        });

      dealService.fetchLookupEntities('WritingSteps')
        .then((response) => {

          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              writingStepsData: (response.data.length > 0 ? response.data : [])
            }
          });
        });

      dealService.fetchLookupEntities('LastStepWritten')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              lastStepWrittenData: response.data.length > 0 ? response.data.map(dt => ({
                text: dt.Name,
                value: dt.Id
              })) : []
            }
          });
        });

      dealService.fetchLookupEntities('OptionalSteps')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              optionalSteps: response.data.length > 0 ? response.data.map(opt => ({
                'text': opt.Name,
                'value': opt.Id
              })) : [],
            }
          });
        });

      dealService.fetchLookupEntities('Material')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              materialData: (response.data.length > 0 ? response.data : [])
            }
          });
        });

      dealService.fetchLookupEntities('Fiction')
        .then((response) => {
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              fictionData: (response.data.length > 0 ? response.data : [])
            }
          });
        });

      dealDetailsForm.formPosition = localStorage.getItem('position')
      dealService.fetchDealDetailsFormData(divisionId, dealDetailsForm.formPosition)
        .then((response) => {
          let responseData = response.map((resp) => (resp.data));
          const [
            RepresentativeDataResponse,
            CompaniesDataResponse,
            DefaultDistributorsResponse,
            AddEditRepResponse,
            RecipientsResponse] = responseData;
          debugger;
          setDealDetailsForm(dealDetailsForm => {
            return {
              ...dealDetailsForm,
              representativesData: (newDeal ? [] : dealDetailsForm.representativesData),
              distributors: (newDeal ? [] : dealDetailsForm.distributors),
              selectedRepresentatives: (newDeal ? [] : dealDetailsForm.selectedRepresentatives),
              companiesData: CompaniesDataResponse,
              repsData: RepresentativeDataResponse,
              addEditReps: AddEditRepResponse,
              recipientsData: RecipientsResponse,
              formPosition: localStorage.getItem('position')
            }
          });

          setdistributionGrid(distributionGrid => {

            return {
              ...distributionGrid,
              // distributionDefaultApprovers: (newDeal ? DefaultDistributorsResponse : 
              //   distributionGrid.distributionDefaultApprovers),
              //finalDistributor: (newDeal ? DefaultDistributorsResponse : distributionGrid.finalDistributor)
            }
          });
          //toggleLoading('');
        });
    }
    //togglePageLoading();
    if (dealId) {
      fetchExistingDeal(dealId, preview);
    }
    else {
      //toggleLoading('Fetching details for new deal');

      setMode(mode => {
        return {
          ...mode,
          newMode: true
        }
      });
      fetchData(true);
    }

    //togglePageLoading();
  }, []);


  const toggleLoading = (msg) => {
    setLoading(loading => {
      return {
        ...loading,
        pageSpinner: !loading.pageSpinner,
        message: msg
      }
    }
    );

  }
  let RepresentativeData = dealDetailsForm.repsData;
  let CompaniesData = dealDetailsForm.companiesData;

  let CreativeExecs = dealDetailsForm.creativeExecData.filter(function (el) {
    return el.IsActive
  }).map(creativeExec => ({
    'RecipientName': creativeExec.Name,
    'RecipientId': creativeExec.UserId,
    'Division': creativeExec.DivisionRoleGroups[0].DivisionName,
    'Group': creativeExec.DivisionRoleGroups[0].Groups[0].GroupName,
    'Role': creativeExec.DivisionRoleGroups[0].Groups[0].Roles[0].RoleName,
    "Action": creativeExec.IsDefaultApprover ? 'Approver' : 'Copied',
    "ActionId": creativeExec.IsDefaultApprover ? 1 : 2,
    'Sequence': 0,
    "IsDefaultApprover": creativeExec.IsDefaultApprover,
    "IsActive": creativeExec.IsActive
  }));
  let AddReps = dealDetailsForm.recipientsData.map(creativeExec => ({
    'RecipientName': creativeExec.Name,
    'RecipientId': creativeExec.UserId,
    'Division': creativeExec.DivisionRoleGroups[0].DivisionName,
    'Group': creativeExec.DivisionRoleGroups[0].Groups[0].GroupName,
    'Role': creativeExec.DivisionRoleGroups[0].Groups[0].Roles[0].RoleName,
    "Action": creativeExec.IsDefaultApprover ? 'Approver' : 'Copied',
    'Sequence': 0,
    "ActionId": creativeExec.IsDefaultApprover ? 1 : 2,
    "IsDefaultApprover": creativeExec.IsDefaultApprover,
    "IsActive": creativeExec.IsActive
  }));
  var today = moment().format('MM/DD/YYYY');
  let TalentNames = dealDetailsForm.talentsData.map(talents => ({
    'talent': talents.TalentName,
    'value': talents.TalentId,
    'IsActive': talents.IsActive
  }));


  let BAExecs = dealDetailsForm.baExecutiveData.filter(function (el) {
    return el.IsActive
  }).map(baExec => ({
    'name': baExec.Name,
    'value': baExec.UserId,
    'IsActive': baExec.IsActive,
  }));
  let ExistingProducer = dealDetailsForm.existingProducerData.
    map(existingProducer => ({ 'ExistingProducerName': existingProducer.TalentName, 'ExistingProducerId': existingProducer.TalentId }));
  let representativesData = dealDetailsForm.representativesData;
  let selectedPosition = localStorage.getItem('position');
  let divisionId = localStorage.getItem('divisionId');
  let positionId = localStorage.getItem('positionId');
  let WritingStepsOptions = [];

  if (dealDetailsForm.formPosition == 'Writer'
    || dealDetailsForm.formPosition == 'Writer/Director'
    || dealDetailsForm.formPosition == 'Writer/Producer'
    || dealDetailsForm.formPosition == 'Writer/Producer/Director') {

    WritingStepsOptions = dealDetailsForm.writingStepsData.map(dt => ({
      text: dt.Name,
      value: dt.Id
    }));
  }
  let LastStepWrittenOptions = [];
  if (dealDetailsForm.formPosition == 'Optional Step Form') {
    LastStepWrittenOptions = dealDetailsForm.lastStepWrittenData.map(dt => ({
      text: dt.Name,
      value: dt.Id
    }));
  }

  let MaterialsOption = [];
  let FictionOptions = [];
  if (dealDetailsForm.formPosition == 'Rights') {
    MaterialsOption = dealDetailsForm.materialData.map(dt => ({
      text: dt.Name,
      value: dt.Id
    }));
    FictionOptions = dealDetailsForm.fictionData.map(dt => ({
      text: dt.Name,
      value: dt.Id
    }));
  }



  let dropDownoptions = [
    {
      text: "Writer",
      value: "1",
    }

  ];

  let positionOptions = [
    {
      text: dealDetailsForm.formPosition == 'Optional Step Form' ? 'Writer' : dealDetailsForm.formPosition,
      value: dealDetailsForm.formPosition == 'Optional Step Form' ? 'Writer' : dealDetailsForm.formPosition,
      checked: true
    }
  ]
  let brandOptions = dealDetailsForm.brandData.map(brand => (
    {
      text: brand.Name,
      value: brand.Id
    }))
  let releaseTypeOptions = dealDetailsForm.releasetypeData.map(rt => (
    {
      text: rt.Name,
      value: rt.Id
    }))
  let genreOptions = dealDetailsForm.genreData.map(g => (
    {
      name: g.Name,
      value: g.Id,
    }
  ))

  let specialCategoryOptions = dealDetailsForm.specialCategoryData.map(sp => (
    {
      text: sp.Name,
      value: sp.Id
    }

  ))
  let OneStepGuaranteeOptions = [
    {
      text: "Yes",
      value: "1"
    },
    {
      text: "No",
      value: "2"
    }
  ]

  let projectTypes = [
    { id: 1, value: "Urgent", isChecked: false },
    { id: 2, value: "Competitive", isChecked: false }

  ]


  function SaveRequest() {
    let userEmail = localStorage.getItem('user_email').toLowerCase();
    if (dealDetailsForm.status == '') {
      return (
        <MDBCol col="3" >
          <MDBBtn id="btnSave" onClick={() => onSubmit(1)} >Save Request</MDBBtn>
        </MDBCol>
      )
    }
    else if ((mode.editMode && dealDetailsForm.isEdit) ||
      ((dealDetailsForm.submitterEmail != null && dealDetailsForm.submitterEmail.toLowerCase().indexOf(userEmail) !== -1)
        || (dealDetailsForm.submitterAssistantEmail && dealDetailsForm.submitterAssistantEmail.toLowerCase().indexOf(userEmail) !== -1)
        || (dealDetailsForm.SubmitterMapAssActiveEmail != null && dealDetailsForm.SubmitterMapAssActiveEmail.toLowerCase().indexOf(userEmail) !== -1)
        || (dealDetailsForm.SubmitterMappedActiveEmail != null && dealDetailsForm.SubmitterMappedActiveEmail.toLowerCase().indexOf(userEmail) !== -1)
      )
    ) {
      return (
        <MDBCol col="3" >
          <MDBBtn id="btnSave" onClick={() => onSubmit(1)} >Save Request</MDBBtn>
        </MDBCol>
      )
    } else {
      return (
        <MDBCol col="3" disabled>
          <MDBBtn id="btnSave" onClick={() => onSubmit(1)} >Save Request</MDBBtn>
        </MDBCol>
      )
    }

  }
  function SubmitRequest() {
    if (dealDetailsForm.status == '') {
      return (
        <MDBCol col="3">
          <MDBBtn onClick={() => onSubmit(2)}>Submit Request</MDBBtn>
        </MDBCol>
      )
    }
    else {
      return (
        <MDBCol col="3" disabled={(!dealDetailsForm.isEdit && mode.viewMode) ||
          (mode.editMode && !dealDetailsForm.isEdit)}>
          <MDBBtn onClick={() => onSubmit(2)}>Submit Request</MDBBtn>
        </MDBCol>
      )
    }

  }

  function BAExecView(props) {
    let BAExecChange = (event, value) => {
      props.change(value);

    }

    return (
      <Autocomplete autoHighlight size="small" autoHighlight
        multiple
        className="tags-standard"
        options={props.options}
        getOptionLabel={(option) => option.name}
        defaultValue={props.value}
        onChange={BAExecChange}
        disabled={!dealDetailsForm.projectSelected || mode.viewMode}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="BA Executives"
          />
        )}
      />
    )

  }

  function CreativeExecView(props) {
    return (
      <Autocomplete autoHighlight size="small" id="creativeExec"
        multiple
        className="tags-standard"
        options={props.options}
        getOptionLabel={(option) => option.RecipientName}
        defaultValue={props.value}
        onChange={props.Change}
        disabled={props.disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Creative Executives"
          // error={props.isformValidated ? (props.value && props.value.length > 0 ? false : true) : false}
          />
        )}
      />)

  }
  let DropDownComponentSecond = (props) => {

    if (dealDetailsForm.formPosition == 'Writer'
      || dealDetailsForm.formPosition == 'Writer/Director'
      || dealDetailsForm.formPosition == 'Writer/Producer'
      || dealDetailsForm.formPosition == 'Writer/Producer/Director') {

      // dropDownComponentSecond = 
      return (
        <Autocomplete size="small" autoHighlight
          className="tags-standard" multiple id="writigsteps"
          options={WritingStepsOptions}
          getOptionLabel={(option) => option.text}
          defaultValue={dealDetailsForm.selectedWritingSteps && dealDetailsForm.selectedWritingSteps.length > 0
            ? dealDetailsForm.selectedWritingSteps : []}
          onChange={(event, value) => {
            setDealDetailsForm({
              ...dealDetailsForm,
              selectedWritingSteps: value
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Writing Steps*"
              error={formValidate ? (dealDetailsForm.selectedWritingSteps
                && dealDetailsForm.selectedWritingSteps.length > 0 ? false : true) : false}
            />
          )}
          disabled={props.disabled}
        />)
    }
    else if (dealDetailsForm.formPosition == 'Optional Step Form') {
      return (
        <Autocomplete autoHighlight size="small"
          className="tags-standard" multiple id="optionlSteps"
          //options={dropDownoptions}
          options={dealDetailsForm.optionalSteps && dealDetailsForm.optionalSteps.length > 0 ?
            dealDetailsForm.optionalSteps : []}
          getOptionLabel={(option) => option.text}
          defaultValue={dealDetailsForm.optionalStepsSelected && dealDetailsForm.optionalStepsSelected.length > 0
            ? dealDetailsForm.optionalStepsSelected : []}
          onChange={(event, value) => {
            setDealDetailsForm({
              ...dealDetailsForm,
              optionalStepsSelected: value
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Optional Steps*"
              error={formValidate ?
                (dealDetailsForm.optionalStepsSelected && dealDetailsForm.optionalStepsSelected.length > 0 ? false : true) : false}
            />
          )}
          disabled={props.disabled}
        />)
    }
    // else if (selectedPosition == 'Actor') {
    //   //  dropDownComponentSecond =
    //   return (<Role onChange={handleRoleChange} value={dealDetailsForm.role}
    //     className={formValidate && dealDetailsForm.role == '' ? 'validateTextField' : ''} />)
    // }
    else if (dealDetailsForm.formPosition == 'Rights') {
      // dropDownComponentSecond =
      return (<Autocomplete size="small" autoHighlight
        className="tags-standard" multiple
        options={MaterialsOption}
        getOptionLabel={(option) => option.text}
        defaultValue={dealDetailsForm.selectedMaterial && dealDetailsForm.selectedMaterial.length > 0
          ? dealDetailsForm.selectedMaterial : []}
        onChange={(event, value) => {
          setDealDetailsForm({
            ...dealDetailsForm,
            selectedMaterial: value
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Material"
          />
        )}
        disabled={props.disabled}
      />
      )
    }
    return ('');
  }
  let BrandComponent = (props) => {
    return (
      <MDBCol md="4">
        <Autocomplete size="small"
          className="tags-standard"
          options={brandOptions}
          value={dealDetailsForm.selectedBrand}
          getOptionLabel={(option) => option.text}
          onChange={(event, value) => {
            setDealDetailsForm({
              ...dealDetailsForm,
              selectedBrand: value
            });
          }}
          disabled={!dealDetailsForm.projectSelected || mode.viewMode}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Brand"
            />
          )}
        />
      </MDBCol>
    )
  }
  let ReleaseTypeComponent = (props) => {
    return (
      <MDBCol md="4">
        <Autocomplete size="small" autoHighlight
          className="tags-standard" id="releasetype"
          options={releaseTypeOptions}
          value={dealDetailsForm.selectedReleaseType}
          getOptionLabel={(option) => option.text}
          onChange={(event, value) => {
            setDealDetailsForm({
              ...dealDetailsForm,
              selectedReleaseType: value
            });
          }}
          disabled={!dealDetailsForm.projectSelected || mode.viewMode}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Release Type*"
              error={formValidate ? (dealDetailsForm.selectedReleaseType && dealDetailsForm.selectedReleaseType.value
                ? false : true) : false}
            />
          )}
        />
      </MDBCol>
    )
  }

  let SpecialCategoryComponent = (props) => {
    return (
      <MDBCol md="4">
        <Autocomplete size="small"
          className="tags-standard"
          value={dealDetailsForm.selectedSpecialCategory}
          options={specialCategoryOptions}
          getOptionLabel={(option) => option.text}
          onChange={(event, value) => {
            setDealDetailsForm({
              ...dealDetailsForm,
              selectedSpecialCategory: value
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Special Category"
            />
          )}
          disabled={!dealDetailsForm.projectSelected || mode.viewMode}
        />
      </MDBCol>
    )
  }
  let GenreComponent = (props) => {
    return (
      <MDBCol md="4">
        <Autocomplete autoHighlight size="small"
          multiple
          className="tags-standard"
          value={dealDetailsForm.selectedGenre}
          options={genreOptions}
          onChange={(event, value) => {
            setDealDetailsForm({
              ...dealDetailsForm,
              selectedGenre: value
            });
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Genre"
            />
          )}
          disabled={!dealDetailsForm.projectSelected || mode.viewMode}
        />
      </MDBCol>

    )
  }

  let handleRoleChange = (value) => {
    setDealDetailsForm({
      ...dealDetailsForm,
      role: value
    });
  }
  let handleFeeChange = (value) => {
    setDealDetailsForm({
      ...dealDetailsForm,
      Fee: value
    });

  }
  let chkChange = (event) => {
    if (event.target.id == 'chkUrgent') {
      setDealDetailsForm({
        ...dealDetailsForm,
        IsUrgent: event.target.checked
      });
    } else {
      setDealDetailsForm({
        ...dealDetailsForm,
        IsCompetitive: event.target.checked
      });
    }
  }
  let chkRepChange = (id, value) => {
    if (id == 'chkCC') {
      setRepForm({
        ...RepresentativeForm,
        isCC: value
      });
    } else {
      setRepForm({
        ...RepresentativeForm,
        isUSeForPaymentAddress: value
      });
    }

  }


  let DropDownComponent = (props) => {
    if (dealDetailsForm.formPosition == 'Writer'
      || dealDetailsForm.formPosition == 'Writer/Director'
      || dealDetailsForm.formPosition == 'Writer/Producer'
      || dealDetailsForm.formPosition == 'Writer/Producer/Director') {
      return (
        <Autocomplete size="small" autoHighlight
          className="tags-standard" id="onestepguarentee"
          options={OneStepGuaranteeOptions}
          defaultValue={dealDetailsForm.oneSterpGuarentee
            ? dealDetailsForm.oneSterpGuarentee : []}
          onChange={(event, value) => {
            setDealDetailsForm({
              ...dealDetailsForm,
              oneSterpGuarentee: value
            });
          }}
          getOptionLabel={(option) => option.text}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="One Step Guarantee Only*"
              error={formValidate ? (dealDetailsForm.oneSterpGuarentee && dealDetailsForm.oneSterpGuarentee.value
                ? false : true) : false}
            />
          )}
          disabled={props.disabled}
        />
      )
    }
    else if (dealDetailsForm.formPosition == 'Optional Step Form') {
      return (
        <Autocomplete size="small" autoHighlight
          className="tags-standard" id="lststepwritten"
          options={dealDetailsForm.lastStepWrittenData && dealDetailsForm.lastStepWrittenData.length > 0 ?
            dealDetailsForm.lastStepWrittenData : []}
          getOptionLabel={(option) => option.text}
          defaultValue={dealDetailsForm.selectedLastSteps
            ? dealDetailsForm.selectedLastSteps : []}
          onChange={(event, value) => {

            setDealDetailsForm({
              ...dealDetailsForm,
              selectedLastSteps: value
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Last Step Written*"
              error={formValidate ? (dealDetailsForm.selectedLastSteps
                && dealDetailsForm.selectedLastSteps.text
                && dealDetailsForm.selectedLastSteps.text != '' ? false : true) : false}
            />
          )}
          disabled={props.disabled}
        />
      )
    }
    else if (dealDetailsForm.formPosition == 'Rights') {
      return (<Autocomplete size="small" autoHighlight
        className="tags-standard"
        options={FictionOptions}
        getOptionLabel={(option) => option.text}
        defaultValue={dealDetailsForm.fictionSelected
          ? dealDetailsForm.fictionSelected : []}
        onChange={(event, value) => {
          setDealDetailsForm({
            ...dealDetailsForm,
            fictionSelected: value
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Fiction/Non Fiction"
          />
        )}
        disabled={props.disabled}
      />
      )
    }
    else {
      return null
    }
  }
  const SpinnerPage = () => {
    if (dealDetailsForm.loading) {
      return (
        <>
          <MDBSpinner small />
        </>
      );
    } else {
      return ('')
    }
  }
  const TalentSpinner = () => {
    if (dealDetailsForm.talentLoader) {
      return (
        <>
          <MDBSpinner small />
        </>
      );
    } else {
      return ('')
    }
  }

  let ExistingProducerView = (props) => {
    return (
      <Autocomplete autoHighlight size="small"
        multiple
        className="tags-standard"
        onChange={(event, value) => {
          setDealDetailsForm({
            ...dealDetailsForm,
            selectedExistingProducerData: value,
          });
        }}
        options={dealDetailsForm.ExistingProdData} onKeyPress={expKeypress}
        getOptionLabel={(option) => option.ExistingProducerName}
        defaultValue={props.value}
        disabled={props.disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Existing Producers"
          // error={props.isformValidated ? (props.value && props.value.length > 0 ? false : true) : false}
          />
        )}
      />
    )
  }

  let handleCommentsChange = (value) => {
    setDealDetailsForm({
      ...dealDetailsForm,
      comments: value
    });

  }
  let handleemailNotesChange = (value) => {
    setDealDetailsForm({
      ...dealDetailsForm,
      emailNotes: value
    });

  }
  let handleRoleChnage = (value) => {
    setDealDetailsForm({
      ...dealDetailsForm,
      role: value
    });

  }
  let BAExecutiveChange = (value) => {
    setDealDetailsForm({
      ...dealDetailsForm,
      selectedBAExecsData: value,
    });
    if (dealDetailsForm.formPosition == 'Optional Step Form') {
      let originalArray = distributionGrid.finalDistributor
      const url = Constants.DealerServiceBaseUrl + 'FinalDistribution?divisionId=' + divisionId + '&positionName=' + selectedPosition
      DealerService.fetchData(url)
        .then(response =>
          response.json())
        .then(data => {
          if (data && data.length > 0) {
            let result = data.filter(item => item.Role == "BA Assigner")[0];
            originalArray = originalArray.filter(item => item.Role != "BA Assigner");
            originalArray.push(result);
          }
          setdistributionGrid({
            ...distributionGrid,
            finalDistributor: originalArray,
          });
        })
    }
  }
  let handleChange = (event, v) => {
    if (event && event.key == "Enter") {
      if (!v.ProjectId) {
        let selectedProject = event.target.value;
        toggleProjectConfirmationModal(selectedProject);
      }
    }
    if (v && v.ProjectId) {
      toggleLoading('Fetching Project Details')
      var r = [];
      r = v;
      setdistributionGrid({
        ...distributionGrid,
        finalDistributor: [],
      });
      setDealDetailsForm({
        ...dealDetailsForm,
        loading: true,
        projectSelected: true
      });
      const url = Constants.DealerServiceBaseUrl + 'PreviousDealInfo?projectId='
        + v.ProjectId + '&divisionId=' + divisionId + '&positionName=' + dealDetailsForm.formPosition;
      DealerService.fetchData(url)
        .then(response =>
          response.json())
        .then(data => {

          setDealDetailsForm({
            ...dealDetailsForm,
            position: '',
            selectedBAExecsData: [],
            selectedCreativeExecData: [],
            selectedExistingProducerData: [],
            selectedProjectData: r,
            loading: false,
            projectSelected: true
          });
          if (data && data.length > 0) {
            let selectedCreativeExec = data[0].CreativeExecutives && data[0].CreativeExecutives.length > 0 ?
              data[0].CreativeExecutives.map(creativeExec => (
                {
                  'RecipientName': creativeExec.CreativeExecutiveName,
                  'RecipientId': creativeExec.CreativeExecutiveId,
                  'Division': creativeExec.DivsionGroupRoles[0].DivisionName,
                  'Group': creativeExec.DivsionGroupRoles[0].Groups[0].GroupName,
                  'Role': creativeExec.DivsionGroupRoles[0].Groups[0].Roles[0].RoleName,
                  "Action": creativeExec.IsDefaultApprover ? 'Approver' : 'Copied',
                  'Sequence': 0,
                  "ActionId": creativeExec.IsDefaultApprover ? 1 : 2,
                  "IsDefaultApprover": creativeExec.IsDefaultApprover,
                  "IsActive": creativeExec.IsActive
                })) : []
            distributionGrid.finalDistributor = data[0].FinalDistributions && data[0].FinalDistributions.length > 0 ?
              data[0].FinalDistributions : [];
            setDealDetailsForm({
              ...dealDetailsForm,
              position: v.Name,
              selectedBAExecsData: data[0].BAExecutiveId != null ?
                [{ 'name': data[0].BAExecutiveName, 'value': data[0].BAExecutiveId, 'IsActive': data[0].BAIsActive }] : [],
              selectedCreativeExecData: data[0].CreativeExecutives && data[0].CreativeExecutives.length > 0 ?
                data[0].CreativeExecutives.map(creativeExec => (
                  {
                    'RecipientName': creativeExec.CreativeExecutiveName,
                    'RecipientId': creativeExec.CreativeExecutiveId,
                    'Division': creativeExec.DivsionGroupRoles[0].DivisionName,
                    'Group': creativeExec.DivsionGroupRoles[0].Groups[0].GroupName,
                    'Role': creativeExec.DivsionGroupRoles[0].Groups[0].Roles[0].RoleName,
                    "Action": creativeExec.IsDefaultApprover ? 'Approver' : 'Copied',
                    'Sequence': 0,
                    "ActionId": creativeExec.IsDefaultApprover ? 1 : 2,
                    "IsDefaultApprover": creativeExec.IsDefaultApprover,
                    "IsActive": creativeExec.IsActive,
                  })) : [],
              selectedExistingProducerData: data[0].ExistingProducers && data[0].ExistingProducers.length > 0 ?
                data[0].ExistingProducers.map(ep => (
                  {
                    'ExistingProducerName': ep.ExistingProducerName,
                    'ExistingProducerId': ep.ExistingProducerId,
                    'IsActive': ep.IsActive
                  }
                )) : [],
              selectedProjectData: r, loading: false, projectSelected: true
            }, [constDistributionFill(selectedCreativeExec)]);
          } else {
            setDealDetailsForm({
              ...dealDetailsForm,
              position: v.Name,
              selectedBAExecsData: [],
              selectedCreativeExecData: [],
              selectedExistingProducerData: [],
              selectedProjectData: r, loading: false,
              projectSelected: true
            });
            toggleLoading();
          }
        })
    } else {
      toggleLoading('')
      distributionGrid.finalDistributor = []
      setDealDetailsForm({
        ...dealDetailsForm,
        position: '',
        selectedBAExecsData: [],
        selectedCreativeExecData: [],
        selectedExistingProducerData: [],
        selectedProjectData: ''
        , projectSelected: false
      }, [constDistributionFill()]);
    }
  }

  function RepresentativeAdd(index, t) {

    if (index && index.index == 0) {
      return (<AddBoxIcon style={{ color: blue[900], fontSize: 25 }}
        onClick={() => toggleRepresentative('A', t, index.represntative)} />)
    } else
      return ('')
  }

  function RepresentativeEditDelete(index, represntative) {

    if ((index.represntative && index.represntative[0] && index.represntative[0].TalentRepresentativeId) ||
      (index.represntative && index.represntative[0] && index.represntative[0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId != null)) {
      return (
        <>
          <CreateIcon href='/' style={{ color: blue[900], fontSize: 25 }}
            onClick={() => toggleRepresentative('E', index.t, index.represntative)} />
          <CancelIcon style={{ color: blue[900], fontSize: 25 }}
            onClick={() => toggleDeleteTalentRepresentationModal(represntative, index)}
          ></CancelIcon></>)
    } else { return ('') }
  }

  const toggleDeleteTalentRepresentationModal = (t, index) => {

    if (index) {
      let array = getAnEmptyTalentRep();
      let emptyRow = array[0];
      if (index.represntative[index.index].RepresentativeCompanyModels[0].RepresentativeExtension.IsNewlyAdd
        != true) {
        let obj = []
        if (t && t != '') {
          obj = index.represntative[index.index];
        }
        setModal({
          ...dataCreationModals,
          deleteTalentRepresentationModal: !dataCreationModals.deleteTalentRepresentationModal,
          deleteTalentRepresentationObject: obj && t != '' ? obj : []
        });
      } else {
        if (index.represntative && index.represntative.length > 0) {
          let TalentId = index.represntative[index.index].TalentModel.TalentId;
          let TalentName = index.represntative[index.index].TalentModel.TalentName;
          let totalArray = dealDetailsForm.representativesData;
          if (TalentId > 0) {
            index.represntative.splice(index.index, 1);
            if (index.represntative && index.represntative.length == 0) {
              emptyRow.TalentModel.TalentId = TalentId;
              emptyRow.TalentModel.TalentName = TalentName;
              index.represntative.push(emptyRow);
            }
            for (const [i, value] of totalArray.entries()) {
              if (value[0]) {
                if (value[0].TalentModel.TalentId == TalentId) {
                  totalArray[i] = index.represntative;
                }
              }
            }
            setDealDetailsForm({
              ...dealDetailsForm,
              representativesData: totalArray,
            });
          }
        }
      }
    } else {
      setModal({
        ...dataCreationModals,
        deleteTalentRepresentationModal: !dataCreationModals.deleteTalentRepresentationModal,
      });
    }
  }
  const confirmTalentRepDeletion = () => {
    debugger
    if (dataCreationModals.deleteTalentRepresentationSelection == "1") {
      let obj = [];
      let r = []
      if (dataCreationModals.deleteTalentRepresentationObject) {
        r = dataCreationModals.deleteTalentRepresentationObject;
        obj = {
          "TalentId": r.TalentModel.TalentId,
          "RepresentativeId": r.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId,
          "TalentRepresentativeId": r.TalentRepresentativeId
        }

        DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'DeActiveTalentRepresentativeLink', obj)
          .then(response => response)
          .then(data => {
            //removing array from RepresentativesSection
            var array = dealDetailsForm.representativesData;
            let index = -1;
            debugger
            for (const [i, obj] of array.entries()) {
              let len = obj.length;
              for (const [j, obj1] of obj.entries()) {
                if (isEqual(obj1, r)) {
                  let talentModel = array[i][j].TalentModel;
                  array[i].splice(j, 1)
                  if (array[i] && array[i].length == 0) {

                    let empt = getAnEmptyTalentRep();
                    let emptyRow = empt[0];
                    emptyRow.TalentModel = talentModel
                    array[i].push(emptyRow)
                  }
                  break;
                }
              }
            }
            setDealDetailsForm({
              ...dealDetailsForm,
              representativesData: array,
            });
            toggleDeleteTalentRepresentationModal()
            // if (index !== -1) {
            //   array[0].splice(index, 1)
            //   setDealDetailsForm({
            //     ...dealDetailsForm,
            //     representativesData: array,
            //   });
            //   toggleDeleteTalentRepresentationModal()
            // }
          });
      }
    } else {
      //toggleDeleteTalentRepresentationModal()
      let r = [];
      if (dataCreationModals.deleteTalentRepresentationObject) {
        r = dataCreationModals.deleteTalentRepresentationObject;
        var array = dealDetailsForm.representativesData;
        for (const [i, obj] of array.entries()) {
          let len = obj.length;
          for (const [j, obj1] of obj.entries()) {
            if (isEqual(obj1, r)) {
              let talentModel = array[i][j].TalentModel;
              array[i].splice(j, 1)
              if (array[i] && array[i].length == 0) {

                let empt = getAnEmptyTalentRep();
                let emptyRow = empt[0];
                emptyRow.TalentModel = talentModel
                array[i].push(emptyRow)
              }
              break;
            }
          }
        }
        setDealDetailsForm({
          ...dealDetailsForm,
          representativesData: array,
        });
        toggleDeleteTalentRepresentationModal()

      } else {
        toggleDeleteTalentRepresentationModal()
      }
    }

  }
  const confirmRepCompanyDeletion = () => {
    //Permanently Disassociate
    setcompanyExtChange(true);
    let deAssociateCompanyRep = {
      "RepresentativeId": RepresentativeForm.representative.RepresentativeId,
      "CompanyId": RepresentativeForm.company.CompanyId,
      "RepresentativeCompanyId": RepresentativeForm.company.RepresentativeCompanyId
    }
    if (dataCreationModals.deleteRepresentationCompanySelection == "1") {
      setIsRepresentativeCompanyLinkAsCurrent(true);
      DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'DeActiveRepresentativeCompanyLink', deAssociateCompanyRep)
        .then(response => response)
        .then(data => {
          //setting up the changed company details to form
          setCompanyChangeToForm();
        });
    } else {
      setIsRepresentativeCompanyLinkAsCurrent(false);
      setcompanyExtensionSelection(false);
      setCompanyChangeToForm();
    }
    toggleDeleteCompany();
  }
  let setCompanyChangeToForm = () => {
    if (companyChange) {
      let company = companyChange;
      let rep = RepresentativeForm.representative;
      setRepForm({
        ...RepresentativeForm,
        representative: rep,
        company: company,
        Address1: company && company.Address ? company.Address : '',
        addres2: company && company.Address2 ? company.Address2 : '',
        city: company && company.City ? company.City : '',
        State: company && company.State ? company.State : '',
        Country: company && company.Country ? company.Country : '',
        Zip: company && company.zip ? company.zip : '',
      });
    }
  }

  const toggleDeleteCompany = () => {

    setModal({
      ...dataCreationModals,
      deleteCompanyModle: !dataCreationModals.deleteCompanyModle,
    });
  }
  const onDelChange = (event, value) => {
    setModal({
      ...dataCreationModals,
      deleteTalentRepresentationSelection: event.target.id,
    });
  }
  const onCompanyDelete = (event, value) => {
    setModal({
      ...dataCreationModals,
      deleteRepresentationCompanySelection: event.target.id,
    });
  }

  //Company Extension Changes
  const toggleCompanyExtension = () => {
    if (companyModal) {
      setcompanyModal(!companyModal);
      RepresentationDomChange();
    } else {
      setcompanyModal(!companyModal);
    }

  }
  const onCompanyExtPopValChange = (event) => {
    if (event && event.target.name == "All") {
      setcompanyExtensionSelection(true);
    } else if (event && event.target.name == "One") {
      setcompanyExtensionSelection(false);
    }
  }
  //Company Extension Changes
  //Talent Extension Changes
  const toggleTalentExtension = () => {
    if (talentModal) {
      settalentModal(!talentModal);
      if (isCompanyChange) {
        toggleCompanyExtension();
      } else {
        RepresentationDomChange();
      }
    } else {
      settalentModal(!talentModal);
    }
  }
  const onTalentExtPopValChange = (event) => {
    if (event && event.target.name == "All") {
      settalentExtensionSelection(true);
    } else if (event && event.target.name == "One") {
      settalentExtensionSelection(false);
    }
  }

  //Talent Extension Changes

  function Representatives(props) {
    return dealDetailsForm.representativesData.map((represntative, index) =>
      <div>
        {
          represntative.map((r, index2) =>
            <div>
              <label className="labelHeaders">{index2 == 0 ? r.TalentModel.TalentName : ''}</label>
              {r.RepresentativeCompanyModels.map(t =>
                <MDBRow>
                  <MDBCol col="3" >
                    <MDBInput disabled label="Representative's Name" value={t.RepresentativeExtension.RepresentativeName}></MDBInput>
                  </MDBCol>
                  <MDBCol col="2" >
                    <MDBInput disabled label="Representative's Email" value={t.RepresentativeExtension.Email}></MDBInput>
                  </MDBCol>
                  <MDBCol col="2" >
                    <MDBInput disabled label="Representative's Phone" value={t.RepresentativeExtension.Phone1}></MDBInput>
                  </MDBCol>
                  <MDBCol col="2" >
                    <MDBInput disabled label="Representative's Type" value={t.RepresentativeExtension.RepresentativeTypeName}></MDBInput>
                  </MDBCol>
                  <MDBCol col="2" >
                    <MDBInput disabled label="Company Name" value={t.CompanyExtension.CompanyName}></MDBInput>
                  </MDBCol>
                  <MDBCol col="1" disabled={props.disabled}>
                    <RepresentativeEditDelete index={index2} t={t} represntative={represntative}></RepresentativeEditDelete>
                    <RepresentativeAdd index={index2} t={t} represntative={represntative}></RepresentativeAdd>
                  </MDBCol>
                </MDBRow>
              )}
            </div>
          )
        }
      </div>
    )
  }
  const titleKeyPress = (value) => {
    setSave(false)
    toggleTalentModal(value, 'Talent');
  }


  const expKeypress = (event, value) => {
    setSave(false)
    if (event.charCode == 13) {
      let selectedTtile = event.target.value;
      toggleTalentModal(selectedTtile, 'Existing Producers');
    }
  }
  const projectKeypress = (event, value) => {
    setSave(false)
    if (event.charCode == 13) {
      // let selectedProject = event.target.value;
      let selectedProject = projectNewInput.current.value;
      toggleProjectConfirmationModal(selectedProject);
    }
  }

  const [talentSelected, setTalentSelected] = useState(false);
  const talentChange = (t) => {
    if (t && t.length > 0) {
      setDealDetailsForm({
        ...dealDetailsForm,
        //representativesData: [],
        selectedRepresentatives: t,
        talentLoader: true
      });
      onTalentSelection(t);
      setTalentSelected(true);
    } else {
      setDealDetailsForm({
        ...dealDetailsForm,
        representativesData: [],
        selectedRepresentatives: [],
      });
    }
  }
  const onTalentSelection = (t) => {
    if (dealDetailsForm.selectedRepresentatives
      && dealDetailsForm.selectedRepresentatives.length <= t.length) {
      if (t && t.length > 0) {
        var selectedTalents = dealDetailsForm.representativesData;
        for (const [index, value] of t.entries()) {

          if (value) {
            var objExists = dealDetailsForm.representativesData.filter(obj => {
              return obj[0].TalentModel.TalentId === value.value
            })
            if (objExists.length == 0) {
              toggleLoading('Fetching Representations')
              DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'TalentRepresentativeCompany?talentId=' + value.value)
                .then(response =>
                  response.json())
                .then(data => {
                  if (data && data.length > 0) {
                    selectedTalents.push(data);
                    setDealDetailsForm({
                      ...dealDetailsForm,
                      representativesData: selectedTalents,
                      selectedRepresentatives: t,
                      talentLoader: false
                    });
                  }
                  toggleLoading('')
                })
            } else {
              setDealDetailsForm({
                ...dealDetailsForm,
                selectedRepresentatives: t,
                talentLoader: false
              });
            }
          } else {
            setDealDetailsForm({
              ...dealDetailsForm,
              talentLoader: false,
            });
          }
        }
      }
    } else {
      // The onew which is remove from Talents DropDown
      if (t && t.length > 0) {
        let r = t;
        let removedItem = dealDetailsForm.selectedRepresentatives.filter((el) => {
          return r.indexOf(el) < 0;
        });
        if (removedItem.length > 0) {
          let talentreps = dealDetailsForm.representativesData;
          let pos = 0;
          for (const [index, value] of talentreps.entries()) {
            if (value[0].TalentModel.TalentId == removedItem[0].value) {
              pos = index;
              break;
            }
          }
          talentreps.splice(pos, 1);
          let pos1 = 0;
          let selectedReps = dealDetailsForm.selectedRepresentatives;
          for (const [i, v] of selectedReps.entries()) {
            if (v.value == removedItem[0].value) {
              pos1 = i;
              break;
            }
          }
          selectedReps.splice(pos1, 1);
          setDealDetailsForm({
            ...dealDetailsForm,
            representativesData: talentreps,
            selectedRepresentatives: selectedReps,
            talentLoader: false
          });

        } else {
          setDealDetailsForm({
            ...dealDetailsForm,
            selectedRepresentatives: t,
            talentLoader: false
          });
        }
      } else {
        setDealDetailsForm({
          ...dealDetailsForm,
          representativesData: [],
          selectedRepresentatives: [],
          talentLoader: false
        });
      }
    }
  }
  //Talent Region
  const toggleTalentModal = (selectedTtile, controlName) => {

    setModal({
      ...dataCreationModals,
      TalentCreationModal: !dataCreationModals.TalentCreationModal,
      TalentNameSelected: selectedTtile,
      controlName: controlName ? controlName : ''
    });
  }
  function CreateTalentModalConfirmation() {
    return (
      <MDBModal isOpen={dataCreationModals.TalentCreationModal} size='sm' >
        <MDBModalHeader toggle={() => setModal(!dataCreationModals.TalentCreationModal)}>Create {dataCreationModals.controlName}</MDBModalHeader>
        <MDBModalBody>
          Do you want to Create the New {dataCreationModals.controlName} - <span className="font-weight-bold">{dataCreationModals.TalentNameSelected}?</span>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn size="sm" disabled={save} className="btnCreate" id="btnCreate"
            autoHighlight onClick={() => { createNewTalent(dataCreationModals.controlName) }}>Confirm</MDBBtn>
          <MDBBtn size="sm" onClick={toggleTalentModal}>Cancel</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
  const createNewTalent = (controlName) => {
    setSave(true);
    toggleLoading('Saving...')
    DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'Talent ', {
      "TalentName": dataCreationModals.TalentNameSelected,
      "IsActive": true
    })
      .then(response => response)
      .then(data => {
        DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'Talent')
          .then(response => response.json())
          .then(data => {
            let activeTalents = data.filter(function (el) {
              return el.IsActive
            });
            let newData = activeTalents.map(talents => ({
              'talent': talents.TalentName,
              'value': talents.TalentId,
              'IsActive': talents.IsActive
            }));
            let newExp = activeTalents.map(existingProducer => ({
              'ExistingProducerName': existingProducer.TalentName,
              'ExistingProducerId': existingProducer.TalentId,
              'IsActive': existingProducer.IsActive
            }))
            dealDetailsForm.talentInfo = newData;
            dealDetailsForm.ExistingProdData = newExp;
            var maxid = 0;
            var maxobj;
            data.sort(function (a, b) {
              return a.TalentId - b.TalentId
            });
            data.map(function (obj) {
              if (obj.TalentId > maxid) maxobj = obj;
            });
            if (controlName != 'Existing Producers') {
              var r = dealDetailsForm.selectedRepresentatives;
              if (maxobj) {
                let newTalent = {
                  'talent': maxobj.TalentName,
                  'value': maxobj.TalentId,
                  'IsActive': true
                }
                r.push(newTalent);
                onTalentSelection(r);
              }
              toggleTalentModal();
            } else {
              if (maxobj) {
                let ep = dealDetailsForm.selectedExistingProducerData;
                let newTalent = {
                  'ExistingProducerName': maxobj.TalentName,
                  'ExistingProducerId': maxobj.TalentId,
                  'IsActive': true
                }
                ep.push(newTalent);
                setDealDetailsForm({
                  ...dealDetailsForm,
                  selectedExistingProducerData: ep
                });
              }
              toggleTalentModal();
            }
            setSave(false);
            toggleLoading('')

          })
      });
  }
  const toggleTalentCreationModal = () => {
    setModal({
      ...dataCreationModals,
      createTalentFormModal: !dataCreationModals.createTalentFormModal,
    });
  }

  //End Talent Region

  //Project Region
  const toggleProjectConfirmationModal = (selectedProject) => {
    setModal({
      ...dataCreationModals,
      projectConfirmationModal: !dataCreationModals.projectConfirmationModal,
      ProjectNameSelected: selectedProject ? selectedProject : ''
    });
  }
  function CreateProjectModalConfirmation() {
    return (
      <MDBModal isOpen={dataCreationModals.projectConfirmationModal} toggle={toggleProjectConfirmationModal} size='sm' >
        <MDBModalHeader toggle={() => setModal(!dataCreationModals.projectConfirmationModal)}>Create Project</MDBModalHeader>
        <MDBModalBody>
          {/* Do you want to Create the New Project - 
          <span className="font-weight-bold">{dataCreationModals.ProjectNameSelected} ? </span> */}
          <MDBContainer>
            <MDBRow>
              <MDBCol col="6">
                <TextField label="Project Name" id="projectName" value={dataCreationModals.ProjectNameSelected}></TextField>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol col="6">
                <TextField label="Alias" id="alias" inputRef={projectAliasInput} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>

        </MDBModalBody>
        <MDBModalFooter>
          {/* <MDBBtn size="sm" onClick={createNewProject}>Confirm</MDBBtn> */}
          <MDBBtn size="sm" disabled={save} onClick={saveProject}>Confirm</MDBBtn>
          <MDBBtn size="sm" onClick={toggleProjectConfirmationModal}>Cancel</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
  const createNewProject = () => {
    toggleProjectCreationModal();
  }
  const toggleProjectCreationModal = () => {

    setModal({
      ...dataCreationModals,
      createProjectFormModal: !dataCreationModals.createProjectFormModal,
    });
  }

  function CreateProjectModal() {
    return (
      <MDBModal isOpen={dataCreationModals.createProjectFormModal} toggle={toggleProjectCreationModal} size='sm' >
        <MDBModalHeader toggle={() => setModal(!dataCreationModals.createProjectFormModal)}>Create Project</MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            <MDBRow>
              <MDBCol col="4">
                <TextField label="Project Name" id="txtProjectTitle" value={dataCreationModals.ProjectNameSelected}></TextField>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol col="4">
                <TextField label="Alias" inputRef={projectAliasInput} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn size="sm" onClick={saveProject}>Create</MDBBtn>
          <MDBBtn size="sm" onClick={toggleProjectCreationModal}>Cancel</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
  const saveProject = () => {
    let postBody = {
      "Id": 0,
      "Name": dataCreationModals.ProjectNameSelected.toUpperCase(),
      "Alias": projectAliasInput.current.value,
    }
    toggleLoading('Saving...')
    setSave(true);
    DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'Project', postBody)
      .then(response => response)
      .then(data => {
        toggleProjectConfirmationModal()
        DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'Project')
          .then(response => response.json())
          .then(data => {
            if (data && data.length > 0) {
              dealDetailsForm.projectTitleData = data;
              var maxid = 0;
              var maxobj;
              // data.map(function (obj) {
              //   if (obj.ProjectId > maxid) maxobj = obj;
              // });
              data.slice(0);
              data.sort(function (a, b) {
                return a.ProjectId - b.ProjectId;
              });
              var arrayLength = data && data.length > 0 ? data.length : 0;
              maxobj = data[arrayLength - 1];
              var r = dealDetailsForm.selectedProjectData = [];
              if (maxobj) {
                let newProject = {
                  'ProjectId': maxobj.ProjectId,
                  'Name': maxobj.Name,
                  'Alias': maxobj.Alias,
                }
                r = newProject;
                setDealDetailsForm({
                  ...dealDetailsForm,
                  selectedProjectData: r
                });
              }
            }
            toggleLoading();
            handleChange(null, r);
            setModal({
              ...dataCreationModals,
              createProjectFormModal: false,
              projectConfirmationModal: false,
            });
            setSave(true);
          })
      });
  }
  const toggleRepresentative = (formType, selectedRep, talentRep) => {

    if (formType == 'C') {
      setRepCreation({
        ...representativeCreation,
        addRepresentativeModal: !representativeCreation.addRepresentativeModal,
      });
    } else {
      settalentRepresentation(talentRep)
      if (formType == 'A') {
        setRepForm({
          ...RepresentativeForm,
          Mode: 'A'
        });
        onRepresentativeChange(null, null, 'A', null);
      } else if (formType == 'E' && selectedRep) {
        // let r = dealDetailsForm.repsData.find((element) => {
        //   return element.RepresentativeId === selectedRep.RepresentativeExtension.RepresentativeId;
        //   //  return element.RepresentativeId === selectedRep.CompanyExtension.CompanyId
        // })
        if (selectedRep.RepresentativeExtension) {
          onRepresentativeChange(null, selectedRep.RepresentativeExtension, 'E', selectedRep.CompanyExtension);
        } else {
          setRepForm({
            ...RepresentativeForm,
            Mode: 'E'
          });
        }
      }
      setRepCreation({
        ...representativeCreation,
        addRepresentativeModal: !representativeCreation.addRepresentativeModal,
        isCreate: formType == 'A' ? true : false
      });
    }

  }

  //End Project Region



  //Distributors
  //Distributors Section
  const data = {
    columns: [
      {
        label: 'Recipient Name',
        field: 'RecipientName',
        width: 200,
      },
      {
        label: 'Action',
        field: 'Action',
        width: 150
      },
      {
        label: 'Sequence',
        field: 'Sequence',
        width: 150
      },
      {
        label: 'Department',
        field: 'Group',
        width: 270
      },
      {
        label: 'Role',
        field: 'Role',
        width: 200
      },
      {
        label: '',
        field: 'fields',
        width: 200
      }
    ],
    rows: distributionGrid.finalDistributor.map((a, index) => {
      return {
        RecipientName: a.RecipientName,
        Group: a.Group,
        Sequence: <SequenceText data={a.Sequence}></SequenceText>,
        Role: a.Role,
        Division: a.Division,
        Action: a.Action,
        fields: <div disabled={!dealDetailsForm.projectSelected || mode.viewMode}>
          <DistributorIcons prop={a}></DistributorIcons >
          <DistributionAddIcon prop={a} index={index}></DistributionAddIcon>
        </div>
      }
    })
  }
  function SequenceText(props) {
    return (<span >{props.data == 0 ? "" : props.data}</span>)
  }
  let DistributorActions = [{ name: "Approver", value: 1 }, { name: "Copied", value: 2 }]
  const creativeExecChange = (event, r) => {
    toggleLoading()
    constDistributionFill(r);
  }
  const constDistributionFill = (r) => {
    if (!r) {
      toggleLoading('')
      return;
    }
    let selectedData = distributionGrid.finalDistributor;
    let nextsequence = 0;
    var maxSequence = 0;

    //new code
    let finalApproverRecord = []
    for (const [i, v] of selectedData.entries()) {
      if (v.Role == 'Final Approver') {
        finalApproverRecord.push(v);
        selectedData.splice(i, 1);
      }
    }
    //new code
    if (selectedData && selectedData.length > 0) {
      maxSequence = Math.max.apply(Math, selectedData
        .map(function (o) { return o.Sequence; }))
    } else {
      maxSequence = 0;
    }
    nextsequence = maxSequence + 1;
    let a = 1;
    //Have to handle if selected or deselcted
    if (dealDetailsForm.selectedCreativeExecData && dealDetailsForm.selectedCreativeExecData.length <= r.length) {
      //selected scenario
      if (r && r.length > 0) {
        //When Creative Exec already selected
        if (dealDetailsForm.selectedCreativeExecData && dealDetailsForm.selectedCreativeExecData.length > 0) {
          //if Selected item already exists do not add it to Routing
          for (const [index, value] of r.entries()) {
            var objExists = dealDetailsForm.selectedCreativeExecData.filter(obj => {
              return obj.RecipientId === value.RecipientId
            })
            if (objExists && objExists.length == 0) {
              if (value.Role != 'Final Approver' && value.RecipientId != finalApproverRecord[0].RecipientId) {
                value.Sequence = value.Action == "Approver" ? nextsequence : 0;
                if (value.Action == "Approver")
                  nextsequence++;
                selectedData.push(value);
              }
            }
          }
        } //When Creative Exec None selected
        else {
          debugger;
          for (const [index, value] of r.entries()) {
            if (value.Role != 'Final Approver' && value.RecipientId != finalApproverRecord[0].RecipientId) {
              value.Sequence = value.Action == "Approver" ? nextsequence : 0;
              if (value.Action == "Approver")
                nextsequence++;
              selectedData.push(value);
            }
          }
        }
        //new code
        //appending final approver again back with new sequence
        if (finalApproverRecord && finalApproverRecord.length > 0) {
          if (selectedData && selectedData.length > 0) {
            maxSequence = Math.max.apply(Math, selectedData
              .map(function (o) { return o.Sequence; }))
          } else {
            maxSequence = 0;
          }
          nextsequence = maxSequence + 1;
          finalApproverRecord[0].Sequence = nextsequence;
          selectedData.push(finalApproverRecord[0])
        }
        //new code
      } else {
        let dupFinalApprove = []
        for (const [i, v] of selectedData.entries()) {
          if (v.Role == 'Final Approver') {
            dupFinalApprove.push(v);
          }
        }
        if (dupFinalApprove && dupFinalApprove.length == 0 && finalApproverRecord.length > 0) {
          if (selectedData && selectedData.length > 0) {
            maxSequence = Math.max.apply(Math, selectedData
              .map(function (o) { return o.Sequence; }))
          } else {
            maxSequence = 0;
          }
          nextsequence = maxSequence + 1;
          finalApproverRecord[0].Sequence = nextsequence;
          selectedData.push(finalApproverRecord[0])
        }
      }
    } else {
      debugger;
      if (r && r.length >= 0) {
        let a = r;
        let removedItem = dealDetailsForm.selectedCreativeExecData.filter((el) => {
          return a.indexOf(el) < 0;
        });
        if (removedItem.length > 0) {
          //let talentreps = selectedData;
          let position = 0;
          for (const [i, val] of selectedData.entries()) {
            if (val.RecipientName == removedItem[0].RecipientName) {
              selectedData.splice(i, 1);
              break;
            }
          }
        }
        //new code
        //appending final approver again back with new sequence
        if (finalApproverRecord && finalApproverRecord.length > 0) {
          if (selectedData && selectedData.length > 0) {
            maxSequence = Math.max.apply(Math, selectedData
              .map(function (o) { return o.Sequence; }))
          } else {
            maxSequence = 0;
          }
          nextsequence = maxSequence + 1;
          finalApproverRecord[0].Sequence = nextsequence;
          selectedData.push(finalApproverRecord[0])
        }
        //new code
      } else {

      }
    }
    var sortBySequence = selectedData.slice(0);
    sortBySequence.sort(function (a, b) {
      return a.Sequence - b.Sequence;
    });
    setDealDetailsForm({
      ...dealDetailsForm,
      //distributors: selectedData,
      selectedCreativeExecData: r
    });
    setdistributionGrid({
      ...distributionGrid,
      finalDistributor: sortBySequence,
    });
    toggleLoading();
  }
  function DistributionAddIcon(props) {
    if (props && props.index == 0) {
      return (<AddBoxIcon style={{ color: blue[900], fontSize: 25 }}
        onClick={() => toggleRecipientModal('A')} />)
    } else
      return ('')
  }
  function DistributorIcons(props) {

    // if (props.prop.IsDefaultApprover != true) {
    if (props.prop.Role != 'Final Approver' && props.prop.Role != 'BA Assigner') {
      return (
        <>
          <DeleteOutlinedIcon style={{ color: blue[900], fontSize: 25 }}
            onClick={() => DeleteRecipient(props)}></DeleteOutlinedIcon>
          <CreateIcon style={{ color: blue[900], fontSize: 25 }}
            onClick={() => editRecipient(props)} ></CreateIcon>
        </>
      );
    } else return ('');
  }

  const toggleRecipientModal = (formType) => {
    let order = [];
    let maxSequence = Math.max.apply(Math, distributionGrid.finalDistributor
      .map(function (o) { return o.Sequence; }))
    //let sequencelengthRequired = formType == 'A' ? maxSequence + 1 : maxSequence;
    let sequencelengthRequired = maxSequence + 3;
    let newsequenceOptions = [];
    for (var i = 0; i < sequencelengthRequired; i++) {
      let option = { name: (i + 1).toString(), value: i + 1 }
      newsequenceOptions.push(option);
    }
    if (formType == 'A') {
      addEditRecipient.slectedRecipient = []
      setaddEditRecipient({
        ...addEditRecipient,
        selectedSequence: [],
        selectedAction: [],
        slectedRecipient: [],
      });
    }
    setdistributionAddModal({
      ...distributionAddModal,
      isOpen: !distributionAddModal.isOpen,
      mode: formType,
      sequenceOptions: newsequenceOptions
    });
  }
  const toggleCommentsModal = () => {
    setAddComments({
      ...addComments,
      isOpen: !addComments.isOpen
    });
  }
  const saveRecipient = () => {

    let originalArray = distributionGrid.finalDistributor;
    let selectedSequence = 0;
    let maxSequence = 0;


    if (addEditRecipient.slectedRecipient == null || addEditRecipient.slectedRecipient.length == 0) {
      alert('Please select Mandatory Fields');
      return false;
    }
    if (addEditRecipient.selectedAction == null || addEditRecipient.selectedAction.length == 0) {
      alert('Please select Mandatory Fields');
      return false;
    }
    if (addEditRecipient.selectedAction != null && addEditRecipient.selectedAction.length != 0 && addEditRecipient.selectedAction.name != "Copied") {
      if (addEditRecipient.selectedSequence != null && addEditRecipient.selectedSequence.length == 0) {
        alert('Please select Mandatory Fields');
        return false;
      }
    }
    if (addEditRecipient.selectedAction.name != "Copied") {
      selectedSequence = addEditRecipient.selectedSequence.value;
    }

    //check if selected user already in use if its not zero
    if (distributionAddModal.mode == 'A') {
      for (const [index, value] of originalArray.entries()) {
        if (value.RecipientName == addEditRecipient.slectedRecipient.RecipientName &&
          value.RecipientId == addEditRecipient.slectedRecipient.RecipientId) {
          alert('Recipient already added to Routing');
          return false;
        }
      }
    }
    //check if selected sequence already in use if its not zero
    if (selectedSequence != 0) {
      for (const [index, value] of originalArray.entries()) {
        if (value.Role !== 'Final Approver') {
          if (value.Sequence === selectedSequence) {
            if (distributionAddModal.mode == 'A') {
              alert('Sequence already in use');
              return false;
            } else if (distributionAddModal.mode == 'E') {
              //return false;
            }
          }
        }
      }
    }
    let r = [];
    if (distributionAddModal.mode == 'A') {
      if (addEditRecipient.slectedRecipient) {
        r = addEditRecipient.slectedRecipient;
        r.Action = addEditRecipient.selectedAction.name;
        r.ActionId = addEditRecipient.selectedAction.name == 'Approver' ? 1 : 2;
        r.Sequence = selectedSequence;
        //removing final approver and adding after new reocrd and incremented sequence
        //new code
        let finalApproverRecord = []
        for (const [i, v] of originalArray.entries()) {
          if (v.Role == 'Final Approver') {
            finalApproverRecord.push(v);
            originalArray.splice(i, 1);
          }
        }
        //new code
        //adding new Record
        originalArray.push(r);
        //re adding final approver with new sequnece
        //new code
        //appending final approver again back with new sequence
        if (finalApproverRecord && finalApproverRecord.length > 0) {
          if (originalArray && originalArray.length > 0) {
            maxSequence = Math.max.apply(Math, originalArray
              .map(function (o) { return o.Sequence; }))
          } else {
            maxSequence = 0;
          }
          let nextsequence = maxSequence + 1;
          finalApproverRecord[0].Sequence = nextsequence;
          originalArray.push(finalApproverRecord[0])
        }
        //new code

        var sortBySequence = originalArray.slice(0);
        sortBySequence.sort(function (a, b) {
          return a.Sequence - b.Sequence;
        });
        setdistributionGrid({
          ...distributionGrid,
          finalDistributor: sortBySequence,
        });
      }
    }
    if (distributionAddModal.mode == 'E') {
      if (addEditRecipient.slectedRecipient) {
        for (const [i, entry] of originalArray.entries()) {
          if (entry.RecipientId === addEditRecipient.slectedRecipient.RecipientId &&
            entry.Sequence === addEditRecipient.slectedRecipient.Sequence) {
            entry.Sequence = selectedSequence;
            entry.Action = addEditRecipient.selectedAction.name;
            entry.ActionId = addEditRecipient.selectedAction.name == 'Approver' ? 1 : 2;
          }
        }
        //new code
        let finalApproverRecord = []
        for (const [i, v] of originalArray.entries()) {
          if (v.Role == 'Final Approver') {
            finalApproverRecord.push(v);
            originalArray.splice(i, 1);
          }
        }
        //new code
        //new code
        //appending final approver again back with new sequence
        let maxSequence = 0;
        if (finalApproverRecord && finalApproverRecord.length > 0) {
          if (originalArray && originalArray.length > 0) {
            maxSequence = Math.max.apply(Math, originalArray
              .map(function (o) { return o.Sequence; }))
          } else {
            maxSequence = 0;
          }
          let nextsequence = maxSequence + 1;
          finalApproverRecord[0].Sequence = nextsequence;
          originalArray.push(finalApproverRecord[0])
        }
        //new code

        var sortBySequence = originalArray.slice(0);
        sortBySequence.sort(function (a, b) {
          return a.Sequence - b.Sequence;
        });
        setdistributionGrid({
          ...distributionGrid,
          finalDistributor: sortBySequence,
        });
      }
    }
    setdistributionAddModal({
      ...distributionAddModal,
      isOpen: !distributionAddModal.isOpen,
    });

  }
  const DeleteRecipient = (prop) => {

    let originalArray = distributionGrid.finalDistributor;
    //splice final approver if have first
    //new code
    let finalApproverRecord = []
    for (const [i, v] of originalArray.entries()) {
      if (v.Role == 'Final Approver') {
        finalApproverRecord.push(v);
        originalArray.splice(i, 1);
      }
    }
    //new code
    //remove as per selectio
    for (const [index, value] of originalArray.entries()) {
      if (value.RecipientId === prop.prop.RecipientId && value.Sequence == prop.prop.Sequence) {
        originalArray.splice(index, 1);
        break;
      }
    }

    //new code
    //appending final approver again back with new sequence
    let maxSequence = 0;
    if (finalApproverRecord && finalApproverRecord.length > 0) {
      if (originalArray && originalArray.length > 0) {
        maxSequence = Math.max.apply(Math, originalArray
          .map(function (o) { return o.Sequence; }))
      } else {
        maxSequence = 0;
      }
      let nextsequence = maxSequence + 1;
      finalApproverRecord[0].Sequence = nextsequence;
      originalArray.push(finalApproverRecord[0])
    }
    //new code
    setdistributionGrid({
      ...distributionGrid,
      finalDistributor: originalArray,
    });

  }
  const editRecipient = (prop) => {
    let selectedAction = []
    let selectedSequence = []
    if (prop) {
      DistributorActions.map(function (obj) {
        if (obj.name == prop.prop.Action) {
          selectedAction = obj;
        }
      });
      distributionAddModal.sequenceOptions.map(function (obj) {
        if (obj.value == prop.prop.Sequence) {
          selectedSequence = obj
        }
      });

      setaddEditRecipient({
        ...addEditRecipient,
        slectedRecipient: prop.prop,
        selectedAction: selectedAction,
        selectedSequence: selectedSequence
      })
      toggleRecipientModal('E')
    }
  }


  let RecipientChange = (event, value) => {
    debugger
    addEditRecipient.slectedRecipient = value;
    // if (value) {
    //   let selectedAction = []
    //   selectedAction = value.IsDefaultApprover ? DistributorActions[0] : DistributorActions[1];
    //   setaddEditRecipient({
    //     ...addEditRecipient,
    //     selectedAction: selectedAction,
    //   })
    // }
  }
  let Actionchange = (event, value) => {
    setaddEditRecipient({
      ...addEditRecipient,
      selectedAction: value,
    });
  }
  let sequenceChange = (event, value) => {
    addEditRecipient.selectedSequence = value
  }

  //Distributors

  //Final Buttons Section
  const onSubmit = (submitType) => {
    setSubmissionType(submitType)
    setFormValidate(true)
    settempValidate(true)
  }
  const onCancel = () => {
    history.push('/dashboard');
  }

  React.useEffect(() => {
    if (tempValidate) {
      submit();
    }
  }, [tempValidate]);


  //operationType 1 is Draft 2 is Submit
  const submit = (operationType) => {

    if (document.getElementsByClassName("Mui-error").length == 0 && document.getElementsByClassName("validateTextField").length == 0) {
      let projectDetails = dealDetailsForm;
      //make final approver as max sequence
      let finalDistributors = distributionGrid.finalDistributor;
      let maxSequence = Math.max.apply(Math, distributionGrid.finalDistributor
        .map(function (o) { return o.Sequence; })) + 1;
      debugger;
      maxSequence = Math.max.apply(Math, distributionGrid.finalDistributor
        .map(function (o) { return o.Sequence; }));

      let minSequence = Math.min.apply(Math, distributionGrid.finalDistributor
        .map(function (o) { return o.Sequence; }));
      if (maxSequence == 0) {
        setFormValidate(false)
        settempValidate(false)
        alert('Please add atleast one Approver');
        return false;
      }
      var recipientArray = finalDistributors.map(function (item) {
        return item.RecipientName
      });
      var isDuplicateRecipients = recipientArray.some(function (item, idx) {
        return recipientArray.indexOf(item) != idx
      });
      if (isDuplicateRecipients) {
        setFormValidate(false)
        settempValidate(false)
        alert('Duplicate Recipients exists in Routing')
        return false
      }
      var isInactiveUsersExists = finalDistributors.filter(function (el) {
        return !el.IsActive
      })
      if (isInactiveUsersExists && isInactiveUsersExists.length > 0) {
        setFormValidate(false)
        settempValidate(false)
        alert('Some of the users in Routing section are Inactive. Please Validate')
        return false
      }


      var isInactiveTalentExists = dealDetailsForm.selectedRepresentatives.filter(function (el) {
        return !el.IsActive
      })
      if (isInactiveTalentExists && isInactiveTalentExists.length > 0) {
        setFormValidate(false)
        settempValidate(false)
        alert('Some of the selected Talents are Inactive. Please Validate')
        return false
      }

      var isInactiveExistingProducers = dealDetailsForm.selectedExistingProducerData.filter(function (el) {
        return !el.IsActive
      })
      if (isInactiveExistingProducers && isInactiveExistingProducers.length > 0) {
        setFormValidate(false)
        settempValidate(false)
        alert('Some of the selected Existing Producers are Inactive. Please Validate')
        return false
      }

      var isInactiveCreativeExecs = dealDetailsForm.selectedCreativeExecData.filter(function (el) {
        return !el.IsActive
      })
      if (isInactiveCreativeExecs && isInactiveCreativeExecs.length > 0) {
        setFormValidate(false)
        settempValidate(false)
        alert('Some of the selected Creative Executives are Inactive. Please Validate')
        return false
      }
      if (dealDetailsForm.selectedBAExecsData && dealDetailsForm.selectedBAExecsData.length > 0) {
        if (!dealDetailsForm.selectedBAExecsData[0].IsActive) {
          setFormValidate(false)
          settempValidate(false)
          alert('Selected BA Executive is Inactive. Please Validate')
          return false
        }
      }

      //checking if sequence is valid
      //Create an array from min to max
      let seqArray = []
      for (var i = minSequence; i <= maxSequence; i++) {
        seqArray.push(i);
      }

      let finalSeqArray = []
      for (const [k, val] of finalDistributors.entries()) {
        finalSeqArray.push(val.Sequence);
      }

      if (seqArray.every(r => finalSeqArray.includes(r))) {
        console.log('Found all of', seqArray, 'in', finalSeqArray);
      } else {
        setFormValidate(false)
        settempValidate(false)
        alert('Please adjust Routing Sequence')
        console.log('Did not find all of', seqArray, 'in', finalSeqArray);
        return false
      }


      //check for duplicates sequence
      var valueArr = finalDistributors.map(function (item) {
        if (item.Sequence != 0 && item.Sequence != null)
          return item.Sequence;
        else return item.RecipientName
      });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx
      });
      if (isDuplicate) {
        setFormValidate(false)
        settempValidate(false)
        alert('Please adjust Routing Sequence')
        return false
      }

      maxSequence = Math.max.apply(Math, distributionGrid.finalDistributor
        .map(function (o) { return o.Sequence; })) + 1;
      for (const [index, value] of finalDistributors.entries()) {
        if (value.Role === 'Final Approver') {
          if (value.Sequence == 0) //Updating the max sequence only when there is no sequence for final Approver
            value.Sequence = maxSequence
        }
      }

      let taletRepresentationArray = [];

      for (const [i, obj] of dealDetailsForm.representativesData.entries()) {
        let repcompanies = [];
        for (const [j, obj1] of obj.entries()) {
          repcompanies.push(obj1.RepresentativeCompanyModels[0])
        }
        let r = {
          "TalentRepresentativeId": obj[0].TalentRepresentativeId,
          "IsTalentRepresntativeLinkActive": obj[0].IsTalentRepresntativeLinkActive,
          "TalentModel": obj[0].TalentModel,
          "RepresentativeCompanyModels": repcompanies
        }
        taletRepresentationArray.push(r);
      }

      if (submissionType == 2) {
        for (const [index, val] of taletRepresentationArray.entries()) {

          if (val && val.TalentRepresentativeId == null) {
            setFormValidate(false)
            settempValidate(false)
            alert('Please provide valid entries in Representation Section');
            return false;
            break;
          } else {
            for (const [j, val1] of val.RepresentativeCompanyModels.entries()) {
              if (val1 && val1.RepresentativeCompanyId == null) {
                setFormValidate(false)
                settempValidate(false)
                alert('Invalid Data in Representation Section');
                return false;
                break;
              }
            }
          }
        }
      }

      let finalArray = {
        "DealId": dealId && dealId != '' ? Number(dealId) : 0,
        "PositionId": positionId,
        "PositionName": "",
        "DivisionId": divisionId,
        "DivisionName": "",
        "ProjectId": dealDetailsForm.selectedProjectData ? dealDetailsForm.selectedProjectData.ProjectId : null,
        "ProjectName": dealDetailsForm.selectedProjectData ? dealDetailsForm.selectedProjectData.Name : '',
        "TalentRepresentativeModels": taletRepresentationArray,// dealDetailsForm.representativesData,
        "CreativeExecutives": dealDetailsForm.selectedCreativeExecData
          && dealDetailsForm.selectedCreativeExecData.length > 0 ?
          dealDetailsForm.selectedCreativeExecData.map(creativeExec => ({
            'CreativeExecutiveName': creativeExec.RecipientName,
            'CreativeExecutiveId': creativeExec.RecipientId,
            'DivisionName': creativeExec.Division,
            'GroupName': creativeExec.Group,
            'RoleName': creativeExec.Role,
            "ActionId": creativeExec.IsDefaultApprover ? 1 : 2,
            "IsDefaultApprover": creativeExec.IsDefaultApprover,
            "IsActive": creativeExec.IsActive,
          })) : [],
        "BAExecutiveId": dealDetailsForm.selectedBAExecsData && dealDetailsForm.selectedBAExecsData.length > 0 ?
          dealDetailsForm.selectedBAExecsData[0].value : '',
        "BAExecutiveName": dealDetailsForm.selectedBAExecsData && dealDetailsForm.selectedBAExecsData.length > 0 ?
          dealDetailsForm.selectedBAExecsData[0].name : '',
        "ExistingProducers": dealDetailsForm.selectedExistingProducerData,
        "BrandId": dealDetailsForm.selectedBrand ? dealDetailsForm.selectedBrand.value : 0,
        "BrandName": dealDetailsForm.selectedBrand ? dealDetailsForm.selectedBrand.text : 0,
        "ReleaseTypeId": dealDetailsForm.selectedReleaseType ? dealDetailsForm.selectedReleaseType.value : 0,
        "ReleaseTypeName": dealDetailsForm.selectedReleaseType ? dealDetailsForm.selectedReleaseType.text : 0,
        "Role": dealDetailsForm.role,
        "Fee": dealDetailsForm.Fee ? Number(dealDetailsForm.Fee) : 0,
        "SpecialCategoryId": dealDetailsForm.selectedSpecialCategory ?
          dealDetailsForm.selectedSpecialCategory.value : null,
        "SpecialCategoryName": dealDetailsForm.selectedSpecialCategory ?
          dealDetailsForm.selectedSpecialCategory.text : "",
        "OneStepGuarenteeOnly": dealDetailsForm.oneSterpGuarentee ?
          (dealDetailsForm.oneSterpGuarentee.value == "2" ? false : true) : false,
        "WritingSteps": dealDetailsForm.selectedWritingSteps && dealDetailsForm.selectedWritingSteps.length > 0 ?
          dealDetailsForm.selectedWritingSteps.map(step => ({
            'WritingStepsId': step.value,
            'WritingStepsName': step.text,
          })) : [],
        "Genres": dealDetailsForm.selectedGenre && dealDetailsForm.selectedGenre.length > 0 ?
          dealDetailsForm.selectedGenre.map(step => ({
            'GenreId': step.value,
            'GenreName': step.name,
          })) : [],
        "Materials": dealDetailsForm.selectedMaterial && dealDetailsForm.selectedMaterial.length > 0 ?
          dealDetailsForm.selectedMaterial.map(step => ({
            'MaterialId': step.value,
            'MaterialName': step.text,
          })) : [],
        "IsCompetitive": dealDetailsForm.IsCompetitive,
        "IsUrgent": dealDetailsForm.IsUrgent,
        "FictionId": dealDetailsForm.fictionSelected ? dealDetailsForm.fictionSelected.value : null,
        "FictionName": dealDetailsForm.fictionSelected ? dealDetailsForm.fictionSelected.text : '',
        "LastStepWrittenId": dealDetailsForm.selectedLastSteps && dealDetailsForm.selectedLastSteps.value ? dealDetailsForm.selectedLastSteps.value : null,
        "LastStepWrittenName": dealDetailsForm.selectedLastSteps && dealDetailsForm.selectedLastSteps.text ? dealDetailsForm.selectedLastSteps.text : '',

        "OptionalSteps": dealDetailsForm.optionalStepsSelected && dealDetailsForm.optionalStepsSelected.length > 0 ?
          dealDetailsForm.optionalStepsSelected.map(option => ({
            'OptionalStepId': option.value,
            'OptionalStepName': option.text,
          })) : [],
        "CreatedDate": "2019-09-21T18:02:09",
        "CreatedBy": localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 2,
        "StatusId": submissionType,//Draft 2 //Submitted
        "Status": submissionType == 1 ? 'Draft' : 'Submit',
        "Comments": dealDetailsForm.comments,
        "EmailNote": dealDetailsForm.emailNotes,
        "DistributionModels": finalDistributors
      }
      if (finalArray) {
        let successMessage = submissionType == 1 ? "Deal Saved Successfully" : "Deal Submitted Successfully";
        let errorMessage = submissionType == 1 ? "Deal Save Failed" : "Deal Submit Failed";
        toggleLoading(submissionType == 1 ? 'Saving..' : 'Submitting..')
        DealerService.postData(Constants.DealerServiceBaseUrl + 'Deal ', finalArray)
          .then(res => {
            // do good things
            if (res.data > 0) {
              settempValidate(false);
              setDealId(res.data)
              toggleLoading('')
              notifications('info', successMessage, 1000, submissionType, res.data)
              setDealDetailsForm(dealDetailsForm => {
                return {
                  ...dealDetailsForm,
                  dealId: res.data
                }
              })

            }
            else {
              toggleLoading('')
              settempValidate(false)
              notifications('error', errorMessage, 3000)
            }
          })
          .catch(err => {
            toggleLoading('')
            notifications('error', errorMessage, 3000)
          });
      }

      let r = 1;
    } else {
      settempValidate(false);
      if (document.getElementsByClassName("Mui-error").length > 0) {
        document.getElementsByClassName("Mui-error")[0].focus();
      } else if (document.getElementsByClassName("validateTextField").length > 0) {
        document.getElementsByClassName("validateTextField")[0].focus();
      }

    }

    //toggleLoading('');

  }
  const notifications = (status, message, timeOut, submissionType = 0, dealId = 0) => {
    switch (status) {
      case 'info':
        NotificationManager.info(message, 'Deal', timeOut);
        if (submissionType == 2) {
          setTimeout(() => {
            history.push('/dashboard')
          }, timeOut);
        } else if (submissionType == 1 && dealId != 0) {
          setTimeout(() => {
            history.push('/dealrequestform?dealId=' + dealId);
            window.location.reload(false);
          }, 100);
        } else {
          NotificationManager.info(message, 'Deal', timeOut);
        }
        break;
      case 'success':
        NotificationManager.success(message, 'Deal', timeOut);
        settempValidate(false)
        break;
      case 'warning':
        NotificationManager.warning(message, 'Deal', timeOut);
        break;
      case 'error':
        NotificationManager.error(message, 'Deal', timeOut, () => {
        });
        settempValidate(false)
        break;
    }
  }
  const onSave = () => {

  }
  //Final Buttons Section
  //OtherComments
  // const saveOtherComments = (newComments) =>{
  //   console.log('saveOtherComments...',newComments);
  //   setAddComments({
  //     ...addComments,
  //       isOpen:false,
  //       comments:newComments
  //   })
  //   //toggleCommentsModal();
  // } 

  //Representative
  const submitHandler = (event, value) => {
    if (!(RepresentativeForm.representative
      && RepresentativeForm.representative.RepresentativeName)) {
      event.preventDefault();
      alert('Please select Representative  before submission');
      return false;
    } else if (!(RepresentativeForm.company
      && RepresentativeForm.company.CompanyName)) {
      event.preventDefault();
      alert('Please select Company  before submission');
      return false;
    }
    else if (!(RepresentativeForm.representativeType
      && RepresentativeForm.representativeType.Id > 0)) {
      event.preventDefault();
      alert('Please select Representative Type  before submission');
    }
    // else if (RepresentativeForm.email == '') {
    //   event.preventDefault();
    //   alert('Please enter email  before submission');
    //   return false;
    // }
    // else if (RepresentativeForm.phoneNumber == '') {
    //   event.preventDefault();
    //   alert('Please enter Phone1 details  before submission');
    //   return false;
    // }
    else {
      event.preventDefault();
      let newItem = [];
      let repChanged = true;
      let companyChanged = true;
      setisCompanyChange(true);
      setisTalentChange(true);
      if (RepresentativeForm.Mode == 'A' || RepresentativeForm.Mode == 'E') {
        let selectedRep = dealDetailsForm.repsData.find((element) => {
          return element.RepresentativeId === RepresentativeForm.representative.RepresentativeId
        })
        if (selectedRep &&
          (RepresentativeForm.email == "" || selectedRep.Email == RepresentativeForm.email)
          && (RepresentativeForm.phoneNumber == "" || selectedRep.Phone1 == RepresentativeForm.phoneNumber)
          && (RepresentativeForm.fax == "" || selectedRep.Fax == RepresentativeForm.fax)
          && (RepresentativeForm.phoneNumber2 == "" || selectedRep.Phone2 == RepresentativeForm.phoneNumber2)
          && selectedRep.RepresentativeTypeId == RepresentativeForm.representativeType.Id
          && selectedRep.CC == RepresentativeForm.isCC
          && selectedRep.UseForPaymentAddress == RepresentativeForm.isUSeForPaymentAddress) {
          repChanged = false;
        } else {
          repChanged = true;
          RepresentativeForm.representative.Email = RepresentativeForm.email;
          RepresentativeForm.representative.Fax = RepresentativeForm.fax;
          RepresentativeForm.representative.Phone1 =
            RepresentativeForm.phoneNumber ? RepresentativeForm.phoneNumber : null;
          RepresentativeForm.representative.Phone2 = RepresentativeForm.phoneNumber2
            ? RepresentativeForm.phoneNumber2 : null;
          RepresentativeForm.representative.RepresentativeTypeId = RepresentativeForm.representativeType.Id
          RepresentativeForm.representative.RepresentativeTypeName = RepresentativeForm.representativeType.Name
          RepresentativeForm.representative.CC = RepresentativeForm.isCC;
          RepresentativeForm.representative.UseForPaymentAddress = RepresentativeForm.isUSeForPaymentAddress;
        }

        let selectedcompany = dealDetailsForm.companiesData.find((element) => {
          return element.CompanyId === RepresentativeForm.company.CompanyId;
        })
        if (selectedcompany
          && (RepresentativeForm.Address1 == "" || selectedcompany.Address == RepresentativeForm.Address1)
          && (RepresentativeForm.addres2 == "" || selectedcompany.Address2 == RepresentativeForm.addres2)
          && (RepresentativeForm.city == "" || selectedcompany.City == RepresentativeForm.city)
          && (RepresentativeForm.State == "" || selectedcompany.State == RepresentativeForm.State)
          && (RepresentativeForm.Country == "" || selectedcompany.Country == RepresentativeForm.Country)
          && (RepresentativeForm.Zip == "" || selectedcompany.zip == RepresentativeForm.Zip)) {
          companyChanged = false;
        } else {
          companyChanged = true;
          RepresentativeForm.company.Address = RepresentativeForm.Address1;
          RepresentativeForm.company.Address2 = RepresentativeForm.addres2;
          RepresentativeForm.company.City = RepresentativeForm.city;
          RepresentativeForm.company.State = RepresentativeForm.State;
          RepresentativeForm.company.Country = RepresentativeForm.Country;
          RepresentativeForm.company.zip = RepresentativeForm.Zip;
        }
        if (RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId == 0) {
          repChanged = false;
        }
        if (RepresentativeForm.company && RepresentativeForm.company.CompanyId == 0) {
          companyChanged = false;
        }

        let selectedRepType = [];
        if (RepresentativeForm.representativeType && RepresentativeForm.representativeType.Id != 0) {
          selectedRepType = dealDetailsForm.representativeTypesData.find((element) => {
            return element.Id === RepresentativeForm.representativeType.Id
          })
        } else {
          selectedRepType = dealDetailsForm.representativeTypesData[0];
        }
        RepresentativeForm.representative.RepresentativeTypeId = selectedRepType.Id;
        RepresentativeForm.representative.RepresentativeTypeName = selectedRepType.Name;
        RepresentativeForm.company.IsExtensionChanged =
          RepresentativeForm.company && RepresentativeForm.company.CompanyId == 0 ?
            true : companyChanged;
        if (RepresentativeForm.company && RepresentativeForm.company.CompanyExtensionId == null) {
          companyChanged = false;
          RepresentativeForm.company.IsExtensionChanged = true;
        }
        RepresentativeForm.representative.IsExtensionChanged =
          RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId == 0 ?
            true : repChanged;
        //RepresentativeForm.represntative.CompanyExtensionId = RepresentativeForm.company.CompanyExtensionId
        if (RepresentativeForm.Mode == 'A') {
          RepresentativeForm.representative.IsNewlyAdd = true;
        }
      }
      if (value && value != "") {
        let obj = {
          "TalentRepresentativeId": value[0].TalentRepresentativeId,
          "IsTalentRepresntativeLinkActive": value[0].IsTalentRepresntativeLinkActive,
          "TalentModel": {
            "TalentId": value[0].TalentModel.TalentId,
            "TalentName": value[0].TalentModel.TalentName
          },
          "RepresentativeCompanyModels": [{
            "RepresentativeCompanyId": RepresentativeForm.company.RepresentativeCompanyId ? RepresentativeForm.company.RepresentativeCompanyId : 0,
            //"RepresentativeCompanyId": null,
            "IsRepresentativeCompanyLinkAsCurrent": true,
            "CompanyExtension":
              RepresentativeForm.company,
            "RepresentativeExtension": RepresentativeForm.representative,
          }]
        }

        if (IsRepresentativeCompanyLinkAsCurrent && IsRepresentativeCompanyLinkAsCurrent == true) {
          obj.RepresentativeCompanyModels[0].IsRepresentativeCompanyLinkAsCurrent = true;
        } else {
          if (RepresentativeForm.representative.RepresentativeExtensionId == 0) {
            obj.RepresentativeCompanyModels[0].IsRepresentativeCompanyLinkAsCurrent = true;
          } else {
            obj.RepresentativeCompanyModels[0].IsRepresentativeCompanyLinkAsCurrent = IsRepresentativeCompanyLinkAsCurrent;
          }

        }

        newItem.push(obj);

      } else {
        //When Company Changes check
      }
      //set whole item in state

      //Submission Logic
      if (repChanged || companyChanged) {
        setnewItem(newItem, [setvalue(value)]);
        setisTalentChange(repChanged);
        setisCompanyChange(companyChanged);
        setrepCompanyDomChange(newItem, [checkRepAndCompanyChanges(repChanged, companyChanged)]);
      }//If nothing is changes
      else {
        //Disable Save Button 
        setDealDetailsForm({
          ...dealDetailsForm,
          repCompanyLoader: true
        })
        let a = dealDetailsForm.representativesData;
        newItem[0].RepresentativeCompanyModels[0].CompanyExtension.IsLastUsed
          = RepresentativeForm.company && RepresentativeForm.company.CompanyId == 0
            ? true : companyExtensionSelection;
        newItem[0].RepresentativeCompanyModels[0].RepresentativeExtension.IsLastUsed
          = RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId == 0 ?
            true : talentExtensionSelection;
        for (const [index, v] of a.entries()) {
          if (v[0].TalentModel.TalentId === value[0].TalentModel.TalentId) {
            if (RepresentativeForm.Mode == 'A') {
              if (a[index][0].TalentRepresentativeId == null &&
                a[index][0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId == null) {
                a[index][0] = newItem[0];
              }
              else {
                a[index].push(newItem[0]);
              }
            }
            else if (RepresentativeForm.Mode == 'E') {
              for (const [j, va] of v.entries()) {
                if (va.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId
                  == newItem[0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId) {
                  a[index][j] = newItem[0];
                }
              }
            }
            saveRepCompany(newItem[0], a);
            break;
          }
        }
      }
      // Append This new addition to existing Section
    }
  }
  const checkRepAndCompanyChanges = (repChanged, companyChanged) => {
    if (repChanged) {
      toggleTalentExtension()
    } else if (companyChanged) {
      toggleCompanyExtension();
    }
  }

  const RepresentationDomChange = () => {
    let newItem = addItem;
    let value = existingITem;
    let a = dealDetailsForm.representativesData;
    newItem[0].RepresentativeCompanyModels[0].CompanyExtension.IsLastUsed = companyExtensionSelection;
    newItem[0].RepresentativeCompanyModels[0].RepresentativeExtension.IsLastUsed = talentExtensionSelection;
    for (const [index, v] of a.entries()) {
      if (v[0].TalentModel.TalentId === value[0].TalentModel.TalentId) {
        if (RepresentativeForm.Mode == 'A') {
          if (a[index][0].TalentRepresentativeId == null &&
            a[index][0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId == null) {
            a[index][0] = newItem[0];
          }
          else {
            a[index].push(newItem[0]);

          }
        } else if (RepresentativeForm.Mode == 'E') {
          for (const [j, va] of v.entries()) {
            if (va.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId
              == newItem[0].RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId) {
              a[index][j] = newItem[0];
            }
          }
        }
        saveRepCompany(newItem[0], a);
        break;
      }
    }
  }
  const saveRepCompany = (newItem, a) => {
    if (newItem) {
      toggleLoading('Saving...')
      DealerService.postData(Constants.DealerSharedServiceBaseUrl +
        'RepresentativeCompany', newItem)
        .then(res1 => {
          // do good things
          if (res1.data) {
            //check if new rep of company
            // if (newItem.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId == 0 ||
            //  newItem.RepresentativeCompanyModels[0].CompanyExtension.CompanyId) {
            //GET REP AND Company Details
            //res1.data[0]
            if (newItem.TalentRepresentativeId == null) {
              newItem.TalentRepresentativeId = res1.data[0].TalentRepresentativeId
            }
            let RepsData = [];
            let CompaniesData = [];
            let newRep = [];
            let newCompany = [];
            DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'Representative')
              .then(response => response.json())
              .then(data => {
                RepsData = data
                dealDetailsForm.repsData = RepsData;
                setDealDetailsForm(dealDetailsForm => {
                  return {
                    ...dealDetailsForm,
                    repsData: RepsData
                  }
                });

                // setDealDetailsForm({
                //   ...dealDetailsForm,
                //   repsData: RepsData
                // })
                RepsData.map(function (obj) {
                  if (obj.RepresentativeName == newItem.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeName) {
                    if (talentExtensionSelection) {
                      newItem.RepresentativeCompanyModels[0].RepresentativeExtension = obj;
                    }
                    if (RepresentativeForm.Mode == 'A') {
                      newItem.RepresentativeCompanyModels[0].RepresentativeExtension.IsNewlyAdd = true;
                    }
                  }
                });
                // if (!talentExtensionSelection) {
                //append representation coming from post data
                if (res1.data && res1.data.length > 0) {
                  newItem.RepresentativeCompanyModels[0].RepresentativeExtension =
                    res1.data[0].RepresentativeCompanyModels[0].RepresentativeExtension;
                }
                //}
                DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'Company')
                  .then(response => response.json())
                  .then(res => {
                    CompaniesData = res;
                    // setDealDetailsForm({
                    //   ...dealDetailsForm,
                    //   companiesData: CompaniesData,
                    //   repsData: RepsData
                    // })
                    dealDetailsForm.companiesData = CompaniesData
                    setDealDetailsForm(dealDetailsForm => {
                      return {
                        ...dealDetailsForm,
                        companiesData: CompaniesData,
                        repsData: RepsData
                      }
                    });
                    CompaniesData.map(function (obj1) {
                      if (obj1.CompanyName == newItem.RepresentativeCompanyModels[0]
                        .CompanyExtension.CompanyName) {
                        newItem.RepresentativeCompanyModels[0].CompanyExtension = obj1
                      }
                    });
                    // if (!companyExtensionSelection) {
                    //append representation coming from post data
                    if (res1.data && res1.data.length > 0) {
                      newItem.RepresentativeCompanyModels[0].CompanyExtension =
                        res1.data[0].RepresentativeCompanyModels[0].CompanyExtension;
                    }
                    // newItem.RepresentativeCompanyModels[0].CompanyExtension = obj;
                    // }
                    for (const [index, v] of a.entries()) {
                      if (v[0].TalentModel.TalentId === newItem.TalentModel.TalentId) {
                        for (const [j, va] of v.entries()) {
                          // if (va.RepresentativeCompanyModels[0].RepresentativeExtension.RepresentativeId
                          //  == 0 || va.RepresentativeCompanyModels[0].CompanyExtension.CompanyId == 0) {
                          va = newItem;
                          // }
                        }
                      }
                    }
                    settalentExtensionSelection(true);
                    setcompanyExtensionSelection(true);
                    setDealDetailsForm({
                      ...dealDetailsForm,
                      representativesData: a,
                      repCompanyLoader
                        : false
                    }, [toggleRepresentative('C', null)])
                  });
              });
            // }
            // else {
            //   setDealDetailsForm({
            //     ...dealDetailsForm,
            //     representativesData: a
            //   }, [toggleRepresentative('C', null)])
            // }

          }
          else {
            setDealDetailsForm({
              ...dealDetailsForm,
              repCompanyLoader: true
            })
            toggleRepresentative('C', null)
          }
          toggleLoading('');
        })
        .catch(err => {
          notifications('error', 'Something went wrong', 3000)
          setDealDetailsForm({
            ...dealDetailsForm,
            repCompanyLoader: false
          })
          toggleRepresentative('C', null)
          toggleLoading('');
        });

    }
  }
  const updateRepAndCompanyLookups = () => {
    DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Representative')
      .then(response => {
        if (response.data) {
          setDealDetailsForm({
            ...dealDetailsForm,
            repsData: data
          })
        }
      })
      .catch(err => {
        notifications('error', 'Something went wrong', 3000)
        toggleRepresentative('C', null)
      });
    DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Company')
      .then(res => {
        if (res.data) {
          setDealDetailsForm({
            ...dealDetailsForm,
            CompaniesData: res.data
          })
        }
      })
      .catch(err => {
        notifications('error', 'Something went wrong', 3000)
        toggleRepresentative('C', null)
      });
  }

  const changeHandler = event => {

  };
  const onRepresentativeChange = (event, value, formType, companyExt) => {

    setcompanyExtChange(false);
    var selectedType = RepresentativeForm.representativeType = [];
    var selectedRep = RepresentativeForm.representative = [];
    var selectedCompnay = RepresentativeForm.company = [];
    if (value) {
      let r = []
      if (value.RepresentativeTypeId && value.RepresentativeTypeId > 0) {
        r = dealDetailsForm.representativeTypesData.find((element) => {
          return element.Id === value.RepresentativeTypeId;
        })
      } else {

      }
      let company = []
      if (companyExt) {
        company = companyExt;
      } else {
        if (value && value.CompanyId) {
          company = dealDetailsForm.companiesData.find((element) => {
            return element.CompanyId === value.CompanyId;
          })
        }
      }
      selectedCompnay = company ? company : []
      selectedRep = value;
      selectedType = r;
      if (value) {
        setRepForm({
          ...RepresentativeForm,
          isRepSelected: true,
          representativeType: selectedType,
          representative: selectedRep,
          company: selectedCompnay,
          email: value.Email && value.Email != "null" ? value.Email : '',
          phoneNumber: value.Phone1 && value.Phone1 != "null" ? value.Phone1 : '',
          phoneNumber2: value.Phone2 && value.Phone2 != "null" ? value.Phone2 : '',
          fax: value.Fax && value.Fax != "null" ? value.Fax : '',
          Address1: company && company.Address ? company.Address : '',
          addres2: company && company.Address2 ? company.Address2 : '',
          city: company && company.City ? company.City : '',
          State: company && company.State ? company.State : '',
          Country: company && company.Country ? company.Country : '',
          Zip: company && company.zip ? company.zip : '',
          isUSeForPaymentAddress: value.UseForPaymentAddress ? value.UseForPaymentAddress : false,
          isCC: value.CC ? value.CC : false,
          Mode: formType
        }, [setSelection()]);
      }
    } else {
      setRepForm({
        ...RepresentativeForm,
        isRepSelected: false,
        Mode: 'A'
      });
    }
  }

  const setSelection = () => {

  }
  const onRepresentativeTypeChange = (event, value) => {
    setRepForm({
      ...RepresentativeForm,
      representativeType: value
    });
  }

  const onFormDataChange = (event, value) => {
    if (event.target.name == 'phoneNumber' || event.target.name == 'phoneNumber2') {
      let isChar = isNumber(event.target.value.charCodeAt(0));
      if (isChar) {
        setRepForm({
          ...RepresentativeForm,
          [event.target.name]: event.target.value
        });
      }
    } else {
      setRepForm({
        ...RepresentativeForm,
        [event.target.name]: event.target.value
      });
    }
  }
  const isNumber = (charCode) => {
    //evt = (evt) ? evt : window.event;
    // var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  let onPDFGen = () => {
    if (dealDetailsForm.dealId) {
      DealerService.getData(Constants.DealerServiceBaseUrl + 'Deal' + '?dealId=' + dealDetailsForm.dealId)
        .then(response => {
          let responseData = response.data[0];
          DealPDFDocGen(responseData);
        });
    }
    else {
      alert("please save the deal before previewing");
    }
  }
  const onCompanyChange = (event, value) => {

    setCompanyChange(value);
    if (RepresentativeForm.Mode == 'A') {
      //No Change in 
      if (value) {
        if (isEqual(RepresentativeForm.company, value) || value.CompanyId == 0) {
          if (value) {
            var company = value;
            setRepForm({
              ...RepresentativeForm,
              company: company,
              Address1: company && company.Address ? company.Address : '',
              addres2: company && company.Address2 ? company.Address2 : '',
              city: company && company.City ? company.City : '',
              State: company && company.State ? company.State : '',
              Country: company && company.Country ? company.Country : '',
              Zip: company && company.zip ? company.zip : '',
            });
          }
        } //Change in Company
        else {
          if (RepresentativeForm.company.CompanyId &&
            RepresentativeForm.company.CompanyId > 0) {

            if (RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId > 0) {
              toggleDeleteCompany();
            }
          }
          if (value) {
            var company = value;
            setRepForm({
              ...RepresentativeForm,
              company: company,
              Address1: company ? company.Address : '',
              addres2: company ? company.Address2 : '',
              city: company ? company.City : '',
              State: company ? company.State : '',
              Country: company ? company.Country : '',
              Zip: company ? company.zip : '',
            });
          }
        }
      } else {

        if (RepresentativeForm.representative && RepresentativeForm.representative.RepresentativeId > 0) {
          toggleDeleteCompany();
        }
        setRepForm({
          ...RepresentativeForm,
          company: [],
          Address1: '',
          addres2: '',
          city: '',
          State: '',
          Country: '',
          Zip: '',
        });
      }
    } else if (RepresentativeForm.Mode == 'E') {
      if (!value) {
        setRepForm({
          ...RepresentativeForm,
          company: [],
          Address1: '',
          addres2: '',
          city: '',
          State: '',
          Country: '',
          Zip: '',
        });
      }
    }
    if (!representativeCreation.isCreate) {
      if (!companyExtChange) {

        toggleDeleteCompany();
      }
      else {
        if (value) {
          let company = value;
          setRepForm({
            ...RepresentativeForm,
            company: company,
            Address1: company && company.Address ? company.Address : '',
            addres2: company && company.Address2 ? company.Address2 : '',
            city: company && company.City ? company.City : '',
            State: company && company.State ? company.State : '',
            Country: company && company.Country ? company.Country : '',
            Zip: company && company.zip ? company.zip : '',
          });
        }
      }
    }
  }

  const spinner = () => {
    if (dealDetailsForm.loading) {
      return (
        <MDBSpinner small />
      )
    }
    else
      return ('')
  }
  const getAnEmptyTalentRep = () => {
    return [
      {
        "TalentRepresentativeId": null,
        "IsTalentRepresntativeLinkActive": false,
        "TalentModel": {
          "TalentId": 0,
          "TalentName": ""
        },
        "RepresentativeCompanyModels": [
          {
            "CompanyExtension": {
              "CompanyExtensionId": null,
              "CompanyId": null,
              "CompanyName": null,
              "Address": null,
              "Address2": null,
              "City": null,
              "State": null,
              "Country": null,
              "zip": null,
              "IsLastUsed": true,
              "IsExtensionChanged": false,
              "RepresentativeCompanyId": null
            },
            "RepresentativeExtension": {
              "RepresentativeExtensionId": null,
              "RepresentativeId": null,
              "RepresentativeName": null,
              "Phone1": null,
              "Phone2": null,
              "Email": null,
              "RepresentativeTypeId": null,
              "RepresentativeTypeName": null,
              "Fax": null,
              "UseForPaymentAddress": false,
              "CC": false,
              "IsLastUsed": true,
              "CompanyExtensionId": null,
              "IsExtensionChanged": false
            },
            "RepresentativeCompanyId": null,
            "IsRepresentativeCompanyLinkAsCurrent": true
          }
        ]
      }
    ]
  }

  //End Representative
  let RoleField = (dealDetailsForm.formPosition == 'Actor' ||
    dealDetailsForm.formPosition == 'Actor/Director'
    || dealDetailsForm.formPosition == 'Actor/Producer') ?
    <Role disabled={!dealDetailsForm.projectSelected || mode.viewMode} onChange={handleRoleChange}
      value={dealDetailsForm.role} key="role1"
      className={formValidate && dealDetailsForm.role == '' ? 'validateTextField' : ''} />
    : '';

  let InputFees = (dealDetailsForm.formPosition == 'Optional Step Form') ?
    <MDBCol md="4" >
      <Fee onChange={handleFeeChange} value={dealDetailsForm.Fee} disabled={!dealDetailsForm.projectSelected || mode.viewMode}
        className={formValidate && dealDetailsForm.Fee == '' ? 'validateTextField' : ''} />
    </MDBCol>
    : '';

  return (
    <MDBContainer fluid>
      <MDBRow>
        {/* <DealCreationForm /> */}
        <MDBContainer className="sectionContainer">
          <MDBRow className="sectionBgColor" >
            <MDBCol >
              <label>PROJECT DETAILS</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div disabled={mode.viewMode} class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input" id="chkUrgent"
                  checked={dealDetailsForm.IsUrgent} onChange={chkChange} />
                <label class="custom-control-label" for="chkUrgent">Urgent</label>
              </div>
              <div disabled={mode.viewMode} class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input" id="chkCompetitive"
                  checked={dealDetailsForm.IsCompetitive} onChange={chkChange} />
                <label class="custom-control-label" for="chkCompetitive">Competitive</label>
              </div>
              &nbsp;&nbsp;
            <label className="redColor">{dealDetailsForm.formPosition == 'Optional Step Form' ? 'OPTIONAL STEP FORM' : ''}</label>

            </MDBCol>
            <MDBCol>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4">
              <DealCreationProjectTitle
                dataOptions={dealDetailsForm.projectTitleData && dealDetailsForm.projectTitleData.length > 0 ? dealDetailsForm.projectTitleData : []}
                value={dealDetailsForm.selectedProjectData ? dealDetailsForm.selectedProjectData : {}}
                Change={handleChange} isformValidated={formValidate}
                inputRef={projectNewInput}
                // disabled={mode.viewMode || mode.editMode ? true : false}
                //disabled={!mode.editMode}
                disabled={dealDetailsForm.dealId > 0 ? true : false}
              />
            </MDBCol>
            <MDBCol md="4">
              <TalentViews options={dealDetailsForm.talentInfo && dealDetailsForm.talentInfo.length > 0 ?
                dealDetailsForm.talentInfo : []}
                value={dealDetailsForm.selectedRepresentatives.length > 0 ? dealDetailsForm.selectedRepresentatives : []}
                change={talentChange} ManageTalent={false}
                keyPress={titleKeyPress} isformValidated={formValidate}
                disabled={!dealDetailsForm.projectSelected || mode.viewMode}
              />
            </MDBCol>
            <MDBCol md="4">
              <CreativeExecView
                options={CreativeExecs && CreativeExecs.length > 0 ?
                  CreativeExecs : []}
                value={dealDetailsForm.selectedCreativeExecData.length > 0 ? dealDetailsForm.selectedCreativeExecData : []}
                Change={creativeExecChange} isformValidated={formValidate}
                disabled={!dealDetailsForm.projectSelected || mode.viewMode}
              />
              {/* <SpinnerPage></SpinnerPage> */}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4" >
              <TextField
                id="date"
                label="Create Date" value={dealDetailsForm.createdDate && dealDetailsForm.createdDate != ''
                  ? dealDetailsForm.createdDate : today}// value={today}
                disabled
              />
            </MDBCol>
            <MDBCol md="4">
              <MDBSelect
                options={positionOptions}
                selected='choose an option'
                label="Position*"
                disabled
              />
            </MDBCol>
            <MDBCol md="4">
              <ExistingProducerView value={dealDetailsForm.selectedExistingProducerData.length > 0 ?
                dealDetailsForm.selectedExistingProducerData : []}
                isformValidated={formValidate}
                disabled={!dealDetailsForm.projectSelected || mode.viewMode} />

            </MDBCol>
          </MDBRow>
          <MDBRow>
            <BrandComponent></BrandComponent>
            <MDBCol md="4">
              <DropDownComponentSecond disabled={!dealDetailsForm.projectSelected || mode.viewMode}></DropDownComponentSecond>
              {RoleField}
            </MDBCol>
            <MDBCol md="4">
              <BAExecView options={BAExecs}
                value={dealDetailsForm.selectedBAExecsData.length > 0 ?
                  dealDetailsForm.selectedBAExecsData : []}
                change={BAExecutiveChange} />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <SpecialCategoryComponent></SpecialCategoryComponent>
            <MDBCol md="4">
              <DropDownComponent disabled={!dealDetailsForm.projectSelected || mode.viewMode}></DropDownComponent>
            </MDBCol>
            {/* <MDBCol md="4">
              <Autocomplete autoHighlight size="small"
                multiple
                className="tags-standard"
                value={dealDetailsForm.selectedGenre}
                options={genreOptions}
                onChange={(event, value) => {
                  setDealDetailsForm({
                    ...dealDetailsForm,
                    selectedGenre: value
                  });
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Genre"
                  />
                )}
              />
            </MDBCol> */}
          </MDBRow>
          <MDBRow>
            <GenreComponent></GenreComponent>
            {InputFees}
          </MDBRow>
          <MDBRow>
            <ReleaseTypeComponent></ReleaseTypeComponent>
          </MDBRow>
          <MDBRow>
            {dealDetailsForm.projectSelected}
          </MDBRow>
          <MDBRow>
            <DealCreationComments projectnotSelected={!dealDetailsForm.projectSelected}
              disabled={!(mode.editMode || (dealDetailsForm.submitterEmail.toLowerCase().indexOf(localStorage.getItem('user_email').toLowerCase()) !== -1
                || dealDetailsForm.submitterAssistantEmail && dealDetailsForm.submitterAssistantEmail.toLowerCase().indexOf(localStorage.getItem('user_email').toLowerCase()) !== -1
                || (dealDetailsForm.SubmitterMapAssActiveEmail != null && dealDetailsForm.SubmitterMapAssActiveEmail.toLowerCase().indexOf(localStorage.getItem('user_email').toLowerCase()) !== -1)
                || (dealDetailsForm.SubmitterMappedActiveEmail != null && dealDetailsForm.SubmitterMappedActiveEmail.toLowerCase().indexOf(localStorage.getItem('user_email').toLowerCase()) !== -1)
              ))}
              onChange={handleCommentsChange} value={dealDetailsForm.comments} />
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="sectionContainer">
          <MDBRow>
            <MDBCol className="sectionBgColor">
              <label >REPRESENTATION</label>
              <br></br>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol >
              <TalentSpinner></TalentSpinner>
            </MDBCol>
          </MDBRow>
          <MDBRow >
            <MDBCol>
              {/* {!talentSelected &&
               <span className="custom-control-label-override">Talents are not selected in project details</span>} */}
              {dealDetailsForm.selectedRepresentatives && dealDetailsForm.selectedRepresentatives.length == 0 ?
                <span className="custom-control-label-override">Please select the talent(s) before accessing this section</span>
                : ''}
            </MDBCol>
          </MDBRow>
          <MDBRow >
            <MDBCol>
              <Representatives disabled={!dealDetailsForm.projectSelected || mode.viewMode}></Representatives>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="sectionContainer" >
          <RoutingDataGrid gridData={data} disabled={!dealDetailsForm.projectSelected || mode.viewMode} />
          <MDBRow >
            <DealCreationEmail disabled={!dealDetailsForm.projectSelected || mode.viewMode}
              onChange={handleemailNotesChange} value={dealDetailsForm.emailNotes} />

          </MDBRow>
        </MDBContainer>
        <MDBContainer id="btn" >
          <MDBRow>
            <MDBCol col="3">
              <MDBBtn id="btnCancel" onClick={() => onCancel()} >Cancel</MDBBtn>
            </MDBCol>
            <SaveRequest></SaveRequest>
            <MDBCol col="3" disabled={dealDetailsForm.dealId > 0 ? false : true}>
              <MDBBtn onClick={() => onPDFGen()}>Preview Request</MDBBtn>
            </MDBCol>
            <SubmitRequest></SubmitRequest>
          </MDBRow>
        </MDBContainer>
      </MDBRow >
      <MDBRow>
        <CreateTalentModalConfirmation></CreateTalentModalConfirmation>
      </MDBRow>
      <MDBRow>
        <CreateProjectModalConfirmation></CreateProjectModalConfirmation>
        <CreateProjectModal></CreateProjectModal>
        <RepresentativeModal isOpen={representativeCreation.addRepresentativeModal}
          representativeCreation={representativeCreation}
          RepresentativeData={dealDetailsForm.repsData && dealDetailsForm.repsData.length > 0 ?
            dealDetailsForm.repsData : []}
          isCreate={!representativeCreation.isCreate}
          onRepresentativeChange={onRepresentativeChange}
          onFormDataChange={onFormDataChange}
          RepresentativeForm={RepresentativeForm} isDisabled={dealDetailsForm.repCompanyLoader}
          RepresentativeTypesData={dealDetailsForm.representativeTypesData}
          onRepresentativeTypeChange={onRepresentativeTypeChange} chkRepChange={chkRepChange}
          onCompanyChange={onCompanyChange}
          submitHandler={submitHandler}
          CompaniesData={dealDetailsForm.companiesData && dealDetailsForm.companiesData.length > 0 ?
            dealDetailsForm.companiesData : []}
          toggleRepresentative={toggleRepresentative}
          selectedRepresentative={selectedRepresentative} talentRepresentation={talentRepresentation}
        ></RepresentativeModal>
        <AddEditRecipient isOpen={distributionAddModal.isOpen} AddReps={AddReps}
          mode={distributionAddModal.mode} recipientValue={addEditRecipient.slectedRecipient
            ? addEditRecipient.slectedRecipient : []} repChange={RecipientChange}
          actionOptions={DistributorActions} actionValue={addEditRecipient.selectedAction ?
            addEditRecipient.selectedAction : []} actionChange={Actionchange}
          seqOptions={distributionAddModal.sequenceOptions &&
            distributionAddModal.sequenceOptions.length > 0
            ? distributionAddModal.sequenceOptions : []}
          seqDisabled={addEditRecipient.selectedAction
            && addEditRecipient.selectedAction.value == 2 ? true : false}
          seqValue={addEditRecipient.selectedSequence ?
            addEditRecipient.selectedSequence : []} sequenceChange={sequenceChange}
          saveRecipient={saveRecipient} toggle={() => toggleRecipientModal('A')}></AddEditRecipient>
        <DisassociateCompany isOpen={dataCreationModals.deleteCompanyModle}
          toggleDeleteCompany={toggleDeleteCompany}
          onCompanyDelete={onCompanyDelete} confirmRepCompanyDeletion={confirmRepCompanyDeletion}
          checkedProp={dataCreationModals.deleteRepresentationCompanySelection}></DisassociateCompany>
        <DisassociateTalent
          isOpen={dataCreationModals.deleteTalentRepresentationModal}
          onDelChange={onDelChange}
          checkedProp={dataCreationModals.deleteTalentRepresentationSelection}
          confirmTalentRepDeletion={confirmTalentRepDeletion}
          toggleDeleteTalentRepresentationModal={toggleDeleteTalentRepresentationModal}></DisassociateTalent>
        <NotificationContainer />
        <CompanyExtensionChanged isOpen={companyModal}
          onChange={onCompanyExtPopValChange} confirmation={toggleCompanyExtension}
          selection={companyExtensionSelection}></CompanyExtensionChanged>
        <TalentExtensionChanged isOpen={talentModal}
          onChange={onTalentExtPopValChange} confirmation={toggleTalentExtension}
          selection={talentExtensionSelection}></TalentExtensionChanged>
        {/* <AddOtherCommentsModal isOpen={addComments.isOpen} toggleCommentsModal={toggleCommentsModal} saveOtherComments={saveOtherComments}></AddOtherCommentsModal> */}
      </MDBRow>
      <MDBRow>
      </MDBRow>
      <PageSpinner pageSpinner={loading.pageSpinner} message={loading.message}></PageSpinner>
    </MDBContainer >
  );
}
export default MoreDealInfo;
//Test Commit to QA