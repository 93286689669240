import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { MDBContainer, MDBCol, MDBRow, MDBDataTableV5, MDBModal, MDBTableHead, MDBIcon, MDBBtn, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import '../styles/DealMemoLandingPage.css';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import datetime from 'node-datetime';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateIcon from '@material-ui/icons/Create';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { blue } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DealerService from '../services/service';
import * as Constants from '../constants/constants';
import { PDFViewer } from '@react-pdf/renderer';
import { DealPDFDocGen } from './Modal/DealPDFDocViewer';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment'
import PageSpinner from './PageSpinner';
import { useHistory, withRouter } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
let textInput;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 280,
    minWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
//let globalPDF;
const DealMemoLandingPage = () => {
  const [dealerDashboard, setDealerDashboard] = useState([]);
  const [input, setInput] = useState('');
  const [assignDealId, setAssignDealId] = useState(null);
  const closedDeals = useRef(false);
  
  const [loading, setLoading] = useState({
    pageSpinner: false,
    message: ''
  });

  const [data, setDealerData] = useState({
    columns: [
      {
        label: 'Title',
        field: 'project',
        width: 200
      },
      {
        label: 'Talent',
        field: 'talents',
        width: 100
      },
      {
        label: 'Position',
        field: 'position',
        width: 100
      },

      {
        label: 'Date',
        field: 'dateText',
        width: 100,
      },
      {
        label: 'Division',
        field: 'division',
        width: 100
      },
      {
        label: 'BA Exec',
        field: 'baExecutive',
        width: 100
      },
      {
        label: 'Urgency',
        field: 'urgency',
        width: 60
      },
      {
        'label': 'Status',
        'field': 'status',
        width: 100
      },
      {
        'label': 'Action',
        'field': 'action',
        sort: 'disabled',
        width: 100
      }
    ],
    rows: []
  });
  const fetchData = async () => {
    const [DealData] =
      await Promise.all(
        [
          DealerService.getData(Constants.DealerServiceBaseUrl + 'Deals?isClosed=' + closedDeals.current),
          //DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'BAExecutive?divisionId=1')
        ])
    if (DealData && DealData.data && DealData.data.length > 0) {
      setDealerDashboard(DealData.data);
      setDealerData({
        columns: [
          {
            label: 'Title',
            field: 'project',
            width: 150
          },
          {
            label: 'Talent',
            field: 'talents',
            width: 100
          },
          {
            label: 'Position',
            field: 'position',
            width: 150
          },

          {
            label: 'Date',
            field: 'dateText',
            width: 100,
          },
          {
            label: 'Division',
            field: 'division',
            width: 100
          },
          {
            label: 'BA Exec',
            field: 'baExecutive',
            width: 100
          },
          {
            label: 'Urgency',
            field: 'urgency',
            width: 60
          },
          {
            'label': 'Status',
            'field': 'status',
            width: 100
          },
          {
            'label': 'Action',
            'field': 'action',
            sort: 'disabled',
            width: 100
          }
        ],
        rows: DealData.data.map((dealerData, order) => {
          return {
            project: <Project dealId={dealerData.DealId} isEdit={dealerData.IsEdit}
              searchvalue={dealerData.Project}
              proj={dealerData.Project} alias={dealerData.ProjectAlias}></Project>,
            projectName: dealerData.Project,
            talents: dealerData.Talent.map((dData) => { return dData.TalentName }).toString().split(",").join("\n"),
            position: dealerData.Position,
            division: dealerData.Division,
            baExecutive: (dealerData.BAExecutive != null ? dealerData.BAExecutive : ''),
            urgency: (dealerData.Urgency != null ? dealerData.Urgency : ''),
            date: <DateColumn createdDate={dealerData.CreatedDate}> </DateColumn>,
            status: <StatusColumn searchvalue={dealerData.Status} status={dealerData.Status}
              isApprove={dealerData.IsApprove} isAssign={dealerData.IsAssign} dealId={dealerData.DealId}
              dealer={dealerData} ></StatusColumn>,
            stat: dealerData.Status,
            dateText: datetime.create(dealerData.CreatedDate).format('m/d/y'),
            action: <ActionItems isCancel={dealerData.IsCancel} isApprove={dealerData.IsApprove} isAssign={dealerData.IsAssign}
              isClose={dealerData.IsClose} isEdit={dealerData.IsEdit} dealId={dealerData.DealId} Status={dealerData.Status}
              queryString={dealerData.ApproveRejectQueryString} baExec={dealerData.BAExecutive} division={dealerData.Division} ></ActionItems>
          }
        })
      });
    }
    // if(BAExecutivesData && BAExecutivesData.data && BAExecutivesData.data.length > 0)
    // setBAExecutives(BAExecutivesData.data);

    toggleLoading();
  }
  useEffect(() => {
    toggleLoading('Loading deals...');
    fetchData();
  }, []);


  const toggleLoading = (msg) => {
    setLoading(loading => {
      return {
        ...loading,
        pageSpinner: !loading.pageSpinner,
        message: msg
      }
    }
    );
  }

  const [isModal, setModal] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    pdf: false,
    modal4: false,
    assignDialog: false,
    dealId: null,
    status: ''
  });
  const [entriesCnt, setEntriesCnt] = useState(25);
  const [pages, setPages] = useState(1);
  function Create(props) {
    let history = useHistory();
    return (
      <>
        <Tooltip title="Edit">
          <IconButton aria-label="Edit">
            <CreateIcon fontSize="small" onClick={(event) => {
              history.push('/dealrequestform?dealId=' + props.dealId)
            }} style={{ color: blue[900] }} />
          </IconButton>
        </Tooltip>
      </>);
  }
  function General(props) {
    return (
      <>
        <Tooltip title="View Deal">
          <IconButton aria-label="View Deal">
            {/* <PictureAsPdfIcon onClick={(event)=>{onClickPdfIcon(event,props.dealId)}} fontSize="small" style={{ color: blue[900] }} /> */}
            <PictureAsPdfIcon fontSize="small" onClick={(event) => {
              toggle4(event, props.dealId);
            }} style={{ color: blue[900] }} />
          </IconButton>
        </Tooltip>
      </>);
  }
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      minWidth: 200,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  function StatusTooltip(props) {
    return (
      <HtmlTooltip
        title={
          <div className="statushistoryMain">

            {props.statusHistory.map((st, index) =>
              //  <div className="statushistoryMain">
              //    <div>
              <p key={index} className="statushistoryPara">
                {st.DealAction == "Draft" ? 'Created:  ' : ''}
                {st.DealAction == "Pending for approval" ? 'Pending Approval:  ' : ''}
                {st.DealAction != "Pending for approval" && st.DealAction != "Draft" ? (st.DealAction + ':  ') : ''}
                {st.ActionPerformedBy}
                <br />{st.DealAction != "Draft" && st.DealAction != "Pending Approval" && st.DealAction != "Pending Assignment"
                  ? ' ' + moment(st.ActionPerformedAt).format('MM/DD/YYYY hh:mm:ss A') : ''}
              </p>
              //    </div>
              //     <div className="statushistoryDiv2">
              //       <p className="statushistoryPara">
              //          {st.ActionPerformedBy}                     
              //       </p>
              //    </div> 
              //     <div>
              //       {st.DealAction != "Draft" && st.DealAction != "Pending for approval" ? ' ' + moment(st.ActionPerformedAt).format('MM/DD/YYYY hh:mm A') : ''} 
              //    </div> 
              //  </div> 
            )}
          </div>
        }>
        <span searchvalue={props.searchvalue}>{props.status}</span>
      </HtmlTooltip >

    );
  }

  function ActionItems(props) {
    //
    let isCancel = props.isCancel;
    let isClose = props.isClose;
    let isApprove = props.isApprove;
    let isAssign = props.isAssign;
    let isEdit = props.isEdit;
    let dealId = props.dealId;
    let queryString = props.queryString;
    let status = props.Status
    let baExec = props.baExec;
    //let division = props.division;
    if (isCancel || isClose) {
      if (queryString != null) {
        return (<>
          <Cancel dealId={dealId} queryString={queryString}
            status={status} isEdit={isEdit} isApprove={isApprove}></Cancel>
          <Create dealId={dealId} queryString={queryString} status={status} isEdit={isEdit} isApprove={isApprove}></Create>
          <General dealId={dealId}></General>
        </>)
      } else if (isApprove) {
        return (<>
          <Cancel dealId={dealId} queryString={queryString} status={status} isEdit={isEdit} isApprove={isApprove}></Cancel>
          <Create dealId={dealId} queryString={queryString} status={status} isEdit={isEdit} isApprove={isApprove}></Create>
          <General dealId={dealId}></General>
        </>)
      }
      else if (isAssign) {
        return (<>
          <General dealId={dealId}></General>
          <Assign dealId={dealId} queryString={queryString} isAssign={isAssign} baExec={baExec}></Assign>
        </>)
      } else if (isEdit) {
        return (<>
          <Cancel dealId={dealId} status={status}></Cancel>
          <Create dealId={dealId} queryString={queryString} status={status} isEdit={isEdit} isApprove={isApprove}></Create>
          <General dealId={dealId} status={status}></General>
        </>)
      }
      else {
        return (<>
          <Cancel dealId={dealId} status={status}></Cancel>
          <General dealId={dealId} status={status}></General>
        </>)
      }
    } else {
      if (isEdit) {
        return (<>
          <Create dealId={dealId}></Create>
          <General dealId={dealId}></General>
        </>)
      } else if (isApprove) {
        return (<>
          <Approve dealId={dealId} queryString={queryString} ></Approve>
          <General dealId={dealId}></General>
        </>)
      }
      else if (isAssign) {
        return (<>
          <General dealId={dealId}></General>
          <Assign dealId={dealId} queryString={queryString} isAssign={isAssign} baExec={baExec} ></Assign>
        </>)
      }
      else {
        return (<>
          <General dealId={dealId}></General>
        </>)
      }
    }
  }
  // function ModalViewPDF(props) {
  //   return (
  //     <MDBModal isOpen={isModal.modal4} toggle={toggle4} size='lg'>
  //       <MDBModalHeader toggle={() => setModal(!isModal.modal4)}>PDF Request</MDBModalHeader>
  //       <MDBModalBody>
  //         <embed type="application/pdf" src={globalPDF} frameborder="0" width="100%" height="400px" />
  //       </MDBModalBody>
  //     </MDBModal>
  //   )
  // }
  const onClickApproveIcon = (event, dealId, queryString) => {
    if (dealId) {
      setModal({
        ...isModal,
        modal3: !isModal.modal3,
        dealId: dealId,
        queryString: queryString
      });
    }
  }
  const onClickAssignIcon = (event, dealId, queryString, updateAssignDealId) => {

    if (dealId) {
      setModal({
        ...isModal,
        assignDialog: !isModal.assignDialog,
        dealId: dealId,
        queryString: queryString
      });

    }
    updateAssignDealId(dealId);
  }
  function Cancel(props) {

    return (
      <>
        <Tooltip title={props.status == 'Draft' ? 'Cancel' : 'Close'}>
          <IconButton aria-label="Delete">
            <DeleteOutlinedIcon onClick={(event) => { toggle1(event, props.dealId, props.status) }} fontSize="small" style={{ color: blue[900] }}></DeleteOutlinedIcon>
          </IconButton>
        </Tooltip>
        {/* <ModalViewForDelete dealId={props.dealId} status={props.status}></ModalViewForDelete> */}
      </>
    );
  }

  const Assign = (props) => {

    // let assignDealId = "";
    let updateAssignDealId = (dealID) => {
      //assignDealId = dealID;
      setAssignDealId(dealID);
    }
    return (
      <>
        {/* {isModal.assignDialog && assignDealId === props.dealId ? <ModalViewAssign dealId={props.dealId} assignDealId={assignDealId} /> : ""} */}
        <Tooltip title="Assign" >
          <IconButton aria-label="Assign">
            <CheckCircleOutlineOutlinedIcon onClick={(event) => {
              textInput = '';
              onClickAssignIcon(event, props.dealId, props.queryString, updateAssignDealId)
            }} fontSize="small" style={{ color: blue[900] }} />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  function Approve(props) {
    return (
      <>
        <Tooltip title="Reject">
          <IconButton aria-label="Reject">
            <CancelOutlinedIcon onClick={(event) => {
              textInput = '';
              onClickRejectIcon(event, props.dealId, props.queryString)
            }} fontSize="small" style={{ color: blue[900] }} />
          </IconButton>
        </Tooltip>
        {/* <ModalViewReject dealId={props.dealId} />
        <ModalViewApprove dealId={props.dealId} /> */}
        {/* <ModalViewAssign dealId = {props.dealId} /> */}
        <Tooltip title="Approve" >
          <IconButton aria-label="Approve">
            <CheckCircleOutlineOutlinedIcon onClick={(event) => {
              textInput = '';
              onClickApproveIcon(event, props.dealId, props.queryString)
            }} fontSize="small" style={{ color: blue[900] }} />
          </IconButton>
        </Tooltip>
      </>
    );
  }
  function Project(props) {
    let Project = props.proj;
    let dealId = props.dealId;
    let alias = props.alias.split(";").join("\n")
    let isEdit = props.isEdit;
    return (
      <>
        <Link to={{ pathname: "/dealrequestform", search: "?dealId=" + dealId }}
          className="project">{Project}</Link>
        <br></br>
        <span className="whiteSpace">{alias}</span>
      </>
    );
  }
  function Talent(props) {
    let text = props.data.map((dData) => { return dData.TalentName }).toString().split(",").join("\n")
    return (<span className="whiteSpace">{text}</span>)
  }
  function StatusColumn(props) {
    let status = props.status;
    let dealId = props.dealId;
    let dealerHistory = props.dealer.DealStatusHistory;
    let comments = '';
    if (props.isApprove) {
      comments = 'Pending Approval'
    }
    if (props.isAssign) {
      comments = 'Pending Assignment'
    }
    let searchvalue = props.searchvalue;

    if (status == 'Submitted' || status == "Approved") {
      return (
        <>
          <StatusTooltip dealId={dealId} status={status}
            searchvalue={searchvalue} statusHistory={dealerHistory} ></StatusTooltip>
          <br></br>

          <span className="statusText">{comments}</span>
        </>);
    } else {
      return (
        <>
          <StatusTooltip dealId={dealId} status={status} searchvalue={searchvalue} statusHistory={dealerHistory} ></StatusTooltip>
        </>
      );
    }

  }

  function DateColumn(props) {
    let searchvalue = props.createdDate;
    let createdDate = datetime.create(props.createdDate).format('m/d/y');
    return (
      <>
        <span searchvalue={createdDate} >{createdDate}</span>
      </>);
  }

  function refreshDataGrid() {
    toggleLoading('Refreshing Dashboard');
    fetchData();
  }
  function ModalViewForDelete(props) {
    //debugger;
    return (
      <MDBModal isOpen={isModal.modal1} toggle={toggle1} size='sm' >
        <MDBModalHeader toggle={() => setModal(!isModal.modal1)}>
          {
            isModal.status == 'Assigned' || isModal.status == 'Approved' ? 'Close Deal Request' :
              'Cancel Deal Request'
          }
        </MDBModalHeader>
        <MDBModalBody>
          {
            isModal.status == 'Assigned' || isModal.status == 'Approved' ? 'Please Confirm to Close the Deal?' :
              'Please Confirm to Cancel the Deal?'
          }
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn size="sm" onClick={(event) => { onClickCancel(event, props.dealId, props.status) }}>Confirm</MDBBtn>
          <MDBBtn size="sm" onClick={toggle1}>Cancel</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
  function ModalViewReject() {
    return (
      <MDBModal isOpen={isModal.modal2} toggle={toggle2}  >
        <MDBModalHeader toggle={() => { setModal(!isModal.modal2); textInput = '' }}>Reject Deal</MDBModalHeader>
        <MDBModalBody>
          Reject Deal Request. Please provide rejection comments.
        </MDBModalBody>
        <div style={{ width: '90%', marginLeft: '15px' }}>
          <MDBInput label="Enter Comment" outline size="sm" onChange={e => textInput = e.target.value} />
        </div>
        <MDBModalFooter>
          <MDBBtn onClick={toggle2}>Cancel</MDBBtn>
          <MDBBtn onClick={(event) => { onClickAppRjtBtn(event, isModal.dealId, isModal.queryString,
             'Rejected', textInput) }}>Reject</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
  function ModalViewApprove() {
    return (
      <MDBModal isOpen={isModal.modal3} toggle={toggle3} >
        <MDBModalHeader toggle={() => { setModal(!isModal.modal3); textInput = '' }}>Approve Deal</MDBModalHeader>
        <MDBModalBody>
          Are you sure you want to Approve this Deal?
          </MDBModalBody>
        <div style={{ width: '90%', marginLeft: '15px' }}>
          <MDBInput label="Enter Comment" outline size="sm" onChange={e => textInput = e.target.value} />
        </div>
        <MDBModalFooter>
          <MDBBtn onClick={toggle3}>Cancel</MDBBtn>
          <MDBBtn onClick={(event) => {
            onClickAppRjtBtn(event, isModal.dealId, isModal.queryString,
              'Approved', textInput)
          }}>Confirm</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
  function ModalViewAssign(props) {

    //let BAExecutives = [];
    const updateBAExec = (BAExecData) => {
      setBAExecutives(BAExecData);
    }
    const [BAExecutives, setBAExecutives] = useState([]);
    const [assignedUserId, setAssignedUserId] = useState(null);
    //const [childDealId, setChildDealId] = useState(null);
    useEffect(() => {
      if (isModal.assignDialog) {
        DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'BAExecutive?dealId=' 
        + isModal.dealId)
          .then((response) => {
            let BAExecutivesData = response.data;
            if (BAExecutivesData && BAExecutivesData.length > 0)
              // setBAExecutives({
              //   BAExecutivesData
              // });
              setBAExecutives(BAExecutivesData);
          },
            err => {
              console.log("BA Assignment : " + err);
            });
      }
    }, []);
    return (
      <MDBModal isOpen={isModal.assignDialog} toggle={toggleAssign} >
        {/* <MDBModalHeader toggle={() => { setModal(!isModal.assignDialog); textInput = '' }}>Assign Deal</MDBModalHeader> */}
        <MDBModalBody>
          <MDBContainer className="sectionContainer">
            <MDBRow>
              <MDBCol>
                <label className="labelHeaders">
                  Assign the BA Executive to Deal
                  </label>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="sectionContainer">
            <MDBRow>
              <MDBCol size='4' className="labelName">
                BA Executive:
                </MDBCol>
              <MDBCol size="8">
                <Autocomplete autoHighlight
                  className="tags-standard"
                  options={BAExecutives ? BAExecutives : []}
                  getOptionLabel={(option) => option.Name}
                  //value={assignedUserId}
                  onChange={(event, value) => {
                    //   setAssignedUserId(assignedUserId => 
                    //     {
                    //     return {
                    //     assignedUserId : value
                    //   }
                    // });
                    setAssignedUserId(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label=""
                    />
                  )}
                />
              </MDBCol>
            </MDBRow>
            <br></br>
            <MDBRow>
              <MDBCol size='4' className="labelName">
                <label className="labelName">Comments</label>
              </MDBCol>
              <MDBCol size="8">
                {/* <div style={{ width: '90%', marginLeft: '15px' }}> */}
                {/* <MDBInput label="Enter Comment" outline size="sm" onChange={e => textInput = e.target.value} /> */}


                <textarea
                  className="form-control" onChange={e => textInput = e.target.value}
                  id="comments"
                  rows="2"
                />
                {/* </div> */}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn onClick={toggleAssign}>Cancel</MDBBtn>
          <MDBBtn onClick={(event) => {
            onClickAppRjtBtn(event, isModal.dealId, isModal.queryString,
              'Assigned', textInput, assignedUserId && assignedUserId.UserId ? assignedUserId.UserId : null)
          }}>Confirm</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
  const toggle1 = (event, dealId, status) => {
    if (dealId && dealId != 0) {
      setModal({
        ...isModal,
        modal1: !isModal.modal1,
        dealId: dealId,
        status: status
      });
    } else {
      setModal({
        ...isModal,
        modal1: !isModal.modal1,
        dealId: 0,
        status: ''
      });
    }
  }
  const toggle2 = () => {
    setModal({
      ...isModal,
      modal2: !isModal.modal2
    });
  }
  const toggle3 = () => {
    setModal({
      ...isModal,
      modal3: !isModal.modal3
    });
  }
  const toggleAssign = () => {
    setModal({
      ...isModal,
      assignDialog: !isModal.assignDialog
    });
  }
  const toggle4 = (event, dealId) => {

    //window.html2canvas = html2canvas;
    // setModal({
    //   ...isModal,
    //   modal4: !isModal.modal4
    // });

    DealerService.getData(Constants.DealerServiceBaseUrl + 'Deal' + '?dealId=' + dealId)
      .then(response => {
        let responseData = response.data[0];
        DealPDFDocGen(responseData);
      });

  }
  const togglePdf = () => {
    setModal({
      ...isModal,
      pdf: !isModal.pdf
    });
  }

  const onClickRejectIcon = (event, dealId, queryString) => {
    if (dealId) {
      setModal({
        ...isModal,
        modal2: !isModal.modal2,
        dealId: dealId,
        queryString: queryString
      });
    }
  }
  const onClickPdfIcon = (event, dealId) => {
    if (dealId) {
      setModal({
        ...isModal,
        pdf: !isModal.pdf,
        dealId: dealId
      });
    }
  }
  const onClickCancel = (event, dealId, status) => {
    if (isModal.dealId && isModal.dealId != 0 && isModal.status != '') {
      dealId = isModal.dealId;
      status = isModal.status;
      let url = Constants.DealerServiceBaseUrl + 'DealCancelOrClose'
      let successMsg = '';
      let errorMsg = '';
      let obj = { "StatusId": status == 'Draft' ? 6 : 5, "DealId": dealId }
      if (status == 'Draft') {

        successMsg = 'Deal Cancelled';
        errorMsg = 'Deal Cancel Failed';
      } else if (status == 'Assigned') {
        successMsg = 'Deal Closed';
        errorMsg = 'Deal Close Failed';
      }

      if (url != '') {
        DealerService.postData(url, obj)
          .then(res => {
            // do good things	
            if (res) {
              notifications('info', res.data, 3000)
              toggle1();
              toggleLoading('Loading deals...');
              fetchData();
            }
            else {
              notifications('error', errorMsg, 3000)
              toggle1();
            }
            //NotificationManager.clear();
          })
          .catch(err => {
            notifications('error', errorMsg, 3000)
            toggle1();
            //NotificationManager.clear();
          });
      }
    }
  }
  //API call	
  const onClickAppRjtBtn = (event, dealId, queryString, method, textInput, assignedUserId) => {
    
    let url = '';
    let successMsg = '';
    let errorMsg = '';
    if (method == 'Approved') {
      url = Constants.DealerServiceBaseUrl + 'DealApproveUI?' + queryString + '&Comments=' + (textInput ? textInput : '');
      successMsg = 'Deal Approved';
      errorMsg = 'Deal Approval Failed';
    }
    else if (method == 'Assigned') {
      
      if (assignedUserId) {
        url = Constants.DealerServiceBaseUrl + 'DealAssign?' + 'dealId=' + dealId +
          '&assignedUserId=' + assignedUserId +
          '&assignerComments=' + (textInput ? textInput : '');
        successMsg = 'Deal Assigned';
        errorMsg = 'Deal Assignment Failed';
      } else {
        alert('Please select BA Executive');
        return false
      }
    }
    else {
      url = Constants.DealerServiceBaseUrl + 'DealRejectUI?' + queryString + '&Comments='
        + (textInput ? textInput : '');
      successMsg = 'Deal Rejected';
      errorMsg = 'Deal Reject Failed';
    }
    if (method == 'Rejected' && textInput == '') {
      alert('Rejection Comments required');
      return;
    }
    DealerService.getData(url)
      .then(res => {
        // do good things	
        if (res) {
          notifications('info', res.data, 3000)
          if (method == 'Approved') toggle3();
          else if (method == 'Assigned') toggleAssign();
          else toggle2();
        }
        else {
          notifications('error', errorMsg, 3000)
          if (method == 'Approved') toggle3();
          else if (method == 'Assigned') toggleAssign();
          else toggle2();
        }
        toggleLoading('Loading deals...');
        fetchData();
        //NotificationManager.clear();
      })
      .catch(err => {
        notifications('error', errorMsg, 3000)
        if (method == 'Approved') toggle3();
        else if (method == 'Assigned') toggleAssign();
        else toggle2();
        //NotificationManager.clear();
      });
  }
  const notifications = (status, message, timeOut) => {
    let notificationId;
    switch (status) {
      case 'info':
        notificationId = NotificationManager.info(message, 'Deal', timeOut);
        break;
      case 'success':
        notificationId = NotificationManager.success(message, 'Deal', timeOut);
        break;
      case 'warning':
        notificationId = NotificationManager.warning(message, 'Deal', timeOut);
        break;
      case 'error':
        notificationId = NotificationManager.error(message, 'Deal', timeOut);
        break;
    }
    //NotificationManager.remove(notificationId);
  }
  // const data = {
  //   columns: [
  //     {
  //       label: 'Title',
  //       field: 'project',
  //       width: 200
  //     },
  //     {
  //       label: 'Talent',
  //       field: 'talents',
  //       width: 100
  //     },
  //     {
  //       label: 'Position',
  //       field: 'position',
  //       width: 100
  //     },

  //     {
  //       label: 'Date',
  //       field: 'dateText',
  //       width: 100,
  //     },
  //     {
  //       label: 'Division',
  //       field: 'division',
  //       width: 100
  //     },
  //     {
  //       label: 'BA Exec',
  //       field: 'baExecutive',
  //       width: 100
  //     },
  //     {
  //       label: 'Urgency',
  //       field: 'urgency',
  //       width: 60
  //     },
  //     {
  //       'label': 'Status',
  //       'field': 'status',
  //       width: 100
  //     },
  //     {
  //       'label': 'Action',
  //       'field': 'action',
  //       sort: 'disabled',
  //       width: 100
  //     }
  //   ],
  //   rows: dealerDashboard.map((dealerData, order) => {
  //     // const TalentResponse = dealerData.Talent.map((dData) => { return dData.TalentName }).toString();
  //     // console.log('dealerData.TalentResponse', TalentResponse)
  //     return {
  //       project: <Project dealId={dealerData.DealId}
  //         searchvalue={dealerData.Project} proj={dealerData.Project} alias={dealerData.ProjectAlias}></Project>,
  //       projectName: dealerData.Project,
  //       talents: <Talent data={dealerData.Talent}></Talent>,
  //       position: dealerData.Position,
  //       division: dealerData.Division,
  //       baExecutive: (dealerData.BAExecutive != null ? dealerData.BAExecutive : ''),
  //       urgency: (dealerData.Urgency != null ? dealerData.Urgency : ''),
  //       date: <DateColumn createdDate={dealerData.CreatedDate}> </DateColumn>,
  //       status: <StatusColumn searchvalue={dealerData.Status} status={dealerData.Status}
  //         isApprove={dealerData.IsApprove} isAssign={dealerData.IsAssign} dealId={dealerData.DealId}
  //         dealer={dealerData} ></StatusColumn>,
  //       stat: dealerData.Status,
  //       dateText: datetime.create(dealerData.CreatedDate).format('m/d/y'),
  //       action: <ActionItems isCancel={dealerData.IsCancel} isApprove={dealerData.IsApprove} isAssign={dealerData.IsAssign}
  //         isClose={dealerData.IsClose} isEdit={dealerData.IsEdit} dealId={dealerData.DealId} Status={dealerData.Status}
  //         queryString={dealerData.ApproveRejectQueryString} baExec={dealerData.BAExecutive} division={dealerData.Division} ></ActionItems>
  //     }
  //   })
  // }
  const closedDealsChange = (e, t) => {
    closedDeals.current = e.target.checked;
    if (e.target.checked) {
      toggleLoading('Displaying Closed Deals');
    }
    else {
      toggleLoading('Displaying All Deals');
    }
    fetchData();
  }
  return (
    <MDBContainer className="TP20">
      <PageSpinner pageSpinner={loading.pageSpinner} message={loading.message}></PageSpinner>
      <ModalViewApprove />
      <ModalViewReject />
      <ModalViewAssign dealId={isModal.dealId} assignDealId={isModal.dealId} />
      <ModalViewForDelete dealId={isModal.dealId} status={isModal.status}></ModalViewForDelete>
      <MDBRow>

      </MDBRow>
      <MDBRow>
        <MDBCol size="4" className="textAlignCenter semiCondensedFont">

        </MDBCol>
        <MDBCol size="4" className="textAlignCenter">
          <h5 className="primaryColor semiCondensedFont">Dealer Dashboard</h5>
        </MDBCol>
        <MDBCol size="3" className="textAlignRight">
          <MDBInput label="Show Closed Deals" type="checkbox" id="checkbox1" onChange={(event, value) => {
            { closedDealsChange(event, value) }
          }} />

        </MDBCol>
        <MDBCol size="1" className="primaryColor" >
          <a title='refresh' onClick={refreshDataGrid}>
            <i class={!loading.pageSpinner ? "fas fa-refresh fa-rotate-125" : "fas fa-refresh fa-spin"}>
            </i></a>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBDataTableV5
            hover
            entriesOptions={[10, 25, 50, 75]} responsive id="dashboard"
            entries={25}
            pagesAmount={4}
            data={data}
            searchTop
            searchBottom={false} barReverse scrollX
            sortRows={['status', 'project', 'date']}
            noRecordsFoundLabel=" "
          >

          </MDBDataTableV5>
        </MDBCol>
        <MDBCol>
          <div class="preloader-wrapper big">
            <div class="spinner-layer spinner-blue-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="gap-patch">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <NotificationContainer />
      </MDBRow>
    </MDBContainer>

  )
}
export default DealMemoLandingPage;
