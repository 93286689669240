import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDataTableV5, MDBInput } from 'mdbreact';



function Role(props) {
    let handleChange = (event) => {
        let role = event.target.value;
        props.onChange(event.target.value);
    }
    return (

        <MDBInput label="Role*" value={props.value != '' ? props.value : ''}
            //className={formValidate && dealDetailsForm.role == '' ? 'validateTextField' : ''}
            className={props.className}
            onChange={handleChange}
            id="txtRole" color="primary" disabled = {props.disabled} />

    )

}

export default Role;

