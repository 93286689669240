import React, { useRef, useState, useEffect } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBSelect, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../styles/Autocomplete.css';
import '../styles/Form.css';
import PageSpinner from './PageSpinner';
import DealerService from '../services/service';
import * as Constants from '../constants/constants';



function DealRequestBAAssignment(props) {
    let history = useHistory();
    const queryString = require('query-string');
    const params = queryString.parse(window.location.search);
    console.log("BA Assignment DealId : " + params.dealId);

    const [dealDetails, setDealDetails] = useState('');
    const [loading, setLoading] = useState({
        pageSpinner: false,
        message: ''
    });

    const [toggleOpen, setToggleOpen] = useState(false);
    const [confirmCheck, setConfirm] = useState(true);
    const [dealId, setDealId] = useState(null);
    const [BAExecutives, setBAExecutives] = useState([]);
    const [assignedUserId, setAssignedUserId] = useState(null);
    const [assignerComments, setAssignerComments] = useState('');

    useEffect(() => {
        let dealId = null;
        if (params.dealId) {
            setDealId(params.dealId);
            dealId = params.dealId;
        }

        const fetchExistingDeal = (dealId) => {
            let allDetailsFetched = false;
            if (dealId) {
                toggleLoading('Fetching Deal details');
                DealerService.getData(Constants.DealerServiceBaseUrl + 'AssignerDealInfo' + '?dealId=' + dealId)
                    .then(response => {
                        let responseData = response.data;
                        setAssignerComments(responseData['BA Assigner Comments'])
                        setAssignedUserId({ Name: responseData['BA Executive Name'], UserId: responseData['BA Executive Id'] })
                        setDealDetails(responseData);
                        if (!allDetailsFetched)
                            toggleLoading('');

                        allDetailsFetched = true;
                    });

                DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'BAExecutive' + '?dealId=' + dealId)
                    .then(response => {
                        let responseData = response.data.filter(function (el) {
                            return el.IsActive
                        });
                        setBAExecutives(responseData);
                        if (!allDetailsFetched)
                            toggleLoading('');

                        allDetailsFetched = true;
                    },
                        (error) => {
                            console.log("BA Assignment failed");
                        });
            }
        }

        fetchExistingDeal(dealId);

    }, []);


    const toggleLoading = (msg) => {
        setLoading(loading => {
            return {
                ...loading,
                pageSpinner: !loading.pageSpinner,
                message: msg
            }
        }
        );
    }
    const toggleOpenMethod = () => {
        setToggleOpen(!toggleOpen);
    }

    const onSaveCheck = () => {
        if (dealDetails["BA Executive Id"] && confirmCheck) {
            toggleOpenMethod();
        }
        else if (dealDetails["BA Executive Id"] && !confirmCheck) {
            saveData();
        }
        else if (dealDetails["BA Executive Id"] == '') {
            saveData();
        }
        else {
            saveData();
        }
    }

    function HandleToggleModal() {
        return (
            <MDBModal isOpen={toggleOpen}
                toggle={toggleOpenMethod}
                id="modalBorder"
                size='sm' >
                <MDBModalBody>
                    Are you sure you want to re-assign?
         </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" className="btnCreate" id="btnCreate"
                        onClick={() => { setConfirm(false); toggleOpenMethod(); saveData() }}>OK</MDBBtn>
                    <MDBBtn size="sm" onClick={() => { setConfirm(true); toggleOpenMethod() }}>Cancel</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )

    }


    let saveData = e => {

        if (dealId != null && assignedUserId && assignedUserId.UserId) {
            DealerService.getData(Constants.DealerServiceBaseUrl + 'DealAssign' +
                '?dealId=' + dealId + '&assignedUserId=' + assignedUserId.UserId +
                '&assignerComments=' + assignerComments)
                .then(response => {
                    let responseData = response.data;
                    alert("BA Assignment successful");
                    history.push('/dashboard')
                },
                    (error) => {
                        alert("BA Assignment failed");
                    });
        } else if (assignedUserId == null || assignedUserId && assignedUserId.UserId == '') {
            alert('Please select BA Executive');
            return false;
        }
    }

    const onCancel = () => {
        history.push('/');
    }

    const DisplayDealDetails = () => {
        var arr = [];
        Object.keys(dealDetails).forEach(function (key) {
            if (key != "BA Executive Name" && key != "BA Executive Id"  && key != "BA Assigner Comments")
                arr.push(key);
        });
        // let DivisionGroupRoles = props.userAccess.DivisionRoleGroups;
        return <div>
            {
                dealDetails != null && dealDetails != ''
                    ?
                    <div>
                        {
                            arr.map((row, index) => {
                                return <div key={row} >
                                    {
                                        <MDBRow>
                                            <MDBCol size='4' className="labelName">
                                                {row}
                                            </MDBCol>
                                            <MDBCol size='1'>
                                                :
                                            </MDBCol>
                                            <MDBCol size='4' className="labelValue" >
                                                {dealDetails[row]}
                                            </MDBCol>
                                            <MDBCol size='3'>

                                            </MDBCol>
                                        </MDBRow>
                                    }
                                </div>
                            })
                        }

                        <MDBRow>
                            <MDBCol size='4' className="labelName">
                                BA Executive
                    </MDBCol>
                            <MDBCol size='1'>
                                :
                    </MDBCol>
                            <MDBCol size='4' id="baexec" >
                                <Autocomplete autoHighlight
                                    className="tags-standard"
                                    options={BAExecutives}
                                    getOptionLabel={(option) => option.Name}
                                    onChange={(event, value) => {
                                        setAssignedUserId(value);
                                    }}
                                    value={assignedUserId}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label=""
                                        />
                                    )}

                                />
                            </MDBCol>
                            <MDBCol size='3'>

                            </MDBCol>
                        </MDBRow>
                        <br></br>
                        <MDBRow >
                            <MDBCol size='4' className="labelName">
                                Comments
                    </MDBCol>
                            <MDBCol size='1'>
                                :
                    </MDBCol>
                            <MDBCol size='4' id="baexec" >
                                <textarea
                                    className="form-control"
                                    id="comments"
                                    rows="2"
                                    onChange={(event, args) => {
                                        setAssignerComments(event.target.value);
                                    }
                                    }
                                    value={assignerComments}
                                />
                            </MDBCol>
                            <MDBCol size='3'>

                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="1"></MDBCol>
                            <MDBCol size="5">

                                <MDBBtn size="sm" onClick={() => onCancel()} >Cancel</MDBBtn>
                                <MDBBtn size="sm" onClick={onSaveCheck} >Save</MDBBtn>
                            </MDBCol>
                            <MDBCol size="6"></MDBCol>
                        </MDBRow>
                        <HandleToggleModal></HandleToggleModal>
                    </div>
                    :
                    <div>
                        {
                            <MDBRow>
                                <MDBCol size='12' className="labelValue">
                                    Selected Deal request is not pending for BA Assignment!
                                </MDBCol>
                            </MDBRow>
                        }
                    </div>
            }
        </div>
    }

    return (
        <MDBContainer className="sectionContainer">
            <MDBRow className="sectionBgColor" >
                <MDBCol >
                    <label>BA ASSIGNMENT FORM</label>
                </MDBCol>
            </MDBRow>

            {DisplayDealDetails()}



            <PageSpinner pageSpinner={loading.pageSpinner} message={loading.message}></PageSpinner>
        </MDBContainer>
    );

}

export default DealRequestBAAssignment;