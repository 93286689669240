import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDataTableV5 } from 'mdbreact';



function RoutingDataGrid(props) {
  console.log('props in RoutingGrid...', props);
  return (
    <MDBRow >
      <MDBCol size="12" className="sectionBgColor">
        <label >ROUTING</label>
      </MDBCol>
      <MDBCol size="12">
        <MDBDataTableV5 paging={false}
          hover
          responsive id="distributors" searching={false} info={false}
          sortable={false} 
          data={props.gridData}
          >
          
        </MDBDataTableV5>
      </MDBCol>
    </MDBRow>
  )

}

export default RoutingDataGrid;

