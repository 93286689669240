import axios from 'axios';
import authService from "../app.config"
class DealerService {

    constructor() {
        console.log("Dealer service created");
    }

    async getData(httpPath) {
        let token = await authService.getAccessToken();
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        return axios.get(httpPath, { headers });
    }
    async fetchData(httpPath) {
        let token = await authService.getAccessToken();
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        
        let options = {
            headers: new Headers(headers)
        };

        return fetch(httpPath, options);
    }
    async postData(httpPath, body) {
        //
        let token = await authService.getAccessToken();

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        return axios.post(httpPath, JSON.stringify(body), { headers });
    }
}

//const DealerService = new Service();
export default new DealerService();