import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDataTableV5 } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export default function TalentViews(props) {

  let newTalentAdded = (event, value) => {
    console.log('On keypress...', event.target.value);
    if (event.charCode == 13) {
      let selectedTitle = event.target.value;
      props.keyPress(selectedTitle);
    }
  }

  let talentChange = (event, value) => {
    console.log('talentChange...', value);
    props.change(value);
  }
  if (props.ManageTalent) {
    return (<Autocomplete autoHighlight
      size="small"
      className="tags-standard"
      options={props.options}
      getOptionLabel={(option) => option.talent}
      onKeyPress={newTalentAdded}
      onChange={talentChange}
      disabled={props.disabled}
      defaultValue={props.value[0]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Talent*"
        />
      )}
    />)
  } else {
    return (
      <Autocomplete autoHighlight
        multiple size="small"
        value={props.value}
        className="tags-standard"
        options={props.options}
        getOptionLabel={(option) => option.talent}
        defaultValue={props.value}
        onKeyPress={newTalentAdded}
        onChange={talentChange}
        disabled={props.disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Talent*"
            error={props.isformValidated ? (props.value && props.value.length > 0 ? false : true) : false}
          />
        )}
      />
    )
  }
}