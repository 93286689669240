import React, { useState, useEffect } from "react";
import {
    MDBModalBody, MDBCol, MDBSelec, MDBModalHeader, MDBModalFooter,
    MDBInput, MDBBtn, MDBContainer, MDBRow, MDBSelect
} from 'mdbreact';
import { blue } from '@material-ui/core/colors';
import fetch from 'cross-fetch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserAccessDropDowns from './UserAccessDropdowns';
import AddBoxIcon from '@material-ui/icons/AddBox';
import * as Constants from '../../constants/constants'
import DealerService from '../../services/service';
import CancelIcon from '@material-ui/icons/Cancel';
function ManageUsersModal(props) {
    const [userAccessOptions, setUserAccessOptions] = React.useState([]);
    const [userEnabled, setuserEnabled] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [representatives, setRepresentatives] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [assistants, setassistants] = React.useState([]);
    const selectedUserJson = {
        UserId: '',
        Name: '',
        Email: '',
        NotificationEmail: '',
        Phone: '',
        IsActive: true,
        DivisionRoleGroups: [],
        AssistantToList: []
    }
    const [selectedUser, setSelectedUser] = React.useState(selectedUserJson);
    const [selectedAssistants, setSelectedAssistants] = React.useState([]);
    const [userAccess, setUserAccess] = React.useState([]);
    const [divisionAccessArray, setdivisionAccessArray] = React.useState([]);
    const [lastRow, setLastRow] = React.useState(1);
    const [initIndex, setinitIndex] = React.useState(0);
    const [statusOptions, setStatusOptions] = useState([
        {
            text: "Active",
            value: "1",
            //checked : false
        },
        {
            text: "Inactive",
            value: "2",
            //checked : false
        }
    ]);
    let initialIndex = 0;
    const loading = open && options.length === 0;
    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            const response = await DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Users')
            const users = response && response.data && response.data.length > 0 ? response.data : [];

            // if (active) {
            setOptions(users);
            // }
        })();
        return () => {
            active = false;
        };
    }, [loading]);
    React.useEffect(() => {
        // if (!open) {
        //     setOptions([]);
        // }
    }, [open]);

    useEffect(() => {
        const fetchData = async () => {
            const [usersData, userAccessOptions] =
                await Promise.all(
                    [
                        DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Users'),
                        DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'DivisionGroupRole'),
                    ])
            if (usersData && usersData.data && usersData.data.length > 0) {
                setUsers(usersData.data);
            }
            setUserAccessOptions(userAccessOptions.data);
        }
        fetchData();
    }, []);

    let onUserSelected = (value) => {
        let assistantToList = [];
        let assistantUsers = []
        if (value && value.AssistantToList && value.AssistantToList.length > 0) {
            value.AssistantToList.map(obj => {
                if (obj && obj.AssistantToId)
                    var user = users.filter(element => element.UserId == obj.AssistantToId)
                if (user && user.length > 0) {
                    assistantUsers.push(user[0]);
                }
            })
        }
        setSelectedAssistants(assistantUsers);

        if (value) {
            let activeCd = (value.IsActive ? "1" : "2");
            let statOptions = statusOptions;
            statOptions.map(option => {
                if (option.value === activeCd) {
                    option.checked = true;
                }
            });
            setStatusOptions(statOptions);
            //setuserEnabled(true);
        }
        else {
            // setuserEnabled(false);
            setSelectedUser(selectedUserJson);
            setSelectedAssistants([]);
        }
    }
    let onAssistantSelected = (value) => {
        setSelectedAssistants(value);
    }

    let clearRow = (index) => {
        if (selectedUser != null &&
            selectedUser.DivisionRoleGroups != null && selectedUser.DivisionRoleGroups.length > 0) {
            let array = selectedUser.DivisionRoleGroups;
            let r = array.splice(index, 1);
            setSelectedUser({
                ...selectedUser,
                DivisionRoleGroups: array
            });
        }
    }

    let AssistantsTo = () => {
        return (
            <Autocomplete autoHighlight size="small" name="assistantTo"
                id="assistantTo" multiple
                options={users.filter(function (el) { return el.IsActive })}
                freeSolo disabled={userEnabled}
                getOptionLabel={(option) => option.Name}
                value={selectedAssistants ?
                    selectedAssistants : []}
                onChange={(event, newValue) => {
                    onAssistantSelected(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Assistant To"
                        placeholder="Assistant To"
                    />)}
            />
        )
    }
    let UserSearch = (props) => {
        return (
            <Autocomplete autoHighlight size="small" className="tags-standard" freeSolo
                value={props.Name}
                defaultValue={selectedUser}
                onChange={userChange}
                getOptionLabel={(option) => option.Name}
                options={users}
                loading={loading}
                renderInput={(params) => (
                    <TextField disabled={props.userEnabled}
                        {...params}
                        variant="standard"
                        label="User's Name*"
                        placeholder="Search User"
                    />
                )}
            />)
    }

    let userChange = (event, value) => {

        if (typeof value === 'string') {
            let newUser = getNewUser(value);
            setSelectedUser(newUser);
            onUserSelected(newUser);
            setuserEnabled(false)
        }
        else {
            setSelectedUser(value);
            onUserSelected(value);
            if (value)
                setuserEnabled(false)
            else
                setuserEnabled(true)
        }

    }

    let onUserChanged = (value) => {
        setSelectedUser(value);
    }
    let userAccessChange = (value, role, index) => {
        //value is whole state except order selection
        // role is role selection
        let division = {
            "DivisionId": value.division.DivisionId,
            "DivisionName": value.division.DivisionName,
            "Groups": [{
                "GroupId": value.group.GroupId,
                "GroupName": value.group.GroupName,
                "Roles": [{
                    "RoleId": role.RoleId,
                    "RoleName": role.RoleName
                }]
            }]
        };

        // let index = props.indexProp;
        if (selectedUser != null &&
            selectedUser.DivisionRoleGroups != null && selectedUser.DivisionRoleGroups.length > 0) {
            let array = [];
            selectedUser.DivisionRoleGroups.forEach((div, i) => {
                if (i === index)
                    array.push(division);
                else
                    array.push(div);
            });
            selectedUser.DivisionRoleGroups = array;
            setSelectedUser(selectedUser);
        }

        // let divisionAccess = divisionAccessArray;
        // if (value) {


        //     if (value.position > 0) {
        //         if (divisionAccess.length <= value.position)
        //             //new record
        //             divisionAccess.push(division)
        //         else if (divisionAccess.length > value.position)
        //             //existing record update
        //             divisionAccess[value.position - 1] = division;

        //     } else {
        //         //Record at index 0 update if exists else push
        //         if (divisionAccess.length > 0)
        //             divisionAccess[0] = division
        //         else
        //             divisionAccess.push(division)
        //     }
        //     console.log('divisions', divisionAccess)
        //     setdivisionAccessArray(divisionAccess)
        // }

    }
    const UserAccessDiv = () => {

        // let DivisionGroupRoles = props.userAccess.DivisionRoleGroups;
        return <div disabled={userEnabled}>
            {
                selectedUser != null && selectedUser.DivisionRoleGroups != null
                    && selectedUser.DivisionRoleGroups.length >= 1
                    ?
                    <div>
                        {
                            selectedUser.DivisionRoleGroups != null &&
                            selectedUser.DivisionRoleGroups.map((division, index) => {
                                return <div key={index}>
                                    {
                                        <MDBRow>
                                            <MDBCol size='10'>
                                                <UserAccessDropDowns selectedDivision={division}
                                                    options={userAccessOptions}
                                                    change={userAccessChange}
                                                    indexProp={index}></UserAccessDropDowns>
                                            </MDBCol>
                                            <MDBCol size='2' className="mngUserIcons">
                                                <CancelIcon onClick={() => clearRow(index)} style={{ color: blue[900], fontSize: 21 }}></CancelIcon>
                                                {(index == 0) && <AddBoxIcon onClick={addNewRow} style={{ color: blue[900], fontSize: 21 }} />}
                                            </MDBCol>
                                        </MDBRow>
                                    }
                                </div>
                            })
                        }

                    </div>
                    :
                    <div>
                        {
                            userAccessOptions != null && userAccessOptions.length > 0 &&
                            // <div key={initIndex}>
                            //     {
                            <MDBRow>
                                <MDBCol size='10'>
                                    <UserAccessDropDowns options={userAccessOptions}
                                        indexProp={initIndex} change={userAccessChange}></UserAccessDropDowns>
                                </MDBCol>
                                <MDBCol size='2' className="mngUserIcons">
                                    <CancelIcon onClick={() => clearRow(0)} style={{ color: blue[900], fontSize: 21 }}></CancelIcon>
                                    <AddBoxIcon onClick={addNewRow} style={{ color: blue[900], fontSize: 21 }} />
                                </MDBCol>
                            </MDBRow>
                            // }
                            // </div>  
                        }
                    </div>
            }
        </div>
    }

    let addNewRow = e => {
        let division = selectedUser.DivisionRoleGroups ? selectedUser.DivisionRoleGroups : [];
        division.push({
            "DivisionId": '',
            "DivisionName": '',
            "Groups": [{
                "GroupId": '',
                "GroupName": '',
                "Roles": [{
                    "RoleId": '',
                    "RoleName": ''
                }]
            }]
        });

        //selectedUser.DivisionRoleGroups = division; //Big mistake to assign the divsion directly
        setSelectedUser(selectedUser => {
            return {
                ...selectedUser,
                DivisionRoleGroups: division
            }
        });


    }
    let saveData = e => {
        //validation
        if (selectedUser.DivisionRoleGroups.length <= 0) {
            alert('Please add atleast one record to Division Access section');
            return false;
        }
        if (selectedUser.Email == '') {
           alert('Login Email is mandatory');
           return false;
        }
        if (selectedUser.NotificationEmail == '') {
            alert('Notification Email is mandatory');
            return false;
         }
        if (selectedUser.Email != '') {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(selectedUser.Email)) {
                alert('Login Email format is invalid');
                return false;
            }
        }
        if (selectedUser.NotificationEmail != '') {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(selectedUser.NotificationEmail)) {
                alert('Notification Email format is invalid');
                return false;
            }
        }
        
        if (selectedUser.DivisionRoleGroups.length > 0) {
            var divisionArray = selectedUser.DivisionRoleGroups.map(function (item) {
                return item.DivisionId
            });
            var isDuplicateDivision = divisionArray.some(function (item, idx) {
                return divisionArray.indexOf(item) != idx
            });
            if (isDuplicateDivision) {
                alert('Only one access permission is allowed for one Division');
                return false;
            }
        }

        if (selectedUser.DivisionRoleGroups.length > 0) {
            for (const [k, val] of selectedUser.DivisionRoleGroups.entries()) {
                if (val && val.DivisionId == "") {
                    alert('Invalid Division Access Selection');
                    return false;
                }
            }
        }
        for (const [a, div] of users.entries()) {
            if (selectedUser && selectedUser.Name != div.Name) {
                if (selectedUser.Email && selectedUser.Email != "" && selectedUser.Email == div.Email) {
                    alert('Already another user with Same Login Email Exists in the Systen');
                    return false;
                }
            }
        }

        let array = []

        if (selectedUser.DivisionRoleGroups.length > 0) {
            for (const [a, div] of users.entries()) {
                for (const [b, divGroups] of div.DivisionRoleGroups.entries()) {
                    if (divGroups.Groups[0] && divGroups.Groups[0].Roles[0] &&
                        divGroups.Groups[0].Roles[0].RoleName == 'Final Approver') {
                        if (selectedUser && selectedUser.UserId != div.UserId)
                            array.push(divGroups)
                    }
                    if (divGroups.Groups[0] && divGroups.Groups[0].Roles[0] &&
                        divGroups.Groups[0].Roles[0].RoleName == 'BA Assigner') {
                        if (selectedUser && selectedUser.UserId != div.UserId)
                            array.push(divGroups)
                    }
                }
            }
            if (array && array.length > 0) {
                for (const [c, divSave] of selectedUser.DivisionRoleGroups.entries()) {
                    if (divSave.Groups[0] && divSave.Groups[0].Roles[0] &&
                        divSave.Groups[0].Roles[0].RoleName == 'Final Approver') {
                        for (const [i, val] of array.entries()) {
                            if (val.DivisionId == divSave.DivisionId &&
                                val.Groups[0].Roles[0].RoleName == 'Final Approver') {
                                alert('A Final Approver already exists in the System for Selected Division')
                                return false;
                            }
                        }
                    }
                    if (divSave.Groups[0] && divSave.Groups[0].Roles[0] &&
                        divSave.Groups[0].Roles[0].RoleName == 'BA Assigner') {
                        for (const [i, val] of array.entries()) {
                            if (val.DivisionId == divSave.DivisionId &&
                                val.Groups[0].Roles[0].RoleName == 'BA Assigner') {
                                alert('A BA Assigner already exists in the System for Selected Division')
                                return false;
                            }
                        }
                    }
                }
            }
        }



        let selAssistants = [];
        selectedAssistants.map(assistant => {
            selAssistants.push({
                "AssistantToId": assistant.UserId,
                "AssistantToName": assistant.Name
            });
        });


        let postBody = {
            "UserId": selectedUser.UserId,
            "Name": selectedUser.Name,
            "Email": selectedUser.Email,
            "NotificationEmail": selectedUser.NotificationEmail,
            "Phone": selectedUser.Phone,
            "IsActive": selectedUser.IsActive == 1 ? true : false,
            "DivisionRoleGroups": selectedUser.DivisionRoleGroups,
            "AssistantToList": selAssistants
        }


        DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'User', postBody)
            .then(data => {
                alert("User Details saved successfully");
                props.toggleManageUser();
            },
                err => {
                    alert("User Details save failed");
                });


    }
    let getNewUser = (value) => {
        return {
            "UserId": 0,
            "Name": value,
            "Email": "",
            "NotificationEmail": "",
            "Phone": "",
            "IsActive": true,
            "DivisionRoleGroups": [
                {
                    "DivisionId": '',
                    "DivisionName": '',
                    "Groups": [{
                        "GroupId": '',
                        "GroupName": '',
                        "Roles": [{
                            "RoleId": '',
                            "RoleName": ''
                        }]
                    }]
                }
            ],
            "AssistantToList": [
                {
                    "AssistantToId": null,
                    "AssistantToName": null
                }
            ],
            "IsDefaultApprover": false,
            "Permissions": null
        }
    }

    return (
        <>
            <MDBContainer>
                <MDBRow>
                    <MDBCol size="12" className='text-center' >
                        <label className="mngUserHeader">Manage Users</label>
                    </MDBCol>
                    <MDBCol size="12">
                        <MDBModalBody id="manageUsers">
                            <MDBContainer>

                                <MDBRow>
                                    <MDBCol size="6">
                                        {/* <UserSearch Name = {selectedUser.Name}  onUserChanged = {onUserChanged} ></UserSearch> */}
                                        <Autocomplete autoHighlight size="small" className="tags-standard" freeSolo
                                            value={selectedUser.Name}
                                            //defaultValue={selectedUser}
                                            onChange={(event, val) => {
                                                setSelectedUser(prevState => {
                                                    return {
                                                        ...prevState,
                                                        Name: event.target.value
                                                    }
                                                });
                                                userChange(event, val);
                                            }
                                            }

                                            getOptionLabel={(option) => {
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                return option.Name

                                            }
                                            }
                                            options={users}
                                            loading={loading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="User's Name*"
                                                    placeholder="Search User"
                                                />

                                            )}
                                        />
                                    </MDBCol>
                                    <MDBCol size="6"></MDBCol>
                                </MDBRow>
                            </MDBContainer>
                            <br></br>
                            <MDBContainer >
                                <MDBRow>
                                    <MDBCol>
                                        <label className="labelHeaders">User Details</label>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol size='6'>
                                        <MDBInput label='Login Email Id*' id="txtLoginEmail"
                                            color="primary" autocomplete="new-password"
                                            disabled={userEnabled}
                                            value={selectedUser.Email}
                                            onChange={(event, value) => {
                                                setSelectedUser(selectedUser => {
                                                    return {
                                                        ...selectedUser,
                                                        Email: event.target.value
                                                    }
                                                });

                                            }} />
                                    </MDBCol>
                                    <MDBCol size='6'>
                                        <MDBInput label='Notification Email*' id="txtNotificationEmail"
                                            color="primary" autocomplete="new-password" disabled={userEnabled}
                                            value={selectedUser.NotificationEmail}
                                            onChange={(event, value) => {
                                                setSelectedUser(selectedUser => {
                                                    return {
                                                        ...selectedUser,
                                                        NotificationEmail: event.target.value
                                                    }
                                                });
                                            }}
                                            onFocus={(event) => {
                                                if (selectedUser && selectedUser.NotificationEmail == '')
                                                    setSelectedUser(selectedUser => {
                                                        return {
                                                            ...selectedUser,
                                                            NotificationEmail: selectedUser.Email
                                                        }
                                                    });
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol size='6'>
                                        <MDBInput label='Office Phone*' id="txtPhone" autocomplete="new-password"
                                            color="primary" disabled={userEnabled}
                                            value={selectedUser.Phone}
                                            onChange={(event, value) => {

                                                setSelectedUser(selectedUser => {
                                                    return {
                                                        ...selectedUser,
                                                        Phone: event.target.value
                                                    }
                                                });
                                            }} />
                                    </MDBCol>
                                    <MDBCol size='6' id="status">
                                        <Autocomplete autoHighlight disabled={userEnabled}
                                            className="tags-standard"
                                            options={statusOptions}
                                            getOptionLabel={(option) => option.text}
                                            value={selectedUser.IsActive == 1 ?
                                                statusOptions[0] : statusOptions[1]}
                                            onChange={(event, value) => {
                                                setSelectedUser(selectedUser => {
                                                    return {
                                                        ...selectedUser,
                                                        IsActive: Number(value.value)
                                                    }
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Status"
                                                />
                                            )}
                                        />
                                    </MDBCol>

                                </MDBRow>
                                <MDBRow>
                                    <MDBCol size="12">
                                        <AssistantsTo></AssistantsTo>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <label className="labelHeaders">Division Access</label>
                                    </MDBCol>
                                </MDBRow>
                                {/* <UserAccessDiv userAccess = {selectedUser}></UserAccessDiv>
                                 */}

                                {UserAccessDiv()}
                                {/* <MDBRow>
                                    <MDBCol size='10'>
                                        <UserAccessDropDowns options={1} change={userAccessChange}></UserAccessDropDowns>
                                    </MDBCol>
                                    <MDBCol size='2' className="mngUserIcons">
                                        <CancelIcon style={{ color: blue[900], fontSize: 21 }}></CancelIcon>
                                        <AddBoxIcon style={{ color: blue[900], fontSize: 21 }} />
                                    </MDBCol>
                                </MDBRow> */}
                            </MDBContainer>
                            <MDBContainer>
                                <MDBRow>
                                    <MDBCol size="3"></MDBCol>
                                    <MDBCol size="6">

                                        <MDBBtn onClick={props.toggleManageUser}>Cancel</MDBBtn>
                                        <MDBBtn onClick={saveData}>Save</MDBBtn>
                                    </MDBCol>
                                    <MDBCol size="3"></MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBModalBody>
                        {/* <MDBModalFooter>    */}

                        {/* </MDBModalFooter> */}
                    </MDBCol>

                </MDBRow>
            </MDBContainer>

        </>
    );
}
export default ManageUsersModal;