import React, { useState, useEffect, Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class CompanyAndDetailsView extends Component {

    constructor(props) {
        super(props);
        
        this.state = { formData : this.props.formData}
        ///this.setState(this.props.formData);
    }
    onCompanyChange = (event, value) => {
        
        this.props.onCompanyChange(event, value);

    }

    render() {
        if (this.props.isPropSelected) {
            return (
                <MDBContainer id="repDetails">
                    <MDBRow>
                        <MDBCol size="12">
                            <Autocomplete autoHighlight size="small"
                                id="company"
                                options={this.props.CompaniesData} freeSolo
                                getOptionLabel={(option) => option.CompanyName}
                                value={this.props.companyValue}
                                onChange={this.onCompanyChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Company Name"
                                        placeholder="Company Name"
                                    />)}
                            />
                        </MDBCol>
                        <MDBCol size="12">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" class="custom-control-input" id="chkCC"/>
                                <label class="custom-control-label" for="chkCC">CC</label>
                            </div>
                             {/* checked={this.state.formData.isUSeForPaymentAddress} */}
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" class="custom-control-input" id="ChkPaymentAddress" />
                                <label class="custom-control-label" for="ChkPaymentAddress">Use for Payment Address</label>
                            </div>
                            <br />
                        </MDBCol>
                        <MDBCol size="12">
                            <MDBInput
                                value={this.state.formData.email} id="email" name="email"
                                onChange={(event, value) => {
                                    
                                    this.setState({ email: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Email"
                            />

                        </MDBCol>
                        <MDBCol size="12">
                            <MDBInput name="phoneNumber"
                                value={this.state.formData.phoneNumber}
                                onChange={(event, value) => {
                                    this.setState({ phoneNumber: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                            />

                        </MDBCol>
                        <MDBCol size="12">
                            <MDBInput name="Address1"
                                value={this.state.formData.Address1}
                                onChange={(event, value) => {
                                    this.setState({ Address1: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Office Address"
                            />

                        </MDBCol>
                        <MDBCol size="12">
                            <MDBInput name="addres2"
                                value={this.state.formData.addres2}
                                onChange={(event, value) => {
                                    this.setState({ addres2: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Office Address2"
                            />

                        </MDBCol>
                        <MDBCol size="12">
                            <MDBInput
                                value={this.state.formData.city}
                                onChange={(event, value) => {
                                    this.setState({ city: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="city"
                            />

                        </MDBCol>
                        <MDBCol size="6">
                            <MDBInput
                                value={this.state.formData.State}
                                onChange={(event, value) => {
                                    this.setState({ State: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="State"
                                name="State"
                            />

                        </MDBCol>
                        <MDBCol size="6">
                            <MDBInput
                                value={this.state.formData.Zip}
                                onChange={(event, value) => {
                                    this.setState({ Zip: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Zip" name="Zip"

                            />

                        </MDBCol>
                        <MDBCol size="6">
                            <MDBInput
                                value={this.state.formData.phoneNumber2}
                                onChange={(event, value) => {
                                    this.setState({ phoneNumber2: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="2nd Phone#"
                                name="phoneNumber2"
                            />
                        </MDBCol>
                        <MDBCol size="6">
                            <MDBInput
                                value={this.state.formData.fax}
                                onChange={(event, value) => {
                                    this.setState({ fax: event.target.value });
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Fax"
                                name="fax"
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            )
        } else {
            return ('')
        }
    }
}
export default CompanyAndDetailsView;
