import React, { useState, useEffect, Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class RepresentativeType extends Component {

    constructor(props) {
        super(props);
    }
    onRepresentativeTypeChange = (event, value) => {
        this.props.onRepresentativeTypeChange(event, value);

    }
    render() {
        if (this.props.isRepSelected) {
            return (
                <MDBCol size="12" id="representatieType">
                    <Autocomplete autoHighlight size="small"
                        id="representativeType"
                        options={this.props.options}
                        freeSolo name="representativeType"
                        getOptionLabel={(option) => option.Name}
                        value={this.props.value}
                        onChange={this.onRepresentativeTypeChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Representative Type*"
                                placeholder="Representative Type*" autocomplete="new-password"
                            />)}
                    />
                </MDBCol>
            )
        } else {
            return ('')
        }
    }
}
export default RepresentativeType