
import React from 'react';
import ReactDOM from 'react-dom';
import { dataDivisions, dataGroups, dataRoles } from './sampleData.js';
import { Component } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { MDBSelect, MDBContainer, MDBCol, MDBRow } from 'mdbreact';

let defaultItemDivision = { divisionName: 'Select Division ...' };
let defaultItemGroup = { groupName: 'Select Group ...' };
let defaultItemRole = { roleName: 'Select Role ...' };

class UserAccessDropDowns extends Component {
    constructor(props) {

        super(props);
        this.state = {
            option: this.props.options,
            division: null,
            group: null,
            role: null,
            roles: [],
            groups: [],
            divisions: [],
            selectedDivision: '',
            position: this.props.indexProp,
            hasDivision : false,
            hasGroup : false
        };
    }

    componentWillReceiveProps(nextProps) {
        // let options = nextProps.options;
        // this.setState(prevState => {
        //     return {
        //         divisions: options
        //     }
        // });
        this.propsUpdated(this.props);;
    }
    componentDidMount() {
        this.propsUpdated(this.props);;
    }

    propsUpdated(props)
    {
        let options = props.options;
        this.setState(prevState => {
            return {
                divisions: options
            }
        });
        let selectedDivision = this.props.selectedDivision;
        if (selectedDivision != null) {
            this.setState(prevState => {
                return {                    
                    //divisions: options,
                    selectedDivision: selectedDivision
                }
            });

            let division = (selectedDivision != null ?
                {
                    "DivisionId": selectedDivision.DivisionId,
                    "DivisionName": selectedDivision.DivisionName
                } : null);
            this.setState(prevState => {
                return {
                    division: division
                }
            });

            let group = (selectedDivision != null ?
                {
                    "GroupId": selectedDivision.Groups[0].GroupId,
                    "GroupName": selectedDivision.Groups[0].GroupName
                } : null);
            this.setState(prevState => {
                return {
                    group: group
                }
            });

            let role = (group != null ?
                {
                    "RoleId": selectedDivision.Groups[0].Roles[0].RoleId,
                    "RoleName": selectedDivision.Groups[0].Roles[0].RoleName
                } : null);
            this.setState(prevState => {
                return {
                    role: role
                }
            });

            let groups = this.populateGroups(division, this.props.options, true);
            this.populateRoles(group, groups, true);
        }
    }


    populateGroups(value, allDivisions, isNew) {
        let division = value;
        let groups = [];
        allDivisions.map(division => {
            if (division.DivisionId === value.DivisionId)
                groups = division.Groups;
        });
        this.setState(prevState => {
            return {
                division: division,
                group: isNew ? prevState.group : '',
                role : isNew ? prevState.role : '',
                groups: groups,
                roles : []
            }
        });
       
        if(division)
            this.setState(prevState => {
                return {
                    hasDivision: true
                }
            });

        return groups;
    }

    populateRoles(value, allGroups, isNew) {
        let group = value;
        let roles = [];// this.option.filter(role => role.groupId === group.groupId);
        allGroups.map(group => {
            if (group.GroupId === value.GroupId)
                roles = group.Roles;
        });
        this.setState(prevState => {
            return {
                group: group,
                role : isNew ? prevState.role : '',
                roles: roles
            }
        });
        if(group)
        this.setState(prevState => {
            return {
                hasGroup: true
            }
        });
    }

    divisionChange = (event, value) => {

        this.populateGroups(value, this.state.divisions,false);
        defaultItemGroup = []
        defaultItemRole = []
    }

    groupChange = (event, value) => {
        this.populateRoles(value, this.state.groups, false);
        defaultItemRole = []
    }

    roleChange = (event, value) => {
        this.setState({ role: value });
        this.props.change(this.state, value, this.props.indexProp)
    }

    render() {
        const division = this.state.division;
        const group = this.state.group;
        const role = this.state.role;

        
        return (
            <MDBContainer>
                <MDBRow>
                    <MDBCol col='4'>
                        <Autocomplete autoHighlight size="small" autoHighlight
                            className="tags-standard"
                            options={this.state.divisions}
                            getOptionLabel={(option) => option.DivisionName}
                            defaultValue={defaultItemDivision ? defaultItemDivision : []}
                            value={this.state.division}
                            onChange={this.divisionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Division"
                                />
                            )}
                        />

                    </MDBCol>
                    <MDBCol col='4' disabled={!this.state.hasDivision}>
                        <Autocomplete autoHighlight size="small" autoHighlight
                            className="tags-standard" 
                            options={this.state.groups}
                            getOptionLabel={(option) => option.GroupName}
                            defaultValue={defaultItemGroup ? defaultItemGroup : []}
                            value={this.state.group}
                            onChange={this.groupChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Group"
                                />
                            )}
                        />
                    </MDBCol>
                    <MDBCol col='4' disabled={!this.state.hasGroup}>
                        <Autocomplete autoHighlight size="small" autoHighlight
                            className="tags-standard" 
                            options={this.state.roles}
                            getOptionLabel={(option) => option.RoleName}
                            defaultValue={defaultItemRole ? defaultItemRole : []}
                            value={this.state.role}
                            onChange={this.roleChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Role"
                                />
                            )}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default UserAccessDropDowns;