import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import DealMemoLandingPage from './pages/DealMemoLandingPage';
import DealRequestForm from './pages/DealRequestForm';
import DealRequestBAAssignmentPage from './pages/DealRequestBAAssignmentPage';
import ManageUsersModal from './pages/Modal/ManageUsersModal';
import CreateNewDealForm from './pages/CreateNewDealForm';
import DealReport from './pages/DealReport';
import PageNotFound from './components/PageElements/PageNotFound';
import { withOktaAuth } from '@okta/okta-react';
import ManageTalents from './pages/ManageTalents';
export default withOktaAuth(class Routes extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    return (

      <Switch>
        {/* <Route path='/dealRequestForm'  component={DealRequestForm} /> */}
        {/* <Route   path='/dealrequestform/:dealId'  component={CreateNewDealForm} />         */}
        <Route path='/dealrequestform' component={CreateNewDealForm} />
        <Route exact={true} path='/' render={() => { return (<Redirect to="/dashboard" />) }} />
        <Route exact={true} path='/dashboard' component={DealMemoLandingPage} />
        <Route exact={true} path='/dashboard' component={DealMemoLandingPage} />
        <Route path='/baassignment' component={DealRequestBAAssignmentPage} />
        <Route path='/dealReport' component={DealReport} />
        <Route exact={true} path='/ManageUsers' component={ManageUsersModal} />
        <Route exact={true} path='/ManageTalents' component={ManageTalents} />
        <Route
          render={function () {
            return <PageNotFound />;
          }}
        />

      </Switch>
    );
  }
}
);
//export default Routes;
