import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import MainContent from '../components/PageElements/MainContent';
import HeaderContent from '../components/PageElements/HeaderContent';
//import FooterContent from '../components/PageElements/FooterContent';
import ErrorPage from '../components/ErrorPage';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "../index.css";
import { withOktaAuth } from '@okta/okta-react';
import DealerService from '../services/service';
import * as Constants from '../constants/constants';
import * as appConstants from '../constants/appConstants';
export default withOktaAuth(class Home extends Component {

  constructor(props) {
    super(props);
    // console.log(this.props.authState.isAuthenticated);
    // console.log("Access Token : " + this.props.authState.accessToken);
    // console.log("Id Token : " + this.props.authState.idToken);
    if (this.props.authState.accessToken == undefined)
      this.props.authService.logout('/');

    this.state = {
      isValidUser: true,
      errorId: 0,
      errorDesc: '',
      isAdmin: false,
      isCreate: false,
      isFinalApprover: false
    }


  }
  async componentDidMount() {
    try {
      this._isMounted = true;
      await this.props.authService.getUser()
        .then(response => {
          let name = response.name
          let email = response.email;

          //console.log("User Info :" + JSON.stringify(response));
          localStorage.setItem('user_email', email);
          localStorage.setItem('user_name', name);

          DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
              if (this._isMounted) {
                //console.log("User Profile -->" + JSON.stringify(response));
                if (response.data && response.data.length > 0) {
                  let userProfile = response.data[0];
                  //if(userprofile )
                  let userId = userProfile.UserId;
                  localStorage.setItem('user_id', userId);
                  localStorage.setItem('user_profile', JSON.stringify(userProfile));
                  this.setState({
                    isValidUser: true
                  });
                  this.processPermissions(userProfile);

                }
                else {
                  this.setState({
                    isValidUser: false,
                    errorId: '401',
                    errorDesc: 'User is not available in the system or marked as inactive. Please contact admin for Dealer application'
                  });
                }
              }
            },
              (err) => {
                console.log('Service Error : Log In -> Getting User Profile ' + err);
                this.setState({
                  isValidUser: false,
                  errorId: '503',
                  errorDesc: 'User profile service is not available. Please try again and contact admin for Dealer application'
                });
              })

        },
          (err) => {
            console.log(err);
            this.setState({
              isValidUser: false,
              errorId: '503',
              errorDesc: 'OKTA user profile service is not available. Please try again and contact admin for Dealer application'
            });
          });
    }
    catch (err) { }
  }
  processPermissions(userProfile) {

    let permissions = appConstants.PERMISSIONS;
    let permissionObjs = [];
    if (userProfile) {

      let isAdmin = false;
      let isCreate = false;
      let isFinalApprover = false;
      debugger;
      userProfile.DivisionRoleGroups.forEach((division) => {
        let permissionArr = division.Groups[0].Roles[0].Permissions;
        let permissionObj = {
          "DivisionId": "",
          "DivisionName": ""
        };
        permissions.forEach((permission) => {
          permissionObj[permission] = false;
        });
        let permObj = permissionObj;
        permObj["DivisionId"] = division.DivisionId;
        permObj["DivisionName"] = division.DivisionName;
        permissionArr.forEach((permission) => {
          permObj[permission.PermissionName] = true;
        });

        if (division.Groups[0].GroupName === "ADMIN" && !isAdmin) {
          isAdmin = true;
        }
        // if (division.Groups[0].GroupName === "Creative" && division.Groups[0].Roles[0].RoleName === "Final Approver") {
        //   isFinalApprover = true;
        // }
        if (userProfile.IsFinalApprover) {
          isFinalApprover = true
        }
        if (userProfile.Permissions && userProfile.Permissions["Create/Modify/Submit Request"]) {
          isCreate = true;
        }
        permissionObjs.push(permObj);
      });
      localStorage.setItem("permissions", permissionObjs);

      this.setState({
        isAdmin: isAdmin,
        isCreate: isCreate,
        isFinalApprover: isFinalApprover
      });
    }

  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      !this.state.isValidUser ? <ErrorPage isLoggedIn={this.state.isValidUser} errorId={this.state.errorId} errorDesc={this.state.errorDesc} ></ErrorPage> :

        <>
          <MDBContainer >
            <HeaderContent isLoggedIn={this.state.isValidUser} authState={this.props.authState} />

          </MDBContainer>
          <MDBContainer>
            <MainContent isAdmin={this.state.isAdmin}
              isCreate={this.state.isCreate} isFinalApprover={this.state.isFinalApprover} authState={this.props.authState} />
          </MDBContainer>
        </>
    );
  }
}
);
