import React, { useState, useEffect } from "react";
import { MDBModalBody, MDBCol, MDBSelect, MDBModalHeader, MDBModalFooter, MDBInput, MDBBtn, MDBContainer, MDBRow } from 'mdbreact';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/Form.css';
import { useHistory, withRouter, Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DealRequestForm from '../DealRequestForm';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as Constants from '../../constants/constants';
import DealerService from '../../services/service';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    paddingTop: 10,
    width: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function DealReuestFormModal(props) {

  const classes = useStyles();

  const [state1, setState] = useState({
    division: '',
    position: '',
    positionArray: [],
    divisionArray: [],
    divisionId: 0,
    positionId: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      const positionresult = await DealerService.getData(
        Constants.DealerSharedServiceBaseUrl + 'LookupEntities?entity=Position',
      );
      const divisionResult = await DealerService.getData(
        //Constants.DealerSharedServiceBaseUrl + 'LookupEntities?entity=Division',
        Constants.DealerSharedServiceBaseUrl + 'Divisions?actionName=CreateDeal',
      );

      setState({
        ...state1,
        positionArray: positionresult.data.map(a => ({ 'text': a.Name, 'value': a.Id })),
        divisionArray: divisionResult.data.map(a => ({ 'text': a.Name, 'value': a.Id })),
      });
    };
    fetchData();
  }, []);

  let history = useHistory();

  let openNewDealForm = e => {

    if (state1.position != '' && state1.divisionId != 0) {
      props.toggle();
      localStorage.setItem('position', state1.position);
      localStorage.setItem('positionId', state1.positionId);
      localStorage.setItem('divisionId', state1.divisionId);
      history.push('/dealrequestform');
      window.location.reload(false);
    }
  }


  function RenderOptions(props) {
    console.log('props for RenderOption', props.items);

    return props.items.map((dt, i) => {
      //  console.log(dt.name);
      return (
        <option
          value={dt.name}
          key={dt.id} name={dt.name}>{dt.name}
        </option>
      );
    });
  }
  const setSelectedPosition = (t) => {

    // if (t) {
    //   let obj = state1.positionArray.find(o => o.value == t[0]);
    //   if (obj) {
    state1.position = t.text;
    state1.positionId = t.value;
    // }
    //}
  }
  const setSelectedDivision = (t) => {
    if (t) {
      state1.divisionId = t.value;
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    console.log('selected for', event.target.value, event.target.name);
    setState({
      ...state1,
      [name]: event.target.value
    });
    console.log('state updated', state1);
  };
  return (
    <>
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12">
            <MDBModalBody>
              <MDBContainer>
                <MDBContainer className="sectionContainer">
                  <MDBRow>
                    <MDBCol>
                      <label className="labelHeaders"> Create a New Deal Request</label>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                <MDBContainer className="sectionContainer">
                  <MDBRow>
                    <MDBCol size="12">
                      <Autocomplete size="small"
                        id="position" autoHighlight
                        options={state1.positionArray}
                        freeSolo
                        getOptionLabel={(option) => option.text}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedPosition(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Position"
                            placeholder="Position"
                          />)}
                      />
                    </MDBCol>
                    <br></br>
                    <MDBCol size="12">
                      <Autocomplete size="small"
                        id="division" autoHighlight
                        options={state1.divisionArray}
                        freeSolo
                        getOptionLabel={(option) => option.text}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedDivision(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Division"
                            placeholder="Division"
                          />)}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                <MDBContainer className="sectionContainer">
                  <MDBRow >
                    <MDBCol size="6" className="alignLeft">
                      <MDBBtn size="sm" onClick={openNewDealForm}>Submit</MDBBtn>
                    </MDBCol>
                    <MDBCol size="6" className="alignLeft">
                      <MDBBtn size="sm" onClick={props.toggle}>Cancel</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBContainer>
            </MDBModalBody>
            {/* <MDBModalFooter>
              <MDBBtn onClick={openNewDealForm}>Submit</MDBBtn>
              <MDBBtn onClick={props.toggle}>Cancel</MDBBtn>
            </MDBModalFooter> */}
          </MDBCol>
          {/* <MDBCol size="12">
            <MDBModalFooter>
              <MDBBtn onClick={openNewDealForm}>Submit</MDBBtn>
              <MDBBtn onClick={props.toggle}>Cancel</MDBBtn>
            </MDBModalFooter>
          </MDBCol> */}
        </MDBRow>
      </MDBContainer>
    </>
  )
}

export default DealReuestFormModal;