import React, { useState, useEffect, Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class Representative extends Component {

    constructor(props) {
        super(props);
    }
    onRepresentativeChange = (event, value) => {
        this.props.onRepresentativeChange(event, value);

    }
    render() {
        return <>
            <Autocomplete autoHighlight size="small" name="representative"
                id="representative"
                options={this.props.RepresentativeData}
                freeSolo disabled={this.props.isCreate}
                getOptionLabel={(option) => option.RepresentativeName}
                value={this.props.value}
                onChange={this.props.onRepresentativeChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Representative"
                        placeholder="Representative"
                    />)}
            />
        </>
    }
}
export default Representative