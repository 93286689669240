import * as Constants  from './constants/constants';
import { AuthService } from '@okta/okta-react';
import { createBrowserHistory } from 'history'
import { __esModule } from '@okta/okta-react/dist/Security';
 
const history = createBrowserHistory();
// export default {
//     url: 'https://tw.oktapreview.com',
//     issuer: 'https://tw.oktapreview.com/oauth2/default',
//     redirect_uri: window.location.origin + '/callback',
//     client_id: '0oari4rcvuJZM49ZB0h7',   
//     //grant_type: 'implicit' 
//     //response_type: 'token'
//   };

const authService = new AuthService({
  history,
  issuer: Constants.OKTA_ISSUER_URL,
  clientId: Constants.OKTA_CLIENT_ID,
  redirectUri: Constants.OKTA_REDIRECT_URL,
  url: Constants.OKTA_URL
});

export default authService;