import React from "react";
import { MDBSpinner, MDBModalBody, MDBModal } from 'mdbreact';

const PageSpinner = (props) => {
  return (
    <>
    <MDBModal id="pageSpinner" isOpen={props.pageSpinner} className = "modal-dialog-loading" >
      <MDBModalBody size="fluid" className = "transparent-modal-dialog">         
        <div className = "alignCenter" >
        <div>
          <MDBSpinner crazy />
        </div>
        <div>
          <label>{props.message}</label>
        </div>
        </div>
      </MDBModalBody>
    </MDBModal>
    </>
  );
}

export default PageSpinner;