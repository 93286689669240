import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useHistory, withRouter, Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import DemoRequestFormModal from '../../pages/Modal/DealRequestFormModal';
import ManageUsersModal from '../../pages/Modal/ManageUsersModal';
import ManageProjects from '../../pages/Modal/ManageProjects';
import { MDBSideNavCat, MDBSideNavNav, MDBSideNav, MDBSideNavLink, MDBModal, MDBContainer, MDBIcon, MDBBtn } from "mdbreact";
import AddCircle from '@material-ui/icons/AddCircle';
import VideocamIcon from '@material-ui/icons/Videocam';
import Home from '@material-ui/icons/Home';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import PersonIcon from '@material-ui/icons/Person';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Component } from "react";
// import { MDBTooltip } from "mdbreact";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 280,
        minWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        position: 'absolute',
    },
}))(Tooltip);

class SideNav extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        console.log("SideNav loaded");
        this.state = {
            isModal: false,
            isManageUserModal: false,
            isManageProjectsModal: false,
            isManageTalents: false,
            sideNavLeft: false,
            isSlim: true,
            toggleStateA: true,
            userProfile: JSON.parse(localStorage.getItem("user_profile")),
            isAdmin: false,
            isCreate: false,
            isFinalApprover: false,
            permissions: localStorage.getItem("permissions")
        }
        console.log("User Profile : " + JSON.parse(localStorage.getItem("user_profile")));
        this.childRef = React.createRef();
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {

            this.setState({
                isAdmin: nextProps.isAdmin,
                isCreate: nextProps.isCreate,
                isFinalApprover: nextProps.isFinalApprover

            });
        }
    }
    toggle = () => {
        //this.setModal(!this.isModal);
        this.setState({
            isModal: !this.state.isModal
        });
    };
    toggleManageUser = () => {
        debugger
        this.setState({
            isManageUserModal: !this.state.isManageUserModal
        });
    }

    toggleManageProjects = () => {
        debugger
        this.setState({
            isManageProjectsModal: !this.state.isManageProjectsModal
        });
    }
    toggleManageTalent = () => {
        debugger
        this.setState({
            isManageTalents: !this.state.isManageTalents
        });
    }

    // setModal(isModal) {
    //     this.setState({ isModal: isModal });
    //     this.isModal = isModal;
    // }
    handleSideNavLeft = () => {
        this.setState(
            {
                sideNavLeft: !this.state.sideNavLeft,
                slim: !this.state.isSlim
            });
        this.props.toggleSideNav();
    }




    render() {
        const navStyle = { paddingLeft: this.state.windowWidth > this.state.breakWidth ? "210px" : "16px" };

        return (
            <>
                <MDBSideNav ref={this.childRef}
                    showOverlay={false}
                    triggerOpening={this.state.sideNavLeft}
                    fixed
                    right={false}
                    // onClick={() => this.setState({ homeRedirect: true })}
                    className={!this.state.sideNavLeft ? "slim side-nav-override" : "side-nav-override"}
                >
                    <MDBSideNavNav className="slim-items">

                        <MDBSideNavLink topLevel>
                            <div onClick={this.handleSideNavLeft}>
                                <MDBIcon style={{ fontSize: 17 }} icon={!this.state.sideNavLeft ? "angle-double-right" : "angle-double-left"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Minimize menu
                            </div >

                        </MDBSideNavLink>
                        <hr className="solid" />

                        <MDBSideNavLink to="/" topLevel >
                            <Tooltip title="Dealer Dashboard" placement="right-start">
                                <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                    <Home style={{ fontSize: 20 }} color="inherit" /> &nbsp; &nbsp;Dealer Dashboard
                            </span>
                            </Tooltip>
                        </MDBSideNavLink>


                        <MDBSideNavLink onClick={this.toggle.bind(this)} topLevel
                            className={!this.state.isCreate ? "d-none" : "d-block"}>
                            <Tooltip title="Create Deal Request" placement="right-start">
                                <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                    <AddCircle style={{ fontSize: 20 }} color="inherit" /> &nbsp; &nbsp;Create Deal Request
                            </span>
                            </Tooltip>
                        </MDBSideNavLink>
                        <MDBSideNavLink className={!this.state.isFinalApprover ? "d-none" : "d-block"}
                            to='/dealReport' topLevel>
                            <Tooltip title="Reports" placement="right-start">
                                <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                    <FileCopyIcon style={{ fontSize: 20 }} color="inherit" /> &nbsp;&nbsp; Reports
                        </span>
                            </Tooltip>
                        </MDBSideNavLink>
                        <br></br>
                        <hr className="solid" />
                        <MDBSideNavLink className={!this.state.isAdmin ? "d-none" : "d-block"}
                            onClick={this.toggleManageUser.bind(this)} topLevel>
                            <Tooltip title="Manage Users" placement="right-start">
                                <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                    <PersonIcon style={{ fontSize: 20 }} color="inherit" /> &nbsp;&nbsp;
                                Manage Users
                        </span>
                            </Tooltip>
                        </MDBSideNavLink>
                        {/* <MDBSideNavLink className={!this.state.isAdmin ? "d-none" : "d-block"} topLevel>
                            <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                <AccountTreeIcon style={{ fontSize: 20 }} color="inherit" /> &nbsp;&nbsp; Manage Divisions
                        </span>
                        </MDBSideNavLink> */}
                        <MDBSideNavLink className={!this.state.isAdmin ? "d-none" : "d-block"}
                            onClick={this.toggleManageProjects.bind(this)} topLevel>
                            <Tooltip title="Manage Projects" placement="right-start">
                                <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                    <VideocamIcon style={{ fontSize: 20 }} color="inherit" /> &nbsp;&nbsp;
                                Manage Projects
                        </span>
                            </Tooltip>
                        </MDBSideNavLink>
                        <MDBSideNavLink className={!this.state.isAdmin ? "d-none" : "d-block"}
                            to='/ManageTalents' topLevel>
                            <Tooltip title="Manage Talents" placement="right-start">
                                <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                    <SupervisedUserCircle style={{ fontSize: 20 }} color="inherit" />
                                &nbsp;&nbsp; Manage Talents
                        </span>
                            </Tooltip>
                        </MDBSideNavLink>

                    </MDBSideNavNav>
                </MDBSideNav>
                <MDBModal isOpen={this.state.isModal} size="sm">
                    <DemoRequestFormModal toggle={this.toggle} />
                </MDBModal>
                <MDBModal isOpen={this.state.isManageUserModal} size="lg" id="manageUsers">
                    <ManageUsersModal toggleManageUser={this.toggleManageUser} />
                </MDBModal>
                <MDBModal isOpen={this.state.isManageProjectsModal} size="md" >
                    <ManageProjects toggleManageProjects={this.toggleManageProjects} />
                </MDBModal>

            </>
        );
    }
}

export default SideNav;











