import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDataTableV5, MDBInput } from 'mdbreact';



function Fee(props) {
    let handleChange = (event) => {
        props.onChange(event.target.value);
    }
    return (
        <MDBInput label="Fee $" value={props.value}
            className={props.className}
            onChange={handleChange} disabled={props.disabled}
            id="txtFee" color="primary" />
    )

}

export default Fee;

