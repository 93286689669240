import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./pages/App";
import ErrorBoundary from "./pages/ErrorBoundary";

//import { createBrowserHistory } from 'history';

// //This code is used to be able to OKTA redirect correctly and accessing the routing links directly
// const history = createBrowserHistory();

// var hash = window.location.hash;
// const newHashpath = hash.replace("#!/",""); //(/#!(\/.*)$/.exec(window.location.pathname) || [])[1];
// if (newHashpath) {
//    // history.replace(path);
//    window.history.pushState({},'',newHashpath);
// }

ReactDOM.render(
    <ErrorBoundary>
        <Router> 
            <App />
        </Router>
    </ErrorBoundary>,
     document.getElementById('root'));
