import React, { useState, useEffect, Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class DisassociateCompany extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return <>
            <MDBModal isOpen={this.props.isOpen} size='lg' >
                <MDBModalHeader>
                    Delete Company Representation</MDBModalHeader>
                <MDBModalBody>
                    <MDBInput value="1"
                        label="Do you want to PERMANENTLY disassociate the Company from this Representative?"
                        type="radio" onChange={this.props.onCompanyDelete}
                        checked={this.props.checkedProp == "1" ? true : false}
                        id="1" />
                    <MDBInput value="2"
                        label="Do you want to disassociate the Company from this Representative for this Deal ONLY? "
                        type="radio" onChange={this.props.onCompanyDelete}
                        checked={this.props.checkedProp == "2" ? true : false}
                        id="2" />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" onClick={this.props.confirmRepCompanyDeletion}>Confirm</MDBBtn>
                    <MDBBtn size="sm" onClick={this.props.toggleDeleteCompany}>Cancel</MDBBtn>
                </MDBModalFooter>
            </MDBModal >

        </>
    }
}
export default DisassociateCompany;