import React, { useState, useEffect, Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class AddEditRecipient extends Component {

    constructor(props) {
        super(props);
    }
    RecipientChange = (event, value) => {
        this.props.repChange(event, value);
    }
    Actionchange = (event, value) => {
        this.props.actionChange(event, value);

    }
    sequenceChange = (event, value) => {
        this.props.sequenceChange(event, value);

    }
    saveRecipient = () => {
        this.props.saveRecipient();
    }
    toggle = () => {
        this.props.toggle();
    }
    render() {
        return <>
            <MDBModal isOpen={this.props.isOpen} toggle={this.props.isOpen} size='sm' >
                <MDBModalHeader
                // toggle={() => setModal(!distributionAddModal.isOpen)}
                >
                    Add/Edit Recipient</MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol col="4" >
                                <Autocomplete autoHighlight
                                    options={this.props.AddReps}
                                    getOptionLabel={(option) => option.RecipientName}
                                    defaultValue={this.props.recipientValue}
                                    onChange={this.RecipientChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Recipient*"
                                            placeholder="Recipient*"
                                        />
                                    )}
                                    disabled={this.props.mode == 'E' ? true : false}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="4">
                                <Autocomplete autoHighlight
                                    options={this.props.actionOptions}
                                    defaultValue={this.props.actionValue}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.Actionchange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Action*"
                                            placeholder="Action*"
                                        />
                                    )}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="4">
                                <Autocomplete autoHighlight
                                    options={this.props.seqOptions}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={this.props.seqValue}
                                    onChange={this.sequenceChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Sequence*"
                                            placeholder="Sequence*"
                                        />
                                    )}
                                    disabled={this.props.seqDisabled}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size="sm" onClick={this.saveRecipient}>Save</MDBBtn>
                    <MDBBtn size="sm" onClick={this.toggle}>Cancel</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

        </>
    }
}
export default AddEditRecipient;