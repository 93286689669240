import React, { useState, useEffect } from "react";
import {
    MDBModalBody, MDBCol, MDBSelec, MDBModalHeader, MDBModalFooter,
    MDBInput, MDBBtn, MDBContainer, MDBRow, MDBSelect
} from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dealService from '../../services/dealCreationService/dealService';
import TextField from '@material-ui/core/TextField';
import * as Constants from '../../constants/constants'
import DealerService from '../../services/service';

function ManageProjects(props) {
    const [projectsData, setprojectsData] = React.useState([]);
    const [projectDetails, setProjectDetails] = useState({
        ProjectId: 0,
        Name: '',
        Alias: '',
        create: true
    });

    useEffect(() => {
        const fetchData = (newDeal) => {
            dealService.fetchEntityDetails('Project')
                .then((response) => {
                    if (response.data && response.data.length > 0)
                        setprojectsData(response.data)
                });
        }
        fetchData();
    }, []);
    let handleProjectTitleChange = (event, value) => {
        if (value === null) {
            setProjectDetails(projectDetails => {
                return {
                    ...projectDetails,
                    ProjectId: 0,
                    Name: '',
                    Alias: '',
                    create: true
                }
            });
        }
        if (typeof value === 'string') {
            if (value != '') {
                setProjectDetails(projectDetails => {
                    return {
                        ...projectDetails,
                        ProjectId: 0,
                        Name: value,
                        Alias: '',
                        create: true
                    }
                });
            }
        }
        else {
            if (value) {
                setProjectDetails(projectDetails => {
                    return {
                        ...projectDetails,
                        ProjectId: value.ProjectId,
                        Name: value.Name,
                        Alias: value.Alias,
                        create: false
                    }
                });
            }
        }
    }
    let handleAliasChange = (event) => {
        let value = event && event.target ? event.target.value : ''
        setProjectDetails(projectDetails => {
            return {
                ...projectDetails,
                Alias: value
            }
        });
    }
    const saveProject = () => {
        if (projectDetails.Name == '') {
            alert('Select a Project Name');
            return false;
        }
        let postBody = {
            "Id": projectDetails && projectDetails.ProjectId != 0 ? projectDetails.ProjectId : 0,
            "Name": projectDetails.Name.toUpperCase(),
            "Alias": projectDetails.Alias,
        }
        DealerService.postData(Constants.DealerSharedServiceBaseUrl + 'Project', postBody)
            .then(response => response)
            .then(data => {
                DealerService.fetchData(Constants.DealerSharedServiceBaseUrl + 'Project')
                    .then(response => response.json())
                    .then(data => {
                        alert('Project updated successfully');
                    })
            });
    }
    return (
        <>
            <MDBContainer>
                <MDBRow>
                    <MDBCol size="12">
                        <MDBModalBody>
                            <MDBContainer>
                                <MDBContainer className="sectionContainer">
                                    <MDBRow>
                                        <MDBCol>
                                            <label className="labelHeaders"> Projects</label>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                                <MDBContainer className="sectionContainer">
                                    <MDBRow>
                                        <MDBCol size="12">
                                            <Autocomplete limitTags={2} size="small"
                                                id="projectTitle" autoHighlight
                                                options={projectsData.length > 0 ? projectsData : []} freeSolo filterSelectedOptions
                                                getOptionLabel={(option) => option.Name}
                                                value={props.value}
                                                onChange={handleProjectTitleChange}
                                                value={projectDetails.Name != '' ? projectDetails : []}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard" autoFocus
                                                        label="Project Title*" className="txtProjectFocus"
                                                    />)}
                                            />
                                        </MDBCol>
                                        <br></br>
                                        <MDBCol size="12">
                                            <TextField label="Alias" id="alias"
                                                onChange={handleAliasChange}
                                                value={projectDetails.Alias} />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                                <MDBContainer className="sectionContainer">
                                    <MDBRow >
                                        <MDBCol size="6" className="alignLeft">
                                            <MDBBtn size="sm" onClick={saveProject} >
                                                {projectDetails.create ? 'Create' : 'Update'}</MDBBtn>
                                        </MDBCol>
                                        <MDBCol size="6" className="alignLeft">
                                            <MDBBtn size="sm" onClick={props.toggleManageProjects} >Cancel</MDBBtn>
                                        </MDBCol>
                                    </MDBRow>

                                </MDBContainer>
                            </MDBContainer>
                        </MDBModalBody>
                    </MDBCol>

                </MDBRow>
            </MDBContainer>

        </>
    );
}
export default ManageProjects;