import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDataTableV5 } from 'mdbreact';



function DealCreationEmail(props) {
    let handleChange = (event) => {
        let emailValue = event.target.value;
        props.onChange(event.target.value);
    }
    return (
            <MDBCol>
                <label className="labelHeaders">Email Notes</label>
                <textarea
                    className="form-control" onChange={handleChange}
                    id="emailNote"
                    rows="3"
                    value={props.value}
                    disabled = {props.disabled}
                />
            </MDBCol>
    )

}

export default DealCreationEmail;

