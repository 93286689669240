import React, { useRef, useState, useEffect } from 'react';
import TextBox from '../components/FormElements/TextBox';
import SelectBox from '../components/FormElements/SelectBox';
import { MDBDatePicker } from 'mdbreact';
import authService from "../app.config"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useHistory, withRouter } from "react-router-dom";
import { MDBDatePickerV5 } from 'mdbreact';
import moment from 'moment';
import { JsonToExcel } from 'react-json-excel';
import exportFromJSON from 'export-from-json'
import {
  MDBContainer, MDBDataTableV5, MDBCol, MDBRow,
  MDBBtn, MDBSpinner, MDBInput, MDBModal, MDBSelect, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBInputGroup
} from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import dealService from '../services/dealCreationService/dealService';
import DealerService from '../services/service';
import * as Constants from '../constants/constants';
const useStyles = makeStyles((theme) => ({

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function DealReport(props) {
  let history = useHistory();
  const classes = useStyles();

  const [approverDatesSelection, setApproverDatesSelection] = React.useState(false);
  const [dataSource, setdataSource] = React.useState([]);
  const [reportFilter, setreportFilter] = useState({
    projectInfo: [],
    projectSelected: [],
    statusInfo: [],
    statusSelected: [],
    talentInfo: [],
    selectedTalents: [],
    divisionInfo: [],
    divisionSelected: [],
    baexecInfo: [],
    selectedBAExec: [],
    fromDate: '',
    todate: '',
    submitterComments: '',
    finalDataSource: []
  });
  useEffect(() => {
    fetchInputs();
  }, [])
  const fetchInputs = () => {
    dealService.fetchEntityDetails('Talent')
      .then((response) => {
        setreportFilter(reportFilter => {
          return {
            ...reportFilter,
            talentInfo: response.data.map(talents => ({
              'TalentName': talents.TalentName,
              'TalentId': talents.TalentId
            })),
          }
        });
      });
    dealService.fetchEntityDetails('Project')
      .then((response) => {
        debugger;
        setreportFilter(reportFilter => {
          return {
            ...reportFilter,
            projectInfo: response.data.map(project => ({
              'TitleName': project.Name,
              'TitleId': project.ProjectId
            })),
          }
        });
      });
    DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'Divisions?actionName=ExcelReport')
      .then((response) => {
        setreportFilter(reportFilter => {
          return {
            ...reportFilter,
            divisionInfo: response.data.map(division => ({
              'DivisionName': division.Name,
              'DivisionId': division.Id
            })),
          }
        });
      },
        err => {
          console.log("Division : " + err);
        });

    // dealService.fetchEntityDetails('Divisions')
    //   .then((response) => {
    //     setreportFilter(reportFilter => {
    //       return {
    //         ...reportFilter,
    //         divisionInfo: response.data.map(division => ({
    //           'DivisionName': division.Name,
    //           'DivisionId': division.Id
    //         })),
    //       }
    //     });
    //   });

    dealService.fetchLookupEntities('Status')
      .then((response) => {
        debugger;
        setreportFilter(reportFilter => {
          return {
            ...reportFilter,
            statusInfo: response.data.map(status => ({
              'StatusName': status.Name,
              'StatusId': status.Id
            })),
          }
        });
      });

    DealerService.getData(Constants.DealerSharedServiceBaseUrl + 'BAExecutive?dealId='
      + 0)
      .then((response) => {
        setreportFilter(reportFilter => {
          return {
            ...reportFilter,
            baexecInfo: response.data.map(baExec => ({
              'BAExecutiveName': baExec.Name,
              'BAExecutiveId': baExec.UserId
            })),
          }
        });
      },
        err => {
          console.log("BA Assignment : " + err);
        });


  }
  const fromDateChange = (val) => {
    reportFilter.fromDate = val
    setreportFilter(reportFilter => {
      return {
        ...reportFilter,
        fromDate: val
      }
    });
  }
  const finalSave = (data) => {
    let exportType = 'csv';
    let fileName = 'Deal Status Report';
    exportFromJSON({ data, fileName, exportType })
  }
  const save = () => {
    debugger;
    if (approverDatesSelection) {
      if (reportFilter.fromDate == '' || reportFilter.todate == '') {
        alert('From and To dates are mandatory for custom search');
        return false;
      }
    }
    let finalArray = {
      "AllDates": !approverDatesSelection,
      "FinalApprovedDateFrom": approverDatesSelection ? reportFilter.fromDate : null,
      "FinalApprovedDateTo": approverDatesSelection ? reportFilter.todate : null,
      "Titles": reportFilter.projectSelected,
      "Talents": reportFilter.selectedTalents,
      "Status": reportFilter.statusSelected,
      "Divisions": reportFilter.divisionSelected,
      "BAExecutives": reportFilter.selectedBAExec,
      "Positions": [],
      "Notes": reportFilter.submitterComments
    }
    if (finalArray) {
      DealerService.postData(Constants.DealerServiceBaseUrl + 'DealExcelReport ', finalArray)
        .then(res => {
          // do good things
          if (res.data && res.data.length > 0) {
            finalSave(res.data)
          }
          else {
            finalSave([{ 'Message': 'No data exists for the selected Criteria' }])
          }
        })
        .catch(err => {
        });
    }
  }

  const toDateChange = (val) => {
    reportFilter.todate = val
    setreportFilter(reportFilter => {
      return {
        ...reportFilter,
        todate: val
      }
    });
  }
  function ApproveDates(props) {
    console.log('ApproveDates...', props);
    if (props.showDates == true) {
      return (
        <>
          <label className="appDate">Approved Dates</label>
        &nbsp;&nbsp;
          <TextField
            id="date"
            label="From*"
            type="date"
            className={classes.textField}
            value={reportFilter.fromDate}
            onChange={(event, value) => {
              fromDateChange(event.target.value)
            }}
            InputLabelProps={{
              shrink: true,
            }} />
          <TextField
            id="date"
            label="To*"
            type="date"
            InputProps={{ inputProps: { min: reportFilter.fromDate != '' ? reportFilter.fromDate : '' } }}
            value={reportFilter.todate}
            onChange={(event, value) => {
              toDateChange(event.target.value)
            }}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </>
      )
    } else {
      return (null);
    }
  }


  var today = moment().format('YYYY-MM-DD');

  const onCancel = () => {
    history.push('/dashboard');
  }

  const dateViewChange = (event) => {
    console.log('dateViewChange...', event.target.value);

    if (event.target.value == "custom") {
      setApproverDatesSelection(true)
    }
    if (event.target.value == "all") {
      setApproverDatesSelection(false);
      setreportFilter(reportFilter => {
        return {
          ...reportFilter,
          fromDate: '',
          todate: ''
        }
      });
    }
  }
  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBContainer className="sectionContainer">
          <MDBRow className="sectionBgColor" >
            <MDBCol >
              <label>PROJECT REPORT</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            </MDBCol>
          </MDBRow>
          <br></br>
          <MDBRow >
            <MDBCol md="3" id="chkSelection">
              <MDBInput
                label="All (Includes All Dates)" type="radio" name="dateView" value="all"
                id="1" onChange={dateViewChange} checked={!approverDatesSelection}
              />
              <MDBInput
                label="Custom (Select Date Range)" name="dateView" value="custom"
                type="radio" onChange={dateViewChange}

                id="2" />
            </MDBCol>
            <MDBCol md="8">
              <ApproveDates showDates={approverDatesSelection} />
            </MDBCol>
            <MDBCol md="2">
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <Autocomplete limitTags={2} size="small"
                id="projectTitle" multiple
                options={reportFilter.projectInfo.length > 0 ? reportFilter.projectInfo : []}
                getOptionLabel={(option) => option.TitleName}
                onChange={(event, value) => {
                  setreportFilter({
                    ...reportFilter,
                    projectSelected: value
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Project Title"
                  />)}
              />
            </MDBCol>
            <MDBCol md="6">
              <Autocomplete autoHighlight
                multiple size="small"
                className="tags-standard"
                options={reportFilter.talentInfo.length > 0 ? reportFilter.talentInfo : []}
                getOptionLabel={(option) => option.TalentName}
                onChange={(event, value) => {
                  setreportFilter({
                    ...reportFilter,
                    selectedTalents: value
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Talent"
                  />
                )}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <Autocomplete autoHighlight
                multiple size="small"
                className="tags-standard"
                options={reportFilter.statusInfo.length > 0 ? reportFilter.statusInfo : []}
                getOptionLabel={(option) => option.StatusName}
                onChange={(event, value) => {
                  setreportFilter({
                    ...reportFilter,
                    statusSelected: value
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Status"
                  />
                )}
              />
            </MDBCol>

            <MDBCol md="6">
              <Autocomplete limitTags={2} size="small"
                id="projectTitle" multiple
                options={reportFilter.divisionInfo.length > 0 ? reportFilter.divisionInfo : []}
                getOptionLabel={(option) => option.DivisionName}
                onChange={(event, value) => {
                  debugger
                  setreportFilter({
                    ...reportFilter,
                    divisionSelected: value
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Division"
                  />)}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <Autocomplete autoHighlight
                multiple size="small"
                className="tags-standard"
                options={reportFilter.baexecInfo.length > 0 ? reportFilter.baexecInfo : []}
                getOptionLabel={(option) => option.BAExecutiveName}
                onChange={(event, value) => {
                  setreportFilter({
                    ...reportFilter,
                    selectedBAExec: value
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="BA Executive"
                  />
                )}
              />
            </MDBCol>

          </MDBRow>
          {/* <MDBRow>
            <MDBCol  >
              <label className="labelHeaders">Submitter Comments</label>
              <textarea
                className="form-control" onChange={(event, value) => {
                  setreportFilter({
                    ...reportFilter,
                    submitterComments: event.target.value
                  });
                }}
                id="comments"
                rows="2"
              />
            </MDBCol>
          </MDBRow> */}

        </MDBContainer>
        <MDBContainer>
          <MDBRow>
            <MDBCol col="4">
              <MDBBtn id="btnCancel" onClick={() => onCancel()} >Cancel</MDBBtn>
              <MDBBtn id="btnSave" onClick={() => { save() }} >Generate Report</MDBBtn>
            </MDBCol>

          </MDBRow>
        </MDBContainer>
      </MDBRow >
    </MDBContainer >
  );
}
export default DealReport;
