export const CREATE_PERMISSIONS = 'Create/Modify/Submit Request'; //1
export const REJECT_PERMISSIONS = 'Reject Request'; //2
export const APPROVAL_PERMISSIONS = 'Default Approve Request'; //3
export const CANCEL_PERMISSIONS = 'Cancel Request'; //4 
export const VIEWALLDEALS_PERMISSIONS = 'Can see all Deals'; //5
export const ASSIGN_PERMISSIONS = 'Assign Request'; //6
export const FINALAPPROVAL_PERMISSIONS = 'Final Request Approval'; //7
export const CLOSE_PERMISSIONS = 'Close an Approved Request'; //8


export const PERMISSIONS = [CREATE_PERMISSIONS, REJECT_PERMISSIONS, APPROVAL_PERMISSIONS, CANCEL_PERMISSIONS,
                            VIEWALLDEALS_PERMISSIONS, ASSIGN_PERMISSIONS, FINALAPPROVAL_PERMISSIONS, CLOSE_PERMISSIONS  ]