import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDataTableV5 } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';



function DealCreationProjectTitle(props) {
  console.log('props in DealCreationProjectTitle...', props);
  let handleProjectTitleChange = (event, value) => {
    let selectedProjectTitle = value;
    console.log('selectedProjectTitle...', selectedProjectTitle);
    props.Change(event, value);
  }
  return (
    <Autocomplete limitTags={2} size="small"
      id="projectTitle" autoHighlight
      // disableClearable={true}
      options={props.dataOptions && props.dataOptions.length > 0 ? props.dataOptions : []} freeSolo filterSelectedOptions
      getOptionLabel={(option) => option.Name}
      value={props.value}
      onChange={handleProjectTitleChange} 
      // onKeyPress={projectKeypress}
      disabled = {props.disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard" autoFocus
          label="Project Title*" className="txtProjectFocus"
          error={props.isformValidated ? (props.value ? false : true) : false}
          //FhelperText={props.value && props.isformValidated ? '' : 'Project Title is required'}
          inputRef={props.inputRef}
        />)}
    />
  )

}

export default DealCreationProjectTitle;

