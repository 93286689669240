import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDataTableV5 } from 'mdbreact';



function DealCreationComments(props) {
    let handleChange = (event) => {
        props.onChange(event.target.value);
    }
    return (
        <MDBCol>
            <label className="labelHeaders">Submitter Comments</label>
            <textarea
                className="form-control" onChange={handleChange}
                id="comments"
                rows="2"
                value={props.value}
                disabled = {props.disabled || props.projectnotSelected}
            />
        </MDBCol>

    )

}

export default DealCreationComments;

